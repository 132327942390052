<template>
  <div class="max-w-[1200px] mx-auto">
    <div>
      <div class="my-4">
        <h4 class="text-lg text-primary-500">Important Information</h4>
        <ul class="grid grid--4 g-3 my-2">
          <li class="text-danger-500">Don't change column names or their order.</li>
          <li>Existing Product Prices will be updated provided in excel.</li>
          <li>Set value in 1 in out of stock column for out of stock in excel.</li>
          <li>Set value 0 in price column for Request for price in excel.</li>
        </ul>
      </div>
      <div class="my-4">
        <h4 class="text-lg text-primary-500">Bulk Update</h4>
        <div class="card-wrap">
          <div class="flex">
            <!-- Step 1 -->
            <step :no="1">
              <p>Download the Excel Sheet with all the data you've already added.</p>
              <template #action>
                <s-button icon="IconMdiDownload" color="primary" label="Download" fluid @click.native="exportExcel" :loader="exporting" align="right" />
              </template>
            </step>

            <!-- Step 2 -->
            <step :no="2">
              <p>Update it with the valid data without altering the structure of the file.</p>
              <p class="mt-2">Microsoft Excel is recommended for making changes in file.</p>
            </step>

            <!-- Step 3 -->
            <step :no="3">
              <p>Upload file and wait for the process to complete.</p>
              <p class="mt-2">Once processed, you'll be able to see the report of inserted items.</p>
              <!-- <input type="file" class="hide" ref="file" accept=".xls,.xlsx" @input="upload($event)" onclick="this.value = null" /> -->

              <template #action>
                <div v-shilp-loader.overlay="uploading">
                  <VueFileAgentWrapper :linkable="true" :multiple="false" :isDeletable="true" accept=".xls,.xlsx" @filesSelected="upload($event)" />
                </div>
                <!-- <s-button label="Upload" color="primary" icon="IconMdiUpload" fluid align="right" :loader="uploading" @click.native="$refs.file.click()" /> -->
              </template>
            </step>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exportExcel, importExcel } from "@/api/product";
import VueFileAgentWrapper from "@/components/VueFileAgentWrapper.vue";
import fileUploadMixin from "@/mixins/fileUpload";

export default {
  components: {
    Step: require("@/components/products/bulkActions/Step").default,
    VueFileAgentWrapper,
  },
  props: {},
  mixins: [fileUploadMixin],

  created() {},
  data() {
    return {
      uploading: false,
      exporting: false,
    };
  },

  methods: {
    exportExcel() {
      this.exporting = true;
      exportExcel()
        .then((res) => {
          this.$shilp.notify({
            title: "You can now edit product prices from downloaded excel.",
            type: "success",
          });

          window.open(res.url, "_blank");
        })
        .catch()
        .finally(() => {
          this.exporting = false;
        });
    },

    upload(e) {
      const isValid = this.isMediaValid(e);

      if (isValid) {
        this.uploading = true;

        const formData = new FormData();

        formData.append("module", "product");
        formData.append("action", "update");
        formData.append("file", e[0].file);

        importExcel(formData)
          .then(() => {
            this.$shilp.notify({
              title: "File Uploaded.",
              type: "success",
            });

            this.$router.push({ name: "bulk-actions-history" });
          })
          .catch(({ data }) => {
            this.$shilp.notify({
              title: data.message,
              type: "danger",
            });
          })
          .finally(() => {
            this.uploading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
ul > li {
  @apply bg-white h-fit p-4 rounded-xl;
}
</style>
