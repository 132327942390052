var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('item-form',{ref:"item",staticClass:"p-4 card-wrap",attrs:{"fields":_vm.fields,"width":"400px","get":_vm.getItem,"item":_vm.id,"form-state":_vm.formState,"save":_vm.saveItem},on:{"update:formState":function($event){_vm.formState=$event},"update:form-state":function($event){_vm.formState=$event},"reset":_vm.resetForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var form = ref.form;
return [_c('s-field-validate',{attrs:{"rules":"required","name":"Product Name","label":"Product Name"}},[_c('s-textbox',{attrs:{"type":"text","placeholder":"Enter Product Name"},model:{value:(form.name),callback:function ($$v) {_vm.$set(form, "name", $$v)},expression:"form.name"}})],1),_c('s-field-validate',{attrs:{"label":"Category","rules":"required","name":"Category"}},[_c('v-select',{attrs:{"options":_vm.categoryOptions,"disabled":_vm.isItemAdded ? false : true,"placeholder":"Select","reduce":function (category) { return category.value; }},on:{"input":function($event){return _vm.onCategorySelected(form.category_id)}},model:{value:(form.category_id),callback:function ($$v) {_vm.$set(form, "category_id", $$v)},expression:"form.category_id"}})],1),_c('s-field-validate',{attrs:{"label":"Brand","rules":"required","name":"Brand"}},[_c('v-select',{attrs:{"options":_vm.brandOptions,"placeholder":"Select","label":"name","loading":_vm.isBrandLoading,"disabled":_vm.isItemAdded ? false : true,"reduce":function (brand) { return brand.id; }},model:{value:(form.brand_id),callback:function ($$v) {_vm.$set(form, "brand_id", $$v)},expression:"form.brand_id"}})],1),(form.category_id == 1)?_c('CementAttributes',{staticClass:"flex !flex-nowrap gap-4",attrs:{"rules":"required","disabled":_vm.isItemAdded ? false : true,"gradeId":_vm.isItemAdded ? null : form.attribute.find(function (item) { return item.attribute_id == 1; }).attribute_option_id,"packingId":_vm.isItemAdded ? null : form.attribute.find(function (item) { return item.attribute_id == 5; }).attribute_option_id},on:{"packing-selected":function($event){_vm.localAttributeFields.selectedPackingId = $event;
          _vm.localAttributeFields.selectedPackingAttributeId = 5;},"grade-selected":function($event){_vm.localAttributeFields.selectedGradeId = $event;
          _vm.localAttributeFields.selectedGradeAttributeId = 1;}}}):_vm._e(),(form.category_id == 2)?_c('TmtBarAttributes',{staticClass:"flex !flex-nowrap gap-4",attrs:{"rules":"required","disabled":_vm.isItemAdded ? false : true,"gradeId":_vm.isItemAdded ? null : form.attribute.find(function (item) { return item.attribute_id == 2; }).attribute_option_id,"sizeId":_vm.isItemAdded ? null : form.attribute.find(function (item) { return item.attribute_id == 4; }).attribute_option_id},on:{"size-selected":function($event){_vm.localAttributeFields.selectedSizeId = $event;
          _vm.localAttributeFields.selectedSizeAttributeId = 4;},"grade-selected":function($event){_vm.localAttributeFields.selectedGradeId = $event;
          _vm.localAttributeFields.selectedGradeAttributeId = 2;}}}):_vm._e(),(form.category_id == 3)?_c('AacBlocksAttributes',{staticClass:"flex !flex-nowrap gap-4",attrs:{"rules":"required","disabled":_vm.isItemAdded ? false : true,"gradeId":_vm.isItemAdded ? null : form.attribute.find(function (item) { return item.attribute_id == 6; }).attribute_option_id,"sizeId":_vm.isItemAdded ? null : form.attribute.find(function (item) { return item.attribute_id == 3; }).attribute_option_id},on:{"size-selected":function($event){_vm.localAttributeFields.selectedSizeId = $event;
          _vm.localAttributeFields.selectedSizeAttributeId = 3;},"grade-selected":function($event){_vm.localAttributeFields.selectedGradeId = $event;
          _vm.localAttributeFields.selectedGradeAttributeId = 6;}}}):_vm._e(),_c('div',{staticClass:"flex !flex-nowrap gap-4"},[_c('s-field-validate',{attrs:{"rules":"required|positiveFloat","name":"Price","label":"Price","after":"₹"}},[_c('s-textbox',{attrs:{"type":"number","placeholder":"Enter Price"},model:{value:(form.price),callback:function ($$v) {_vm.$set(form, "price", $$v)},expression:"form.price"}})],1),_c('s-field-validate',{attrs:{"rules":"required","name":"Unit","label":"Unit"}},[_c('v-select',{attrs:{"reduce":function (item) { return item.value; },"options":_vm.unitOptions,"placeholder":"Select Unit"},model:{value:(form.unit),callback:function ($$v) {_vm.$set(form, "unit", $$v)},expression:"form.unit"}})],1)],1),_c('s-field',{attrs:{"inline":"","label":"Out of Stock","desc":"Turning this on will show the product as 'out of stock' even if the stock is available."}},[_c('s-switch',{attrs:{"id":"outOfStock","size":"xs","shape":"pill","color":"danger"},model:{value:(form.out_of_stock),callback:function ($$v) {_vm.$set(form, "out_of_stock", $$v)},expression:"form.out_of_stock"}})],1),_c('status-input',{model:{value:(form.status),callback:function ($$v) {_vm.$set(form, "status", $$v)},expression:"form.status"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }