<template>
  <modal size="sm" title="Update Product Details" id="modal-order-product-status-change" @close="resetState">
    <template #default>
      <!-- Total Quantity -->
      <div class="grid-wrap">
        <div class="info-section">
          <label for="" class="block"> Total Quantity</label>
          <span> {{ itemQuantity }} {{ unit }}</span>
        </div>

        <!-- Total Price -->
        <div class="info-section">
          <label for="" class="block"> Total Price</label>
          <span>{{ formatPrice(totalPrice) }}</span>
        </div>
      </div>

      <ValidationObserver>
        <template #default="{ invalid }">
          <div class="grid-wrap">
            <!-- Total Quantity -->
            <div>
              <s-field-validate label="Total Dispatch Quantity" rules="required|positiveNonZeroFloat" :after="unit">
                <s-textbox type="number" v-model="dispatchQuantity"></s-textbox>
              </s-field-validate>
            </div>

            <div class="info-section">
              <label for="" class="block">Adjusted Price</label>
              <span> {{ formatPrice(adjustedPrice) }}</span>
            </div>
          </div>

          <div class="flex flex--fit my-2">
            <s-field label="Product Status">
              <s-select placeholder="Select Product Order Status" :options="allowedStatusOptions" v-model="localProductStatusId" />
            </s-field>
          </div>
          <s-field label="Mark order as complete" class="my-2 border-2 border-danger-200 p-2 rounded-md" inline>
            <s-switch id="mark-order-complete" :on-value="true" :off-value="false" shape="pill" color="primary" v-model="markOrderAsComplete" size="sm" />
          </s-field>

          <!-- <s-field label="Payment Status" class="mt-4">
            <s-select
              placeholder="Select Payment Status"
              :options="paymentStatusOptions"
              v-model="data.payment_status_id"
            />
          </s-field> -->

          <s-field label="Additional Details">
            <s-textarea :rows="4" v-model="additionalDetails" placeholder="You can enter driver number, Truck information etc.." />
          </s-field>

          <div class="text-center">
            <s-button class="mt-4" align="center" color="primary" label="Submit" :loader="isChanging" @click.native="change" :disabled="invalid" />
          </div>
        </template>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import { changeOrderProductStatus } from "@/api/order";
import orderStatusData from "@/data/orderStatus";
import paymentStatusData from "@/data/paymentStatus";

import { formatPrice } from "@/utilities/common";

/*
Issue List
- Not able to use v-model.number modifier here in quantity field because not able to enter 500.03.. zeroes are getting replaced to 500, therefore using Number casting
*/
export default {
  orderStatusData,
  paymentStatusData,
  props: ["productStatusId", "itemId", "itemQuantity", "totalPrice", "productPrice", "unit", "order"],
  computed: {
    ...mapGetters({
      orderStatusOptions: "orderStatusOptions",
    }),
    allowedStatusOptions() {
      if (this.isOrderCancellationAllowed) return this.orderStatusOptions;
      else
        return this.orderStatusOptions.filter((item) => {
          // Cancelled status
          return item.id !== this.$options.orderStatusData.cancelled;
        });
    },

    isOrderCancellationAllowed() {
      let orderStatusId = [
        this.$options.orderStatusData.partial_shipped,
        this.$options.orderStatusData.shipped,
        this.$options.orderStatusData.partial_delivered,
        this.$options.orderStatusData.delivered,
      ];

      // ORder PRoducts logic

      // Checking Product status
      if (orderStatusId.includes(this.productStatusId)) return false;

      // Checking for payment status paid
      if (this.$options.paymentStatusData.paid === this.order.paymentStatus.id) return false;
      return true;
    },
    adjustedPrice() {
      return this.productPrice * Number(this.dispatchQuantity);
    },
    // paymentStatusOptions: "paymentStatusOptions",
  },

  watch: {
    productStatusId(newValue) {
      this.localProductStatusId = newValue;
    },

    itemQuantity(newValue) {
      this.dispatchQuantity = newValue;
    },

    dispatchQuantity(newValue) {
      if (newValue == this.itemQuantity) this.markOrderAsComplete = true;
    },
  },
  data() {
    return {
      isChanging: false,
      localProductStatusId: null,
      dispatchQuantity: 0,
      markOrderAsComplete: true,
      additionalDetails: null,
    };
  },

  methods: {
    formatPrice,

    resetState() {
      this.localProductStatusId = null;
      this.dispatchQuantity = 0;
      this.markOrderAsComplete = true;
      this.isChanging = false;
      this.$emit("close");
    },
    async change() {
      this.isChanging = true;

      const data = {
        dispatch_quantity: Number(this.dispatchQuantity),
        order_status_id: this.localProductStatusId,
        // TODO Ask Ask backend to update key name pending_quantity_send to mark_order_as_complete because it is creating lot of confusion.
        complete_order: this.markOrderAsComplete,
      };

      if (this.additionalDetails) data["additional_detail"] = this.additionalDetails;
      try {
        await changeOrderProductStatus(this.itemId, data);
        // this.$router.push({ name: "order-list" });
        this.$shilp.notify({
          title: "Product details Updated.",
          type: "success",
        });

        this.resetState();
        this.$root.$emit("shilp-modal-close", "modal-order-product-status-change");
        this.$emit("order-product-status-changed");
      } catch ({ data }) {
        this.$shilp.notify({
          title: data.message,
          type: "danger",
        });
      } finally {
        this.isChanging = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-wrap {
  @apply grid grid-cols-2 gap-8 mt-4 items-center;
}

.info-section {
  label {
    @apply text-lg text-gray-500;
  }

  span {
    @apply text-lg;
  }
}
</style>
