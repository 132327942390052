<template>
  <div>
    <div class="header-container">
      <span class="header-label">Filters</span>
      <s-button icon="IconIcRoundClose" size="lg" color="gray" theme="link" @click.native="$emit('filterBarClose')" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.header-container {
  @apply flex justify-between items-center border-b-2 border-b-gray-100 h-14 pl-4;

  .header-label {
    @apply text-base text-gray-500 block;
  }
}
</style>
