<template>
  <div class="ml-4 w-96 mt-4">
    <s-field label="Company">
      <v-select placeholder="Select Company" :options="companyOptions" v-model="companyId" label="name" @input="getCredits" :clearable="false" :reduce="(item) => item.id" />
    </s-field>

    <s-field label="Credit" class="mt-4">
      <div v-shilp-loader.overlay="isLoading">
        <s-textbox type="number" name="credit" v-model="credits" />
      </div>
    </s-field>
    <s-button class="flex-none text-center w-20 mt-4" align="center" fluid color="primary" label="Update" @click.native="update(credits)" :loader="saving" />
  </div>
</template>

<script>
import { getAllCompanies, get, changeCredit } from "@/api/company";
export default {
  data() {
    return {
      saving: false,
      credits: null,
      isLoading: false,
      companyOptions: [],
      companyId: null,
    };
  },
  created() {
    this.getAllData();
  },
  methods: {
    async getAllData() {
      let companies = await getAllCompanies({ limit: -1 });
      this.companyOptions = companies.data;
    },
    getCredits() {
      this.isLoading = true;
      get(this.companyId)
        .then((res) => {
          if (this.credits) {
            this.credits = res.credit;
          } else {
            this.credits = 0;
          }
        })
        .catch()
        .finally(() => (this.isLoading = false));
    },
    async update() {
      let res = await changeCredit(this.companyId, this.credits);
      this.credits = res.credit;
    },
  },
};
</script>
