<template>
  <div class="rounded overflow-hidden bg-white flex flex--fit justify-between p-3 text-grey" v-if="!loader && orderInfo">
    <div>
      <p>Order Id</p>
      <h4>{{ orderInfo.order_no }}</h4>
    </div>
    <div>
      <p>Order Date</p>
      <h4>{{ getFormatedDate(orderInfo.created_at) }}</h4>
    </div>
    <div>
      <p>Delivery By</p>
      <h4>{{ getFormatedDate(orderInfo.delivery_date) }}</h4>
    </div>
    <div>
      <p>Category</p>
      <h4>{{ orderInfo.category.name }}</h4>
    </div>
    <div class="flex flex--fit">
      <img :src="orderInfo.brand.logo.url" class="w-5 h-5" />
      <span class="border-r border-gray-200 mx-4"></span>
      <div>
        <p>Share via</p>
        <s-button icon="IconMdiWhatsapp" label="WhatsApp" class="text-white button--sm" color="success" @click.native="redirectTowhatsapp(orderInfo)" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["orderInfo", "isLoading"],
  data() {
    return {
      loader: false,
    };
  },
  components: {},
  watch: {
    isLoading(value) {
      this.loader = value;
    },
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    getFormatedDate(date) {
      const modifiedDate = new Date(date * 1000);
      return moment(modifiedDate).format("DD MMM,YY");
    },

    redirectTowhatsapp(orderInfo) {
      const textMsg = `Hello ${orderInfo.user.name} your order details: %0A Order Id: ${orderInfo.order_no},%0A Category: ${orderInfo.category.name},%0A Brand: ${orderInfo.brand.name},%0A Quantity: ${orderInfo.qty} %0A ${process.env.VUE_APP_BASE_URL}/order-detail/${orderInfo.id}`;
      window.open(`https://wa.me/${orderInfo.user.mobile_no}?text=${textMsg}`, "_blank");
    },
  },
};
</script>

<style></style>
 