<template>
  <div>
    <FilterBar @resetFilters="resetFilters">
      <s-field label="Date" class="mt-2">
        <CustomDatePicker v-model="filters.date_range" />
      </s-field>
    </FilterBar>

    <sp-list sortBy="created_at" sortOrder="desc" :endpoint="endPoint" ref="list" :attrs="columns" :params="params" :perPage="params.limit" :filters="filters">
      <template #header="{ refresh }">
        <div class="flex flex--fit justify-between">
          <FilterTabs
            :filters="filters"
            :module="module"
            @selected="
              filters.notifiable_type = $event;
              params.read_at = null;
            "
            :typeOptions="typeOptions"
            @unread-filter-selected="onUnreadFilterSelected()"
          />

          <div class="mb-2 filter-section flex flex--fit justify-end">
            <SearchFilter :value="filters.search" @search="filters.search = $event" />
            <s-button-group>
              <s-button class="filter" icon="vmdi-filter" shape="circle" @click.native="$root.$emit('filter-bar-open')" />
              <s-button class="refresh" icon="vmdi-refresh" shape="circle" @click.native="refresh()" />
            </s-button-group>
          </div>
        </div>
      </template>

      <sp-list-table @rowClick="redirect"></sp-list-table>
    </sp-list>
  </div>
</template>

<script>
import tableMixin from "@/mixins/table";
import { getNotificationRoute } from "@/utilities/common";
import { getDetail as getNotificationDetail } from "@/api/notifications";
import { mapMutations } from "vuex";
import bus from "@/bus";
export default {
  mixins: [tableMixin],

  props: {
    filter: Object,
    columns: Array,
    param: Object,
    endPoint: String,
    typeOptions: Array,
    module: String,
  },

  data() {
    return {
      filters: this.filter,
      params: this.param,
    };
  },
  methods: {
    ...mapMutations({
      setCompanyFilterId: "setCompanyFilterId",
    }),
    redirect(row) {
      this.markAsRead(row);
      let routeName = null;
      routeName = getNotificationRoute(row.notifiable_type);
      if (routeName == "company-profile") {
        this.setCompanyFilterId(row.notifiable_id);
        this.$router.push({ name: routeName, params: { id: row.notifiable_id, status: "incompleteOrPending" } });
      } else if (routeName === "vendor-bids-assign") {
        this.$router.push({ name: routeName, params: { id: row.notification_detail.vendor_order_id, bidId: row.notifiable_id } });
      } else {
        this.$router.push({ name: routeName, params: { id: row.notifiable_id } });
      }
    },

    markAsRead(notification) {
      getNotificationDetail(notification.id).then(() => {
        bus.$emit("notification-clicked");
      });
    },
    resetFilters() {
      this.filters = {
        notifiable_type: null,
        search: null,
        date_range: null,
        read_at: null,
      };
    },

    onUnreadFilterSelected() {
      this.params.read_at = 0;
      this.filters.notifiable_type = null;
    },
    refresh() {
      this.$refs.list.refresh();
    },
  },
  components: {
    FilterTabs: require("@/components/notifications/FilterTabs.vue").default,
    SearchFilter: require("@/components/SearchFilter.vue").default,
    CustomDatePicker: require("@/components/CustomDatePicker.vue").default,
    FilterBar: require("@/components/FilterBar").default,
  },

  created() {
    bus.$on("notification-arrived", this.refresh);
  },
  destroyed() {
    bus.$off("notification-arrived", this.refresh);
  },
};
</script>

<style lang="scss" scoped>
.filter-section {
  @apply flex gap-4 items-center;
}
.button-group {
  @apply relative block;
}
.v-list__custom-header > * {
  flex: 1 1 100%;
}
.has-applied {
  @apply text-secondary-500 z-10;
  background: none !important;
}

.not-applied {
  @apply text-gray-500;
}

.animate {
  @apply absolute left-0 bg-secondary-100 animate-ping p-4 rounded-full;
}
</style>
