import $axios from "@/plugins/axios";

export const get = () => {
  return $axios.get(`orders?include=products,products.brand,products.categories,products.attributeOptions.attribute,orderStatus,paymentStatus`).then((res) => {
    return res.data;
  });
};

export const detail = (id, params = null) => {
  return $axios
    .get(`orders/${id}`, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

// export const changeOrderStatus = (id, order_status_id, payment_status_id = null) => {
//   return $axios.put(`orders/${id}`, { order_status_id, payment_status_id }).then((res) => {
//     return res.data;
//   });
// };

export const changeOrderOrPaymentStatus = (id, data) => {
  // TODO Ask backend to split order status and payment statatus API
  return $axios.put(`orders/${id}`, data).then((res) => {
    return res.data;
  });
};

export const changeQuantity = (id, quantity) => {
  return $axios.put(`orderProducts/${id}`, { id, quantity }).then((res) => {
    return res.data;
  });
};

// export const changeOrderProductStatus = (id, data) => {
//   return $axios.put(`orderProducts/Orderstatus/${id}`, data).then((res) => {
//     return res.data;
//   });
// };

export const countStatistics = (id, params = null) => {
  return $axios.get(`companies/${id}/ordersCount`, { params }).then((res) => {
    return res.data;
  });
};

export const categoryWiseOrderCount = (params = null) => {
  return $axios.get("orders/ordercount/categorywise", { params }).then((res) => res.data);
};

export const changeOrderProductStatus = (id, data) => {
  return $axios.put(`orderProducts/${id}`, data).then((res) => {
    return res.data;
  });
};

export const getCountForAllOrders = (params = null) => {
  return $axios.get("orders/ordercount/ordercountall", { params }).then((res) => res.data);
};

export const downloadHistory = (params = null) => {
  return $axios.get("orders/export/data", { params }).then((res) => res.data);
};

export const dashboardChart = (params = null) => {
  return $axios.get("orders/chart/monthwise", { params }).then((res) => res.data);
};

export const averageOrderSize = (params = null) => {
  return $axios.get("orders/average/count", { params }).then((res) => res.data);
};

export const quantitySoldCategoryWise = (params = null) => {
  return $axios.get("quantity/sold/count", { params }).then((res) => res.data);
};

export const downloadOrderPDF = (id, params = null) => {
  return $axios.get(`orders/${id}/orderDetailPdf`, { params }).then((res) => res.data);
};

export const upcomingDeliveryOrderCount = (params = null) => {
  return $axios.get(`orders/upcomingDelivery/count`, { params }).then((res) => res.data);
};

export const overdueDeliveryOrderCount = (params = null) => {
  return $axios.get("orders/overdueDelivery/count", { params }).then((res) => res.data);
};

export const updateVendorPrice = (id, data) => {
  return $axios.put(`orderProducts/${id}/vendorPrice`, data).then((res) => res.data);
};
