export default [
  {
    path: "brands",
    name: "brand",
    component: require("@/views/private/master/brands/Index").default,
    meta: {
      label: "Brands",
    },
    redirect: { name: "brand-listing" },
    children: [
      {
        path: "list",
        name: "brand-listing",
        component: require("@/views/private/master/brands/List").default,
        meta: {
          label: "Brands",
          breadcrumbs: ["dashboard", "brand-listing"],
        },
      },
      {
        path: ":id",
        props: true,
        name: "brand-upsert",
        component: require("@/views/private/master/brands/Item").default,
        meta: {
          label: "Add New",
          breadcrumbs: ["dashboard", "brand-listing", "brand-upsert"],
        },
      },
    ],
  },
];
