<template>
  <div class="flex flex--fit mt-2" v-if="orderInfo && !isLoading">
    <div class="mr-2 price-info rounded bg-white !grow">
      <v-popover trigger="click" class="float-right" ref="pricePopover">
        <template #popover>
          <ValidationObserver>
            <template #default="{ invalid }">
              <s-field-validate name="Amount" label="Freight" :rules="`required|positiveInteger`" class="field--required">
                <STextbox type="number" v-model.number="freightAmount" />
              </s-field-validate>
              <s-field-validate name="Amount" label="Loading" :rules="`required|positiveInteger`" class="field--required mt-1">
                <STextbox type="number" v-model.number="loadingAmount" />
              </s-field-validate>
              <s-field-validate name="Amount" label="Unloading" :rules="`required|positiveInteger`" class="field--required mt-1">
                <STextbox type="number" v-model.number="unLoadingAmount" />
              </s-field-validate>
              <SButton color="primary" label="Submit" class="mt-2" @click.native="onAmountChange(freightAmount, loadingAmount, unLoadingAmount)" :disabled="invalid" :loader="apiLoader" />
            </template>
          </ValidationObserver>
        </template>
        <a class="text-blue-500 font-medium cursor-pointer">Edit</a> </v-popover
      ><br />
      <div class="flex flex--fit flex--nowrap text-grey gap-20">
        <div>
          <p class="font-medium mb-1">Freight</p>
          <div class="flex flex--fit">
            <StatusTag :label="isFreightIncluded ? 'Included' : 'Excluded'" :name="isFreightIncluded ? 'included' : 'excluded'" class="status w-20" />
          </div>
        </div>
        <div>
          <p class="font-medium mb-1">Loading</p>
          <div class="flex flex--fit">
            <StatusTag :label="isLoadingIncluded ? 'Included' : 'Excluded'" :name="isLoadingIncluded ? 'included' : 'excluded'" class="status w-20" />
          </div>
        </div>
        <div>
          <p class="font-medium mb-1">Unloading</p>
          <div class="flex flex--fit">
            <StatusTag :label="isUnloadingIncluded ? 'Included' : 'Excluded'" :name="isUnloadingIncluded ? 'included' : 'excluded'" class="status w-20" />
          </div>
        </div>
      </div>
    </div>
    <div class="rounded overflow-hidden bg-white p-3 !grow">
      <p class="font-medium text-gray-500">Payment Paid</p>
      <p class="font-bold text-base mt-1">{{ formatPrice(orderInfo.receive_amount) }}</p>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/utilities/common";
import StatusTag from "@/components/StatusTag.vue";
import { updateOrder } from "@/api/vendor/order.js";
export default {
  props: ["orderInfo", "isLoading"],
  components: {
    StatusTag,
  },
  data() {
    return {
      freightAmount: this.orderInfo.freight || 0,
      loadingAmount: this.orderInfo.loading || 0,
      unLoadingAmount: this.orderInfo.unloading || 0,
      apiLoader: false,
    };
  },
  mounted() {},
  computed: {
    isLoadingIncluded() {
      if (this.orderInfo.brand.loading === 0) {
        return false;
      } else {
        return true;
      }
    },
    isUnloadingIncluded() {
      if (this.orderInfo.brand.unloading === 0) {
        return false;
      } else {
        return true;
      }
    },
    isFreightIncluded() {
      if (this.orderInfo.brand.freight === 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    formatPrice,
    onAmountChange(frieght, loading, unloading) {
      this.apiLoader = true;
      const payload = {
        frieght,
        loading,
        unloading,
      };
      updateOrder(this.$route.params.id, payload)
        .then(() => {
          this.$refs.pricePopover.hide();
          this.apiLoader = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          console.log(err);
          this.$shilp.notify({
            title: err.message,
            type: "danger",
          });
          this.apiLoader = false;
        });
    },
  },
};
</script>

<style lang="css" scoped>
.divide-dotted {
  border-style: dashed;
}
table {
  overflow-y: scroll;
  height: 150px;
  display: block;
}
::v-deep .button svg {
  width: 10px !important;
  height: 10px !important;
}
.status {
  padding: 10px !important;
}
.price-info {
  min-height: 80px;
  padding: 8px;
}
.label {
  font-size: small;
}
</style>
 