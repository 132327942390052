<template>
  <s-textbox :value="value" @input="textToUpperCase" :maxlength="maxLength" :disabled="isDisabled" />
</template>

<script>
export default {
  props: ["value", "maxLength", "isDisabled"],
  methods: {
    textToUpperCase(inputValue) {
      this.$emit("input", inputValue.toUpperCase());
    },
  },
};
</script>

<style lang="scss" scoped></style>
