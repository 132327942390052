<template>
  <sidebar-menu :menu="menu" :width="width" :showOneChild="true" @toggle-collapse="toggleCollapse" @item-click="onItemClick">
    <template #toggle-icon>
      <s-button icon="IconMdiArrowLeftRight" theme="link" color="light" />
    </template>
    <template #header>
      <div v-if="collapseState" class="py-2 mobile-logo">
        <router-link to="/">
          <img src="@/assets/logo.svg" alt="Logo-Image" />
        </router-link>
      </div>
      <div v-else class="site-logo p-4">
        <router-link to="/">
          <img src="@/assets/sidebarlogo.svg" alt="Logo-Image" />
        </router-link>
      </div>
    </template>

    <template #footer>
      <p class="text-xs py-2 text-center text-white">
        <span>Version: </span>
        <span>{{ version }}</span>
      </p>
    </template>
    <template #dropdown-icon>
      <s-button icon="IconMdiArrowDown" theme="link" color="light" />
    </template>
  </sidebar-menu>
</template>

<script>
import { version } from "../../package.json";
import { SidebarMenu } from "vue-sidebar-menu";
// import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import { getAllCompanies } from "@/api/company";

import roleMixin from "@/mixins/role.js";
export default {
  props: ["width"],
  mixins: [roleMixin],
  data() {
    return {
      version,
      isLoggingOut: false,
      activeCompanyCount: 0,
      collapseState: false,
    };
  },
  created() {
    this.getActiveCompanyCount();
  },
  components: {
    SidebarMenu,
  },
  computed: {
    isRouteActive() {
      return ["company-listing", "company-profile", "company-statistics"].includes(this.$route.name);
    },
    menu() {
      return [
        {
          href: { name: "dashboard" },
          title: "Dashboard",
          icon: {
            element: "IconIcRoundDashboard",
          },
        },
        {
          title: "Buyer",
          icon: {
            element: "IconMdiOfficeBuildingOutline",
          },
          name: "buyer",
          child: [
            {
              title: "Companies",
              icon: {
                element: "IconMdiOfficeBuildingOutline",
              },
              name: "companies",
              child: [
                {
                  title: "Active",
                  href: { name: "active-company" },
                  icon: {
                    element: "IconMdiOfficeBuildingOutline",
                  },
                  alias: ["/active-companies/*"],
                  exactPath: true,
                  badge: {
                    text: this.activeCompanyCount,
                    class: "vsm--badge_default",
                  },
                },
                {
                  title: "Action Required",
                  href: { name: "action-required", params: { status: "inreview" } },
                  icon: {
                    element: "IconMdiAccountQuestionOutline",
                  },
                  alias: ["/action-required/*"],
                  exactPath: true,
                },
                {
                  title: "Projects",
                  href: { name: "project-list" },
                  alias: ["/projects/*"],
                  icon: {
                    element: "IconMdiOfficeBuildingMarkerOutline",
                  },
                  exactPath: true,
                },
              ],
            },
            {
              title: "Orders",
              href: { name: "orders" },
              icon: {
                element: "IconIcOrder",
              },
              hidden: this.isOrderListVisible ? false : true,
              alias: ["/orders/*"],
              exactPath: true,
            },
            {
              title: "Buyer Type",
              href: { name: "buyer-type" },
              icon: {
                element: "IconMaterialSymbolsLayersOutlineRounded",
              },
              hidden: this.isBuyerTypeListVisible ? false : true,
              exactPath: true,
              alias: ["/buyer/*"],
            },
            {
              title: "Inquiries",
              href: { name: "inquiry-list" },
              icon: {
                element: "IconMdiAccountCardOutline",
              },
              exactPath: true,
              hidden: this.isInquiryListVisible ? false : true,
              alias: ["/inquiry/*"],
            },

            {
              title: "Notifications",
              href: { name: "notification-list" },
              icon: {
                element: "IconMdiBellRingOutline",
              },
              exactPath: true,

              alias: ["/notifications/*"],
            },
            {
              title: "Settings",
              href: { name: "settings" },
              icon: {
                element: "IconMdiCog",
              },
              alias: ["/settings/*"],
              exactPath: true,
            },
          ],
        },
        {
          title: "Master",
          icon: {
            element: "IconMdiDominoMask",
          },
          child: [
            {
              title: "Brand",
              href: { name: "brand" },
              icon: {
                element: "IconMdiMicrosoftBing",
              },
              exactPath: true,
              hidden: this.isBrandListVisible ? false : true,
              alias: ["/brands/*"],
            },
            {
              title: "Brochure",
              href: { name: "brochure-list" },
              icon: {
                element: "IconMdiFormSelect",
              },
              exactPath: true,
              hidden: this.isBrochureListVisible ? false : true,
              alias: ["/brochure/*"],
            },
            {
              title: "Products",
              href: { name: "product" },
              icon: {
                element: "IconMdiPackageVariantClosed",
              },
              alias: ["/products/*"],
              exactPath: true,
              hidden: this.isProductListVisible ? false : true,
            },
            {
              title: "Users",
              href: { name: "user-list" },
              icon: {
                element: "IconMdiAccountMultipleOutline",
              },
              exactPath: true,
              hidden: this.isUserListVisible ? false : true,

              alias: ["/users/*"],
            },
          ],
        },
        {
          title: "Vendor",
          icon: {
            element: "IconMdiAccountCogOutline",
          },
          name: "vendor",
          child: [
            {
              title: "Manage Vendor",
              href: { name: "vendor" },
              icon: {
                element: "IconMaterialSymbolsDeployedCodeAccountOutline",
              },
              alias: ["/vendor/*"],
              exactPath: true,
              hidden: false,
            },
            {
              title: "Orders",
              icon: {
                element: "IconIcOrder",
              },
              name: "vendor-orders",
              alias: ["/vendor-orders/*"],
              child: [
                {
                  title: "Unassigned Orders",
                  href: { name: "vendor-unassigned-order-list" },
                  icon: {
                    element: "IconOrderUnAssignIcon",
                  },
                  exactPath: true,
                  alias: ["/vendor-unassigned-orders/*"],
                  hidden: false,
                },
                {
                  title: "Assigned Orders",
                  href: { name: "vendor-assigned-order-list" },
                  icon: {
                    element: "IconOrderAssignIcon",
                  },
                  exactPath: true,
                  alias: ["/vendor-assigned-orders/*"],
                  hidden: false,
                },
              ],
            },
            {
              title: "Advance Bid",
              href: { name: "advance-bid" },
              icon: {
                element: "IconAdvanceBid",
              },
              hidden: false,
              alias: ["/advance-bid/*"],
              exactPath: true,
            },
            {
              title: "Issues",
              href: { name: "vendor-inquiries" },
              icon: {
                element: "IconMdiAccountCardOutline",
              },
              exactPath: true,
              hidden: this.isInquiryListVisible ? false : true,
              alias: ["/vendor-inquiries/*"],
            },

            {
              title: "Notifications",
              href: { name: "vendor-notification-list" },
              icon: {
                element: "IconMdiBellRingOutline",
              },
              exactPath: true,
              alias: ["/vendor-notification-list/*"],
            },
            {
              title: "Settings",
              href: { name: "vendor-settings" },
              icon: {
                element: "IconMdiCog",
              },
              alias: ["/vendor-settings/*"],
              exactPath: true,
            },
          ],
        },
      ];
    },
  },
  methods: {
    onItemClick(e, item) {
      if (item.name == "companies") this.getActiveCompanyCount();
    },
    toggleCollapse($event) {
      this.collapseState = $event;
      this.$emit("toggle-collapse", $event);
    },

    getActiveCompanyCount() {
      getAllCompanies({
        limit: -1,
        filter: {
          kyc_status: "approve",
        },
      }).then((res) => {
        this.activeCompanyCount = res.data.length;
      });
    },
  },
};
</script>

<style lang="scss">
.mobile-logo {
  @apply max-w-[50px] h-auto;
}
$primary-color: #000080;
$icon-bg: transparent;
$icon-height: 24px;
$icon-width: 24px;
$item-open-bg: transparent;
$icon-open-bg: transparent;
$item-active-bg: $primary-color;
@import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";
</style>
