<template>
  <s-button-group color="secondary" theme="tab" size="lg" v-if="typeOptions">
    <s-button>
      <template #before>
        <span :class="{ 'text-secondary-500 font-bold': isActive(null) }" @click="onSelect(null)">
          ALL <span v-if="counts">({{ counts.total }})</span></span
        >
      </template>
      <template #after>
        <span class="ml-2" :class="{ 'text-secondary-500 font-bold': isActive('unread-filter') }" @click="onSelect('unread-filter')" v-if="counts"> Unread ({{ counts.unread_count }})</span>
      </template>
    </s-button>

    <s-button
      v-for="notification in typeOptions"
      class="mb-2"
      :key="notification.value"
      :label="notification.label"
      :active="isActive(notification.value)"
      @click.native="onSelect(notification.value)"
    >
      <template #after>
        <span class="ml-2 font-bold" v-if="counts"> ({{ counts[notification.name] || 0 }}) </span>
      </template>
    </s-button>
  </s-button-group>
</template>

<script>
import { notificationCount } from "@/api/notifications";
import { vendorNotificationCount } from "@/api/vendor/notification.js";
export default {
  props: ["filters", "typeOptions", "module"],
  data() {
    return {
      active: null,
      counts: null,
    };
  },
  computed: {},
  created() {
    this.getNotificationCount();
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler() {
        this.getNotificationCount();
      },
    },
  },
  methods: {
    getNotificationCount() {
      if (this.module == "vendor") {
        vendorNotificationCount({
          filter: {
            ...this.filters,
            notifiable_type: null,
          },
        }).then((res) => {
          this.counts = res;
        });
      } else {
        notificationCount({
          filter: {
            ...this.filters,
          },
        }).then((res) => {
          this.counts = res;
        });
      }
    },
    onSelect(id) {
      if (id == "unread-filter") {
        this.getNotificationCount();
        this.active = "unread-filter";
        this.$emit("unread-filter-selected");
      } else {
        this.getNotificationCount();
        this.active = id;
        this.$emit("selected", id);
      }
    },
    isActive(id) {
      if (id == this.active) return true;
      else return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
