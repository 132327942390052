<template>
  <!-- Hard coded style is given to solve UI issue of showing component at center of screen instead of sticking to top -->
  <div class="p-4 flex flex-col flex--center flex--fit flex--middle w-full h-full flex--nowrap">
    <s-icon name="IconNoDataFound" class="text-white" />

    <div class="block text-lg mt-3 font-semibold">{{ title }}</div>
    <p class="text-grey-light">{{ content }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "No Data Found",
    },
    content: {
      type: String,
      default: "Try changing filters",
    },
  },
};
</script>

<style lang="scss"></style>
