<template>
  <div class="2xl:w-4/5" v-shilp-loader.overlay="isLoading">
    <div class="order-details">
      <div class="order-widget" @click="onClick(null)" :class="{ active: type !== 'info' && !selected }">
        <s-button icon="IconMdiCart" theme="muted" color="primary" shape="circle" size="lg" />
        <div>
          <p class="text-lg font-bold text-primary-500">{{ totalCount }}</p>
          <span class="text-base">Total Orders</span>
          <span class="order-amount text-primary-500"> {{ formatPrice(totalOrderAmount) }}</span>
        </div>
      </div>

      <div class="order-widget" @click="onClick($options.underProcessId)" :class="{ active: isActive($options.underProcessId) }">
        <s-button icon="IconMdiPending" theme="muted" color="warning" shape="circle" size="lg" />
        <div>
          <p class="text-lg font-bold text-warning-500">
            {{ activeCount }} <InformationButton v-tooltip="'Active orders include Status ( Requested, OnHold, Confirmed, Partial Shipped, Partial Delivered, Shipped )'" />
          </p>
          <span class="text-base">Active Orders</span>
          <span class="order-amount text-warning-500"> {{ formatPrice(totalOrderUnderProcessAmount) }}</span>
        </div>
      </div>

      <div class="order-widget" @click="onClick(3)" :class="{ active: isActive(3) }">
        <s-button icon="IconMdiTruck" theme="muted" color="success" shape="circle" size="lg" />
        <div>
          <p class="text-lg font-bold text-success-800">{{ deliveredCount }} <InformationButton v-tooltip="'Amount contains total of all sub items delivered'" /></p>
          <span class="text-base">Delivered</span>
          <span class="order-amount text-success-800">
            {{ formatPrice(totalOrderDeliveredAmount) }}
          </span>
        </div>
      </div>

      <!-- Cancelled -->
      <div class="order-widget" @click="onClick(6)" :class="{ active: isActive(6) }">
        <s-button icon="IconMdiCancel" theme="muted" color="danger" shape="circle" size="lg" />
        <div>
          <p class="text-lg font-bold text-danger-500">
            {{ cancelledCount }}
            <InformationButton v-tooltip="'The amount displayed consists of all the orders that are cancelled.'" />
          </p>
          <span class="text-base">Cancelled</span>
          <span class="order-amount text-danger-500"> {{ formatPrice(totalOrderCancelAmount) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { countStatistics, getCountForAllOrders } from "@/api/order";
import { orderCountVendorWise, allOrderCountAndAmount } from "@/api/vendor/order.js";
import { mapGetters } from "vuex";
import { formatPrice } from "@/utilities/common";

export default {
  // props: ["companyId", "activeId", "type"],
  props: ["activeId", "type", "module", "vendorId", "vendorFilters"],
  components: {
    InformationButton: require("@/components/InformationButton").default,
  },

  underProcessId: [1, 5, 4, 10, 11, 2],
  data() {
    return {
      totalCount: 0,
      activeCount: 0,
      cancelledCount: 0,
      deliveredCount: 0,
      totalOrderCancelAmount: 0,
      totalOrderDeliveredAmount: 0,
      totalOrderUnderProcessAmount: 0,
      totalOrderAmount: 0,
      selected: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      companyFilterIdFromVuex: "companyFilterId",
      filters: "order/filters",
    }),
  },
  created() {
    if (this.activeId) this.selected = this.activeId;

    if (this.filters.order_status_id) this.selected = this.filters.order_status_id;
    if (this.companyFilterIdFromVuex || this.vendorId) {
      this.module == "vendor" ? this.getOrderCountVendorWise() : this.getCompanyWiseCount();
    } else this.module == "vendor" ? this.getAllOrderCountAndAmount() : this.getAllCount();
  },
  watch: {
    companyFilterIdFromVuex(newValue) {
      if (newValue) this.getCompanyWiseCount();
      else this.getAllCount();
    },

    vendorFilters: {
      deep: true,
      handler() {
        this.getAllOrderCountAndAmount();
      },
    },
  },

  methods: {
    formatPrice,
    isActive(id) {
      if (Array.isArray(id) && Array.isArray(this.selected)) return true;
      if (id && id == this.selected) return true;
      else return false;
    },
    onClick(id) {
      if (id) {
        if (this.selected && this.selected == id) {
          this.selected = null;
        } else {
          this.selected = id;
        }
      } else this.selected = null;

      this.$emit("selected", this.selected);
    },
    getCompanyWiseCount() {
      this.isLoading = true;

      countStatistics(this.companyFilterIdFromVuex).then(({ total, active, cancelled, delivered, order_total_amount, order_under_process_amount, order_cancel_amount, order_deliverd_amount }) => {
        this.totalCount = total;
        this.activeCount = active;
        this.cancelledCount = cancelled;
        this.deliveredCount = delivered;
        this.totalOrderAmount = order_total_amount;
        this.totalOrderUnderProcessAmount = order_under_process_amount;
        this.totalOrderCancelAmount = order_cancel_amount;
        this.totalOrderDeliveredAmount = order_deliverd_amount;
        this.isLoading = false;
      });
    },

    getOrderCountVendorWise() {
      const payload = {
        filters: this.vendorFilters,
      };
      orderCountVendorWise(this.vendorId, payload).then(
        ({ total, cancelled, delivered, order_total_amount, order_under_process_amount, order_cancel_amount, order_deliverd_amount, under_process_order_count }) => {
          this.totalCount = total;
          this.activeCount = under_process_order_count;
          this.cancelledCount = cancelled;
          this.deliveredCount = delivered;
          this.totalOrderAmount = order_total_amount;
          this.totalOrderUnderProcessAmount = order_under_process_amount;
          this.totalOrderCancelAmount = order_cancel_amount;
          this.totalOrderDeliveredAmount = order_deliverd_amount;
          this.isLoading = false;
        }
      );
    },

    getAllCount() {
      this.isLoading = true;
      getCountForAllOrders(this.companyFilterIdFromVuex).then(
        ({ total, under_process_order_count, cancelled, delivered, order_total_amount, order_under_process_amount, order_cancel_amount, order_deliverd_amount }) => {
          this.totalCount = total;
          this.activeCount = under_process_order_count;
          this.cancelledCount = cancelled;
          this.deliveredCount = delivered;
          this.totalOrderAmount = order_total_amount;
          this.totalOrderUnderProcessAmount = order_under_process_amount;
          this.totalOrderCancelAmount = order_cancel_amount;
          this.totalOrderDeliveredAmount = order_deliverd_amount;
          this.isLoading = false;
        }
      );
    },

    getAllOrderCountAndAmount() {
      this.isLoading = true;
      const payload = {
        filter: this.vendorFilters,
      };
      allOrderCountAndAmount(payload).then(({ total, under_process_order_count, cancelled, delivered, order_total_amount, order_under_process_amount, order_cancel_amount, order_deliverd_amount }) => {
        this.totalCount = total;
        this.activeCount = under_process_order_count;
        this.cancelledCount = cancelled;
        this.deliveredCount = delivered;
        this.totalOrderAmount = order_total_amount;
        this.totalOrderUnderProcessAmount = order_under_process_amount;
        this.totalOrderCancelAmount = order_cancel_amount;
        this.totalOrderDeliveredAmount = order_deliverd_amount;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.order-details {
  @apply grid grid-cols-2 2xl:grid-cols-4 gap-3;
}

.order-widget {
  @apply bg-white flex items-center justify-center gap-6 pt-2 pb-4 rounded-md border-2 hover:drop-shadow-lg transition-all duration-300 cursor-pointer;
  &.active {
    @apply bg-success-200;
  }
}

.title {
  @apply text-2xl font-bold block text-primary-500 mb-2;
}

.order-amount {
  @apply block;
}
</style>
