<template>
  <router-view :allLocations="allLocations"></router-view>
</template>

<script>
import { getLocations } from "@/api/vendor/vendor.js";

export default {
  data() {
    return {
      allLocations: [],
    };
  },
  mounted() {
    this.getAllLocations();
  },
  methods: {
    getAllLocations() {
      const params = {
        limit: -1,
        include: "cities",
      };
      getLocations(params)
        .then((res) => {
          this.allLocations = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style></style>
