import { render, staticRenderFns } from "./FilterTabs.vue?vue&type=template&id=39f3620e&scoped=true"
import script from "./FilterTabs.vue?vue&type=script&lang=js"
export * from "./FilterTabs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39f3620e",
  null
  
)

export default component.exports