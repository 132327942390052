<template>
  <div>
    <ValidationObserver #default="{ invalid }">
      <s-field-validate rules="required|positiveNonZeroInteger">
        <s-textbox type="number" name="credit" v-model.number="credits" />
      </s-field-validate>
      <s-button fluid color="primary" label="Update" @click.native="update" :loader="saving" class="mt-2" :disabled="invalid" />
    </ValidationObserver>
  </div>
</template>

<script>
import { changeCredit } from "@/api/company";

export default {
  data() {
    return {
      credits: 0,
      saving: false,
    };
  },
  props: ["value", "companyId"],
  created() {
    if (this.value) this.credits = this.value;
  },
  methods: {
    async update() {
      this.saving = true;
      await changeCredit(this.companyId, this.credits);

      this.$emit("credit-updated");
      this.saving = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
