<template>
  <div class="w-full h-full">
    <portal to="header-actions">
      <CustomDatePicker v-model="dateSelected" :clearable="false" />
    </portal>

    <div class="header-flex my-3">
      <div class="count-down-flex">
        <CountDown label="Data Refreshes in" :time="timer" />
        <s-button class="refresh" theme="solid" icon="vmdi-refresh" shape="circle" @click.native="dashboardRefresh()" style="background-color: white" />
      </div>
      <StatisticsCardWrapper :dateRange="dateSelected" />
    </div>
    <div class="three-chart my-3">
      <OrderCountDonutChart :dateRange="dateSelected" />
      <CategoryWiseTotalSales :dateRange="dateSelected" />
      <PaymentStatistics :dateRange="dateSelected" />
    </div>

    <!-- <div>
      <OverdueOrderList />
    </div> -->
    <div class="my-3">
      <BrandPieChartWrapper :dateRange="dateSelected" />
    </div>

    <div class="two-chart my-3">
      <OrderSalesChart :dateRange="dateSelected" :key="`order-sales-chart-${categoryWiseIndex}`" />

      <CategoryWiseSales :key="`category-wise-sales-${categoryWiseIndex}`" />
    </div>

    <QuantitySoldCategoryWise :dateRange="dateSelected" class="my-3" />
  </div>
</template>

<script>
import CustomDatePicker from "@/components/CustomDatePicker.vue";
import OrderCountDonutChart from "@/components/dashboard/donutCharts/OrderCount.vue";
import OrderSalesChart from "@/components/dashboard/barCharts/OrderSales";
import CategoryWiseSales from "@/components/dashboard/barCharts/CategoryWiseSales.vue";
import CategoryWiseTotalSales from "@/components/dashboard/donutCharts/CategoryWiseTotalSales.vue";
import PaymentStatistics from "@/components/dashboard/donutCharts/PaymentStatistics.vue";
import CountDown from "@/components/CountDown.vue";
import moment from "moment";

export default {
  data() {
    return {
      dateSelected: ["01-01-2022", moment().format("DD-MM-YYYY")],
      categoryWiseIndex: 1,
      timer: 120000,
      refreshIntervalId: null,
    };
  },
  components: {
    CountDown,
    StatisticsCardWrapper: require("@/components/dashboard/StatisticsCardWrapper.vue").default,
    OrderSalesChart,
    CustomDatePicker,
    BrandPieChartWrapper: require("@/components/dashboard/pieCharts/brands/Wrapper.vue").default,
    OrderCountDonutChart,
    CategoryWiseSales,
    QuantitySoldCategoryWise: require("@/components/dashboard/lineCharts/QuantitySoldCategoryWise").default,
    CategoryWiseTotalSales,
    PaymentStatistics,
    // OverdueOrderList: require("@/components/dashboard/OverdueOrdersList.vue").default,
  },
  created() {
    // this.getQuarter();
    this.refreshIntervalId = setInterval(() => {
      this.dashboardRefresh();
    }, this.timer);
  },
  beforeDestroy() {
    clearInterval(this.refreshIntervalId);
  },
  methods: {
    dashboardRefresh() {
      this.$root.$emit("dashboard-refresh");
      this.categoryWiseIndex++;
    },
    getQuarter() {
      let currentQuarter = moment().quarter();
      if (currentQuarter === 1) {
        this.dateSelected = [`01-01-${moment().format("YYYY")}`, `${moment().format("DD-MM-YYYY")}`];
      } else if (currentQuarter === 2) {
        this.dateSelected = [`01-04-${moment().format("YYYY")}`, `${moment().format("DD-MM-YYYY")}`];
      } else if (currentQuarter === 3) {
        this.dateSelected = [`01-07-${moment().format("YYYY")}`, `${moment().format("DD-MM-YYYY")}`];
      } else {
        this.dateSelected = [`01-10-${moment().format("YYYY")}`, `${moment().format("DD-MM-YYYY")}`];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-flex {
  @apply flex flex-wrap gap-4;
}

.count-down-flex {
  @apply flex flex-nowrap items-center gap-4;
}
.three-chart {
  @apply flex flex-wrap gap-4;
  > div {
    min-width: 496px;
    width: calc(100% / 3 - 11px);
  }
}
.two-chart {
  @apply flex flex-wrap gap-4;
  > div:nth-child(1) {
    width: calc(60% - 8px);
  }
  > div:nth-child(2) {
    width: calc(40% - 8px);
  }
}

@media (max-width: 1280px) {
  .two-chart {
    > div:nth-child(1) {
      width: 100%;
    }
    > div:nth-child(2) {
      width: 100%;
    }
  }
}
</style>
