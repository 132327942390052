<template>
  <div class="flex flex--fit flex--nowrap gap-4 items-center w-full right-timeline">
    <div class="z-20 flex flex--fit flex--nowrap items-center order-1 bg-gray-200 shadow-xl w-4 h-4 rounded-full">
      <h1 class="mx-auto font-semibold text-lg text-white"></h1>
    </div>
    <div class="order-1 bg-gray-200 rounded-lg shadow-xl w-5/12 px-4 py-3">
      <h3 class="mb-3 font-bold text-primary-500 text-md">{{ formatDate(comment.created_at) }}</h3>
      <p class="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">
        {{ comment.description }}
      </p>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utilities/common";
export default {
  props: {
    status: {
      type: Object,
    },
    index: {
      type: Number,
    },
    comment: {
      type: Object,
    },
  },
  computed: {},
  methods: {
    formatDate,
  },
};
</script>

<style lang="scss" scoped></style>
