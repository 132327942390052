<template>
  <s-radio-button :value="value" :options="options" @input="$emit('input', $event)" name="brand-options" class="flex gap-2 flex--fit" />
</template>

<script>
export default {
  props: {
    value: [Boolean, Number],
  },
  data() {
    return {
      options: [
        {
          label: "Included",
          value: 1,
        },
        {
          label: "Extra",
          value: 0,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
