<template>
  <div class="p-4 card-wrap w-3/5" ref="item">
    <div v-if="!isLoading">
      <div>
        <p class="text-2xl font-medium mb-2">Order Auto Confirm</p>
        <s-field-validate class="w-5/12 mr-2 field--required" label="Order auto confirm" rules="required" name="orderConfirmTime" after="hour(s)">
          <STextbox type="text" v-model.number="orderConfirmTime" />
        </s-field-validate>
      </div>

      <s-button type="submit" label="Update" color="primary" class="button-sm mt-4 w-full" :loader="saving" :disabled="!orderConfirmTime" @click.native="updateSettings" />
    </div>

    <Loader class="flex" v-else />
  </div>
</template>
<script>
import { getSlotSettings, updateSlotSettings } from "@/api/vendor/bid-slot-setting.js";
import Loader from "@/components/Loader.vue";
export default {
  data() {
    return {
      orderConfirmTime: null,
      saving: false,
      isLoading: false,
    };
  },

  mounted() {
    this.getSots();
  },

  components: {
    Loader,
  },

  methods: {
    getSots() {
      this.isLoading = true;
      getSlotSettings()
        .then(({ data }) => {
          if (data && data.length > 0) {
            this.orderConfirmTime = data[6].time;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$shilp.notify({
            title: err.message,
            type: "danger",
          });
        });
    },
    updateSettings() {
      this.saving = true;
      const postData = [
        {
          type: "buyer_order_default_confirm_time",
          time: this.orderConfirmTime,
        },
      ];
      updateSlotSettings({ setting: postData })
        .then(() => {
          this.$shilp.notify({
            title: "Settings updated successfully.",
            type: "success",
          });
          this.saving = false;
        })
        .catch((err) => {
          this.saving = false;
          this.$shilp.notify({
            title: err.message,
            type: "danger",
          });
        });
    },
  },
};
</script>