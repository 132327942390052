import $axios from "@/plugins/axios";

export const getAll = (params) => {
  return $axios.get("issues", { params }).then((res) => res.data);
};

export const getTypes = (params) => {
  return $axios.get("vendor/issue-type", { params }).then((res) => res.data);
};

export const getTypeCounts = (params) => {
  return $axios.get("vendor/issue/count/all", { params }).then((res) => res.data);
};

export const getDetails = (id, params) => {
  return $axios.get("issues/"+id, { params }).then((res) => res.data);
};

export const update = (id, payload, params = null) => {
  return $axios.put(`issues/${id}`, payload, { params }).then((res) => res.data);
};

export const createComment = (payload, params) => {
  return $axios.post(`vendor/issue/comment`, payload, { params }).then((res) => res.data);
};