import $axios from "@/plugins/axios";

export const getAll = (params = null) => {
  return $axios.get(`notifications`, { params }).then((res) => {
    return res.data;
  });
};

export const getDetail = (id, params = null) => {
  return $axios.get(`notifications/${id}`, { params }).then((res) => {
    return res.data;
  });
};

export const notificationCount = (params = null) => {
  return $axios.get(`notifications/count/all`, { params }).then((res) => {
    return res.data;
  });
};
