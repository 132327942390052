<template>
  <div id="app">
    <s-notification-container width="280px" />
    <s-confirm />

    <router-view></router-view>

    <s-modal-container />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  mounted() {
    document.title = "BuilditIndia Admin Panel";
  },
  methods: {},
};
</script>
