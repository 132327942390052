export default {
  pending: 1,
  shipped: 2,
  delivered: 3,
  confirmed: 4,
  onhold: 5,
  cancelled: 6,
  partial_shipped: 10,
  partial_delivered: 11,
  under_process: [1, 5, 4, 10, 11, 2],
};
