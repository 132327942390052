import Vue from "vue";
// Register a global custom directive called `v-scroll-into-view`
Vue.directive("scroll-into-view", {
  // When the bound element is inserted into the DOM...
  inserted: function (el, binding) {
    el.setAttribute("id", binding.value.id);
  },
});

const scrollBehavior = (to, from) => {
  // using id to provide scrollIntoView element by id for list page
  if (from.params.storeScrollState) {
    to.meta["scrollId"] = from.params.id;
  }
};

export { scrollBehavior };
