var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"shilp-loader",rawName:"v-shilp-loader.overlay",value:(_vm.isLoading),expression:"isLoading",modifiers:{"overlay":true}}]},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SFieldValidate',{staticClass:"premium-field",attrs:{"rules":("required|" + _vm.ruleType),"name":"Premium","label":"Enter Premium (Applicable on unit price)"},scopedSlots:_vm._u([{key:"after",fn:function(){return [_c('SRadioButton',{staticClass:"flex flex--fit flex--nowrap g-2",attrs:{"activeColor":"secondary","inactiveColor":"secondary","name":"premium_type","options":[
            // {
            //   label: '%',
            //   value: 'percentage',
            // },
            {
              label: '₹',
              value: 'amount',
            } ]},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})]},proxy:true}],null,true)},[_c('STextbox',{attrs:{"type":"number","placeholder":("Enter " + _vm.selectedType),"disabled":_vm.orderStatus.id === _vm.$options.orderStatusData.cancelled},model:{value:(_vm.premium),callback:function ($$v) {_vm.premium=$$v},expression:"premium"}})],1),_c('s-button',{staticClass:"mt-2",attrs:{"label":"Apply","color":"secondary","disabled":invalid || _vm.disabled,"loading":_vm.isLoading},nativeOn:{"click":function($event){return _vm.submit.apply(null, arguments)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }