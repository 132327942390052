import $axios from "@/plugins/axios";

export const getBrandOptions = () => {
  return $axios
    .get(`brands`, { params: { limit: -1 } })
    .then((res) => {
      return res.data;
    })
    .then((res) => {
      const brandOptions = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
          ...item,
        };
      });

      return brandOptions;
    });
};

export const getCategoryOptions = () => {
  return $axios
    .get(`categories`, { params: { limit: -1 } })
    .then((res) => {
      return res.data;
    })
    .then((res) => {
      const categoryOptions = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      return categoryOptions;
    });
};

export const getPaymentStatusOptions = () => {
  return $axios.get("paymentStatuses", { params: { limit: -1 } }).then((res) => {
    let options = [];

    res.data.data.forEach((item) => {
      options.push({
        label: item.display_name,
        value: item.id,
        ...item,
      });
    });
    return options;
  });
};

export const getOrderStatusOptions = (params = null) => {
  return $axios.get("orderStatuses", { params }).then((res) => {
    let options = [];

    res.data.data.forEach((item) => {
      options.push({
        label: item.display_name,
        value: item.id,
        ...item,
      });
    });
    return options;
  });
};

export const getInquiryStatusOptions = (params = null) => {
  return $axios.get("inquiryStatus", { params }).then((res) => {
    let options = [];

    res.data.data.forEach((item) => {
      options.push({
        label: item.name,
        value: item.id,
        ...item,
      });
    });
    return options;
  });
};