<template>
  <!-- AAC Blocks Table  -->
  <div>
    <div class="border-2 border-gray-200 p-2 my-2 rounded-md flex flex--fit gap-2 flex--wrap">
      <div class="text-grey text-md"><sup>*</sup> <b>Discount</b> will be considered when percentage will be <b>negative</b> <span class="hidden md:inline"> | </span></div>
      <div class="text-grey text-md"><sup>*</sup> <b>Premium</b> will be considered when percentage will be <b>positive</b></div>
    </div>

    <div class="grid-wrapper">
      <SpList endpoint="buyerTypeCategoryDiscounts" ref="aacBlocksTable" :attrs="columns" :filters="{ category_id: 3 }" :params="$options.params" :actions="[]" :footer="false">
        <SpListTable>
          <template #category="{ item, rowIndex }">
            <span v-if="rowIndex == 0">{{ item.category.name }}</span>
            <span v-else></span>
          </template>

          <template #percentage="{ item }">
            <s-field>
              <s-textbox :key="`percentage-input-${item.id}`" type="number" :value="item.percentage" @focus.native="$event.target.select()" @input="update(item, Number($event), 'aacBlocksTable')" />
            </s-field>
          </template>
        </SpListTable>
      </SpList>

      <SpList endpoint="buyerTypeCategoryDiscounts" ref="tmtBarTable" :attrs="columns" :filters="{ category_id: 2 }" :params="$options.params" :actions="[]" :footer="false">
        <SpListTable>
          <template #category="{ item, rowIndex }">
            <span v-if="rowIndex == 0">{{ item.category.name }}</span>
            <span v-else></span>
          </template>

          <template #percentage="{ item }">
            <s-field>
              <s-textbox :key="`percentage-input-${item.id}`" type="number" :value="item.percentage" @focus.native="$event.target.select()" @input="update(item, Number($event), 'tmtBarTable')" />
            </s-field>
          </template>
        </SpListTable>
      </SpList>

      <SpList endpoint="buyerTypeCategoryDiscounts" ref="cementTable" :attrs="columns" :filters="{ category_id: 1 }" :params="$options.params" :actions="[]" :footer="false">
        <SpListTable>
          <template #category="{ item, rowIndex }">
            <span v-if="rowIndex == 0">{{ item.category.name }}</span>
            <span v-else></span>
          </template>

          <template #percentage="{ item }">
            <s-field>
              <s-textbox :key="`percentage-input-${item.id}`" type="number" :value="item.percentage" @focus.native="$event.target.select()" @input="update(item, Number($event), 'cementTable')" />
            </s-field>
          </template>
        </SpListTable>
      </SpList>
    </div>
  </div>
</template>

<script>
import { updateBuyerTypeCategoryDiscounts } from "@/api/buyerType";
export default {
  params: {
    include: "masterBuyerType,category",
    limit: -1,
  },

  created() {},
  data() {
    return {
      saving: false,
      buyerType: [],
      isUpdating: false,
      fields: ["name", "percentage"],

      columns: [
        {
          name: "category",
          label: "Category",
          value: (item) => item.category.name,
        },
        {
          name: "buyer_type",
          label: "Buyer Type",
          value: (item) => item.buyerType.name,
        },
        {
          name: "percentage",
          label: "Percentage",
          width: "150px",
          value: (item) => item.percentage,
        },
      ],
    };
  },
  methods: {
    update(row, percentage, tableName) {
      this.isUpdating = true;
      let data = {
        buyerTypeCategoryDiscount: [
          {
            id: row.id,
            percentage: percentage,
            master_buyer_type_id: row.master_buyer_type_id,
            category_id: row.category_id,
          },
        ],
      };
      updateBuyerTypeCategoryDiscounts(data)
        .then(() => {
          this.$refs[tableName].refresh();
        })
        .catch()
        .finally(() => {
          this.isUpdating = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.grid-wrapper {
  @apply grid grid-cols-1 md:grid-cols-2 gap-4;
}
</style>
