export default {
  computed: {
    tableCurrentPage() {
      return this.$refs.list.$data?.localPage;
    },

    tableLastPage() {
      return this.$refs.list.$data?.response?.pagination?.last_page;
    },
  },
  watch: {
    $route() {
      // providing feature when go to detail page and come back, admin should able to see that record without scrolling manually
      if (this.$route.meta?.scrollId) {
        setTimeout(() => {
          document.getElementById(this.$route.meta.scrollId).scrollIntoView({
            behavior: "smooth",
          });
        }, 1200);
      }
    },
  },
  methods: {
    loadMoreData() {
      if (this.tableCurrentPage < this.tableLastPage) {
        return this.$refs.list.loadMore();
      }
    },
  },
};
