<template>
  <s-button-group color="secondary" theme="tab" size="lg" v-shilp-loader.overlay="isCountLoading">
    <s-button>
      <template #before>
        <span :class="{ 'text-secondary-500 font-bold': isActive(null) }" @click="onSelect(null)">
          ALL <span v-if="counts">({{ totalCount }})</span></span
        >
      </template>
      <template #after>
        <span class="ml-2" :class="{ 'text-secondary-500 font-bold': isActive('unread-filter') }" @click="onSelect('unread-filter')"> Unread ({{ unreadCount }})</span>
      </template>
    </s-button>
    <s-button v-for="category in categoryOptions" :key="category.value" :label="category.label" :active="isActive(category.value)" buttonLabel="test" @click.native="onSelect(category.value)">
      <template #after>
        <span class="ml-2 font-bold" v-if="counts"> ({{ counts[category.label] }}) </span>
      </template>
    </s-button>
  </s-button-group>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import { categoryWiseOrderCount, getCountForAllOrders, countStatistics } from "@/api/order";
export default {
  data() {
    return {
      active: null,
      counts: null,
      unreadCount: 0,
      isCountLoading: true,
    };
  },

  created() {
    this.getCount();

    if (this.filters.category_id) this.onSelect(this.filters.category_id);
  },
  computed: {
    ...mapGetters({
      categoryOptions: "categoryOptions",
      filters: "order/filters",
      expectedDeliveryDate: "order/expectedDeliveryDate",
    }),
    totalCount() {
      if (this.counts) return Object.values(this.counts).reduce((value, total) => (total = total + value));
      else return 0;
    },

    params() {
      return {
        filter: {
          ...this.filters,
          category_id: undefined,
        },
        expected_delivery_date: this.expectedDeliveryDate,
      };
    },
  },

  watch: {
    params: {
      deep: true,
      handler: function () {
        this.getCount();
      },
    },
  },
  methods: {
    ...mapMutations({
      setCategoryId: "order/setCategoryId",
      setReadStatus: "order/setReadStatus",
    }),
    getCount() {
      this.isCountLoading = true;
      categoryWiseOrderCount(this.params).then((res) => {
        this.counts = res;

        if (this.filters.company_id) {
          countStatistics(this.filters.company_id).then((res) => {
            this.unreadCount = res.unread_count;
          });
        } else {
          getCountForAllOrders().then((res) => {
            this.unreadCount = res.unread_count;
          });
        }
        this.isCountLoading = false;
      });
    },
    onSelect(categoryId) {
      if (categoryId == "unread-filter") {
        this.active = "unread-filter";
        this.setReadStatus(0);
        this.setCategoryId(null);
        this.getCount();
      } else {
        this.active = categoryId;
        this.setCategoryId(categoryId);
        this.setReadStatus(null);
        this.getCount();
      }
    },
    isActive(categoryId) {
      if (categoryId == this.active) return true;
      else return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
