<template>
  <div>
    <portal to="header-actions">
      <div class="header-actions">
        <s-button color="primary" label="Add New Product" icon="IconIcRoundPlus" :to="{ name: 'product-upsert', params: { id: '+' } }" v-if="isProductCreateGranted" />

        <s-button v-if="isBulkActionsGranted" color="primary" label="Bulk Actions" icon="IconIcExcel" :to="{ name: 'bulk-actions-home' }" class="ml-2" />
      </div>
    </portal>

    <FilterBar @resetFilters="resetFilters">
      <template #default>
        <s-field label="Categories">
          <v-select
            label="name"
            placeholder="Select"
            :options="categories"
            :reduce="(category) => category.id"
            :value="filters.category_id"
            @input="
              resetFilters();
              setCategoryId($event);
            "
          />
        </s-field>

        <s-field v-if="isBrandListVisible" label="Brands" class="mt-2">
          <BrandSelect v-if="brands" :value="filters.brand_id" @input="setBrandFilter" />
          <!-- <v-select label="name" placeholder="Select" :options="brands" :reduce="(brand) => brand.id" :value="filters.brand_id" @input="setBrandFilter" /> -->
        </s-field>

        <!-- <CementAttributes class="mt-2 flex flex--col g-2" v-if="filters.category_id == 1" @packing-selected="setAttributeFilter" @grade-selected="setAttributeFilter" /> -->

        <!-- <TmtBarAttributes class="mt-2 flex flex--col g-2" v-if="filters.category_id == 2" @size-selected="setAttributeFilter" @grade-selected="setAttributeFilter" /> -->

        <!-- <AacBlocksAttributes class="mt-2 flex flex--col g-2" v-if="filters.category_id == 3" @size-selected="setAttributeFilter" @grade-selected="setAttributeFilter" /> -->
      </template>
    </FilterBar>

    <sp-list
      ref="list"
      pagination-mode="infinite"
      v-infinite-scroll="loadMoreData"
      :attrs="columns"
      :filters="filters"
      endpoint="products"
      :perPage="$options.params.limit"
      :params="$options.params"
      :actions="['search', 'filters', 'refresh']"
    >
      <template #header="{ refresh }">
        <div></div>
        <div class="filter-section">
          <!-- <s-field class="filter-search">
            <s-textbox v-model="filters.search" placeholder="Search1" />
          </s-field> -->
          <SearchFilter :value="filters.search" @search="filters.search = $event" />
          <s-button-group>
            <s-button class="filter" icon="vmdi-filter" shape="circle" @click.native="$root.$emit('filter-bar-open')" />
            <s-button class="refresh" icon="vmdi-refresh" shape="circle" @click.native="refresh()" />
          </s-button-group>
        </div>
      </template>
      <template #empty>
        <list-empty title="No Products Found" />
      </template>

      <sp-list-table @rowClick="goToItem">
        <template #price="{ item }">
          <product-price :price="item.price" :unit="item.unit" />
        </template>

        <template #brand_logo="{ item }">
          <img :src="item.brand.logo.url" v-if="item.brand && item.brand.logo" class="w-10 h-10" />
        </template>

        <template #status="{ item }">
          <Tag :label="item.status" :color="item.status == 'active' ? 'green' : 'red'" />
        </template>

        <template #actions="{ item }">
          <s-button v-if="isProductDeleteGranted" icon="IconMdiTrashCanOutline" shape="circle" color="danger" theme="muted" @click.native="deleteProduct(item.id)" />
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>

<script>
import { getBrands } from "@/api/brand";
import { getCategories } from "@/api/category";
import { remove } from "@/api/product";
import { mapMutations, mapGetters } from "vuex";

import tableMixin from "@/mixins/table";
import BrandSelect from "@/components/brand/BrandSelect.vue";
import roleMixin from "@/mixins/role";

export default {
  params: {
    limit: 25,
    include: "brand,attributeOptions.attribute,categories",
  },
  components: {
    ProductPrice: require("@/components/ProductPrice.vue").default,
    // CementAttributes: require("@/components/products/CementAttributes.vue").default,
    // TmtBarAttributes: require("@/components/products/TmtBarAttributes.vue").default,
    // AacBlocksAttributes: require("@/components/products/AacBlocksAttributes.vue").default,
    SearchFilter: require("@/components/SearchFilter.vue").default,
    FilterBar: require("@/components/FilterBar").default,
    BrandSelect,
  },
  created() {
    this.getFilterOptions();
    if (this.isBrandListVisible) this.getAllBrands();
  },
  mixins: [tableMixin, roleMixin],
  data() {
    return {
      brands: [],
      categories: [],
    };
  },
  computed: {
    ...mapGetters({
      filters: "product/filters",
    }),
    columns() {
      return [
        { name: "brand_logo", label: "Brand Logo" },
        "name",
        {
          label: "Grade",
          value: (item) => item.attributeOptions.length && item.attributeOptions[0].name,
        },
        {
          label: "Size/Packing",
          value: (item) => item.attributeOptions.length && item.attributeOptions[1]?.name,
        },

        // { name: "brand", value: (item) => item.brand.name },
        "status",
        "price",
        { name: "actions", rowClick: false },
      ];
    },
  },
  watch: {
    "filters.category_id"() {
      if (this.isBrandListVisible) this.getAllBrands();
    },
  },
  methods: {
    ...mapMutations({
      setCategoryId: "product/setCategoryId",
      setBrandFilter: "product/setBrandFilter",
      setAttributeFilter: "product/setAttributeFilter",
      resetFilters: "product/resetFilters",
    }),
    goToItem($event) {
      if (this.isProductUpdateGranted) this.$router.push({ name: "product-upsert", params: { id: $event.id } });
    },

    async deleteProduct(id) {
      try {
        const adminSelectedDelete = confirm("Are you sure you want to delete");
        if (adminSelectedDelete) {
          await remove(id);
          this.$shilp.notify({
            title: "Product Deleted Successfully",
            type: "success",
          });

          this.$refs.list.refresh();
        }
      } catch {
        this.$shilp.notify({
          title: "Something went wrong while deleting product. Try again.",
          type: "danger",
        });
      }
    },
    async getAllBrands() {
      let res = await getBrands({ filter: { category_id: this.filters.category_id }, limit: -1 });
      this.brands = res.data;
    },
    async getFilterOptions() {
      let res = await getCategories({ limit: -1 });
      this.categories = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-section {
  @apply flex gap-4 items-center;
}

.button-group {
  @apply relative block;
}

.has-applied {
  @apply text-secondary-500 z-10;
  background: none !important;
}

.not-applied {
  @apply text-gray-500;
}

.animate {
  @apply absolute left-0 bg-secondary-100 animate-ping p-4 rounded-full;
}
</style>
