import $axios from "@/plugins/axios";

export const getAll = (params = null) => {
  return $axios
    .get("teams", {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

export const get = (id, params = null) => {
  return $axios
    .get(`teams/${id}`, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

export const create = (data, params = null) => {
  return $axios.post("teams", data, { params }).then((res) => res.data);
};

export const update = (id, data, params = null) => {
  return $axios.put(`teams/${id}`, data, { params }).then((res) => res.data);
};

export const remove = (id, params = null) => {
  return $axios.delete(`teams/${id}`, { params }).then((res) => res);
};
