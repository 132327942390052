export default [
  {
    path: "active-companies",
    name: "active-company",
    component: require("@/views/private/buyer/companies/ActiveIndex").default,
    meta: {
      label: "Active Companies",
    },
    redirect: { name: "approved-companies" },
    children: [
      {
        path: "approve",
        props: true,
        name: "approved-companies",
        component: require("@/views/private/buyer/companies/ApprovedList").default,
        meta: {
          label: "Approved Companies",
          icon: "IconMdiOfficeBuildingOutline",
          breadcrumbs: ["dashboard", "approved-companies"],
        },
      },
      {
        path: "approve/view/statistics/:id",
        props: (route) => ({ id: Number(route.params.id) }),
        name: "approved-companies-statistics",
        component: require("@/views/private/buyer/companies/Statistics").default,
        meta: {
          label: "Summary",
          icon: "IconMdiOfficeBuildingOutline",
          breadcrumbs: ["dashboard", "approved-companies", "approved-companies-statistics"],
        },
      },
      {
        path: "approve/view/profile/:id",
        props: (route) => ({ id: Number(route.params.id) }),

        name: "approved-company-profile",
        component: require("@/views/private/buyer/companies/Profile").default,
        meta: {
          label: "Company Profile",
          breadcrumbs: ["dashboard", "approved-companies", "approved-company-profile"],
        },
      },
    ],
  },

  {
    path: "action-required",
    name: "action-required",
    component: require("@/views/private/buyer/companies/Index").default,
    redirect: { name: "company-listing" },
    children: [
      {
        path: "list/:status",
        props: true,
        name: "company-listing",
        component: require("@/views/private/buyer/companies/List").default,
        meta: {
          label: "Companies",
          icon: "IconMdiOfficeBuildingOutline",
          breadcrumbs: ["dashboard", "company-listing"],
        },
      },
      {
        path: ":status/view/statistics/:id",
        props: (route) => ({ id: Number(route.params.id) }),

        name: "company-statistics",
        component: require("@/views/private/buyer/companies/Statistics").default,
        meta: {
          label: "Company Statistics",
          breadcrumbs: ["dashboard", "company-listing", "company-statistics"],
        },
      },
      {
        path: ":status/view/profile/:id",
        props: (route) => ({ id: Number(route.params.id) }),
        name: "company-profile",
        component: require("@/views/private/buyer/companies/Profile").default,
        meta: {
          label: "Company Profile",
          breadcrumbs: ["dashboard", "company-listing", "company-profile"],
        },
      },
    ],
  },

  {
    path: "company/:id",
    name: "upsert-company",
    props: true,
    component: require("@/views/private/buyer/companies/Item.vue").default,
    meta: {
      label: "Add Company",
      breadcrumbs: ["dashboard", "upsert-company"],
    },
  },
];
