import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations({
      setCompanyFilterId: "setCompanyFilterId",
    }),
    goToApprovedCompanyProfile(id, status) {
      this.setCompanyFilterId(id);
      this.$router.push({ name: "approved-company-profile", params: { id: id }, query: { status: status } });
    },

    goToNotApprovedCompanyProfile(id, status) {
      this.setCompanyFilterId(id);
      this.$router.push({ name: "company-profile", params: { id: id, status: status }, query: { status: status } });
    },
  },
};
