<template>
  <div>
    <label for="" class="block text-gray-500">Account Name</label>
    <span class="text-lg">{{ name }}</span>
  </div>
</template>

<script>
export default {
  props: ["name"],
};
</script>

<style lang="scss" scoped></style>
