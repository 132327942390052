export default [
  {
    path: "orders",
    name: "orders",
    component: require("@/views/private/buyer/order/Index").default,
    meta: {
      label: "Order",
    },
    redirect: { name: "order-list" },

    children: [
      {
        path: "list",
        props: true,
        name: "order-list",
        component: require("@/views/private/buyer/order/List").default,
        meta: {
          label: "Orders",
          breadcrumbs: ["dashboard", "order-list"],
        },
      },
      {
        path: "order-details/detail/:id",
        name: "order-details",
        component: require("@/views/private/buyer/order/View").default,
        props: true,
        meta: {
          label: "Order Details",
          breadcrumbs: ["dashboard", "order-list", "order-details"],
        },
      },
    ],
  },
];
