<template>
  <div>
    <div class="rounded overflow-hidden bg-white p-3 mb-2 w-full">
      <div v-if="details && !isLoading" class="flex flex--fit">
        <div class="mr-5">
          <p class="font-medium mr-2">Vendor Name</p>
          <p class="font-medium text-gray-500">{{ details.name }}</p>
        </div>
        <div class="mr-5">
          <p class="font-medium mr-2">GST Number</p>
          <p class="font-medium text-gray-500">{{ details.vendorDetail.vendor_gst_no }}</p>
        </div>
        <div>
          <p class="font-medium mr-5">Location</p>
          <p class="font-medium text-gray-500">{{ details.vendorDetail.vendor_office_address }}</p>
        </div>
      </div>
      <Loader class="flex" v-else />
    </div>
    <Item @productAdded="productSubmitted" />
    <List ref="listComponent" />
  </div>
</template>

<script>
import Item from "./Item.vue";
import List from "./List.vue";
import { get } from "@/api/vendor/vendor.js";
import Loader from "@/components/Loader.vue";
export default {
  data() {
    return {
      details: null,
      isLoading: false,
    };
  },
  components: {
    Item,
    List,
    Loader,
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      this.isLoading = true;
      get(this.$route.params.id)
        .then((res) => {
          this.details = res;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    productSubmitted() {
      this.$refs.listComponent.refreshList();
    },
  },
};
</script>