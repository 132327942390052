import $axios from "@/plugins/axios";

export const getBrands = (params = null) => {
  return $axios.get(`brands`, { params: params }).then((res) => {
    return res.data;
  });
};

export const get = (id, params = null) => {
  return $axios.get(`brands/${id}`, { params: params }).then((res) => {
    return res.data;
  });
};
export const create = (data) => {
  return $axios.post("brands", data).then((res) => {
    return res.data;
  });
};

export const update = (id, data) => {
  return $axios.post(`brands/${id}`, data).then((res) => {
    return res.data;
  });
};

export const remove = (id) => {
  return $axios.delete(`brands/${id}`).then((res) => {
    return res.data;
  });
};

export const allBrandCount = () => {
  return $axios.get("brands/count/all").then((res) => {
    return res.data;
  });
};

export const topBrandsCategoryWise = (params = null) => {
  return $axios.get("top/brands/categorywise", { params }).then((res) => res.data);
};
