<template>
  <item-form :submitOnEnter="false" :fields="fields" :redirect="false" :notify="false" class="p-4 card-wrap" width="100%" ref="item" :get="getItem" :item="id" :save="saveItem">
    <template #default="{ form }">
      <div class="md:grid grid-cols-[400px_auto] gap-4">
        <div class="flex gap-2">
          <s-field-validate label="Name" rules="required" name="Name">
            <s-textbox v-model="form.name" placeholder="Enter Brand Name" />
          </s-field-validate>

          <s-field-validate label="Expected Delivery Days" rules="required|positiveNonZeroInteger" name="expected_delivery_days">
            <s-textbox v-model.number="form.expected_delivery_days" placeholder="Enter Number of Days" type="number" />
          </s-field-validate>

          <s-field-validate label="Tag" name="Tag">
            <v-select :options="$options.options.brandTags" label="label" placeholder="Select" :reduce="(item) => item.value" v-model="form.tag" />
            <!-- <s-textbox v-model="form.tag" placeholder="Enter Brand Tag" ref="tag" /> -->
          </s-field-validate>

          <s-field-validate label="Logo" rules="required" name="Logo">
            <VueFileAgentWrapper :linkable="true" accept=".jpg,.png,.jpeg" v-model="form.logo" description="Choose any image" />
            <!-- <file-upload :readonly="false" accept=".jpg,.png,.jpeg" v-model="form.logo" width="100px" ratio="1x1" /> -->
          </s-field-validate>

          <!-- Loading Included / Excluded -->
          <s-field label="Loading">
            <BrandOptions v-model="form.loading" key="loading-options" />
          </s-field>

          <!-- UnLoading Options -->
          <s-field label="Unloading">
            <BrandOptions v-model="form.unloading" key="unloading-options" />
          </s-field>

          <!-- Freight service options -->
          <s-field label="Freight">
            <BrandOptions v-model="form.freight" key="freight-options" />
          </s-field>
        </div>

        <div>
          <s-field label="Brand Description">
            <QuillEditor v-model="form.description" />
          </s-field>
        </div>
      </div>
    </template>
    <template #footer="{ saving, saveItem }">
      <div>
        <s-button @click.native="saveItem()" label="Submit" color="primary" :loader="saving" />
      </div>
    </template>
  </item-form>
</template>

<script>
import { update, create, get } from "@/api/brand";
import { getBrandOptions } from "@/api/common";
import { serialize } from "object-to-formdata";
import fileUploadMixin from "@/mixins/fileUpload";
import options from "@/data/options.js";
import { mapActions } from "vuex";
import VueFileAgentWrapper from "@/components/VueFileAgentWrapper.vue";
import { cloneDeep } from "lodash-es";
import QuillEditor from "@/components/brand/QuillEditor.vue";
export default {
  options,
  components: {
    // FileUpload: require("@/components/FileUpload.vue").default,
    BrandOptions: require("@/components/brand/Options.vue").default,
    VueFileAgentWrapper,
    QuillEditor,
  },
  mixins: [fileUploadMixin],
  props: ["id"],
  data() {
    return {
      // REFACTOR Tell backend to update below loading unloading value to true false instead of 0 and 1
      tag: "",
      fields: ["name", "expected_delivery_days", "tag", "logo", { name: "loading", value: 1 }, { name: "unloading", value: 0 }, { name: "freight", value: 1 }, "description"],
    };
  },
  methods: {
    ...mapActions({
      setBrandOptions: "setBrandOptions",
    }),
    getItem(id) {
      return get(id).then((res) => {
        this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, res.name);
        return res;
      });
    },

    async saveItem(id, form) {
      let formData = cloneDeep(form);
      if (formData.logo && formData.logo.url) {
        delete formData["logo"];
      } else {
        const isValid = this.isMediaValid(formData.logo);

        if (isValid) {
          formData.logo = formData.logo[0].file;
        } else return Promise.reject(false);
      }
      formData = serialize(formData);

      let result = null;
      if (id) {
        result = update(id, formData).then((res) => {
          return res.data;
        });
      } else {
        result = create(formData).then((res) => {
          return res.data;
        });
      }

      const brandOptions = await getBrandOptions();
      this.setBrandOptions(brandOptions);
      this.$router.push({ name: "brand-listing" });
      this.$shilp.notify({
        title: "Success",
        type: "success",
      });
      // this.isLoading = false;
      return result;
    },
  },
};
</script>

<style></style>
