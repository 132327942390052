import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import Vue from "vue";
import VueApollo from "vue-apollo";

// HTTP connection to the API

const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_STORE_GRAPHQL,
});

// Cache implementation
const cache = new InMemoryCache();

// Setting token and other headers
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists

  const token = localStorage.getItem("bit-admin-token");

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Create the apollo client

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

export { apolloProvider };
