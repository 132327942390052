<template>
  <div class="flex flex--fit flex--nowrap h-auto" v-if="orderInfo && !isLoading && selectedPaymentStatus">
    <div class="rounded overflow-hidden bg-white p-2 mr-2 w-4/12 pb-3">
      <div class="flex flex--fit justify-between mb-2">
        <p>Payment Status</p>
        <v-popover v-if="paymentStatusList" ref="popover">
          <template #popover>
            <p class="mb-1">Payment Status</p>
            <div class="flex">
              <v-select :options="paymentStatusList" v-model="selectedPaymentStatus" :clearable="false" class="w-36">
                <template v-slot:option="paymentStatusList">
                  <StatusTag :label="paymentStatusList.label" :name="paymentStatusList.name === 'Partially paid' ? 'partially_paid' : paymentStatusList.name.toLowerCase()" />
                </template>
              </v-select>
              <s-field-validate name="Amount" label="Enter Received Amount" :rules="`required|max_value:${paymentAmount}`" class="mt-2" v-if="showAmountField">
                <STextbox type="number" v-model="amount" :disabled="paymentFieldDisabled" />
              </s-field-validate>
              <s-button label="save" class="button--primary text-center mt-2" @click.native="updateVendorPaymentstatus" :loader="paymentStatusLoader" />
            </div>
          </template>
          <a class="text-blue-500 font-medium cursor-pointer">Edit</a>
        </v-popover>
      </div>
      <StatusTag :label="updatedPaymentStatus.label" :name="updatedPaymentStatus.name === 'Partially paid' ? 'partially_paid' : updatedPaymentStatus.name.toLowerCase()" />
    </div>
    <div class="rounded overflow-hidden bg-white p-2 mr-2 w-4/12">
      <div class="flex flex--fit justify-between mb-2">
        <p>Order Status</p>
        <v-popover v-if="orderStatusList" ref="popover">
          <template #popover>
            <p class="mb-1">Order Status</p>
            <div class="flex">
              <v-select :options="orderStatusList" v-model="selectedOrderStatus" :clearable="false" class="w-36 mb-1">
                <template v-slot:option="orderStatusList"> <StatusTag :label="orderStatusList.label" :name="orderStatusList.name.toLowerCase()" /> </template
              ></v-select>
              <s-button label="save" class="button--primary text-center" @click.native="updateVendorOrderStatus('orderStatus')" :loader="orderUpdateLoader" />
            </div>
          </template>
          <a class="text-blue-500 font-medium cursor-pointer">Edit</a>
        </v-popover>
      </div>
      <StatusTag :label="updatedOrderStatus.label" :name="updatedOrderStatus.name" />
    </div>
    <div class="rounded overflow-hidden bg-white p-2 !grow">
      <div class="flex flex--fit justify-between mb-2">
        <p>Payment Terms</p>
        <v-popover ref="popover">
          <template #popover>
            <div class="flex">
              <s-field-validate name="payment terms" label="Enter Payment Terms" :rules="`required`" class="mb-2 field--required" after="day(s)">
                <STextbox type="number" v-model="paymentTermDays" />
              </s-field-validate>
              <s-button label="save" class="button--primary text-center" @click.native="updateVendorOrderStatus('paymentTerm')" :loader="orderUpdateLoader" :disabled="!paymentTermDays" />
            </div>
          </template>
          <a class="text-blue-500 font-medium cursor-pointer">Edit</a>
        </v-popover>
      </div>
      <StatusTag v-if="updatedPaymentTerm" :label="updatedPaymentTerm" :name="updatedPaymentTerm.toLowerCase()" />
    </div>
  </div>
</template>

<script>
import { updateOrder, updatePaymentStatus } from "@/api/vendor/order.js";
import StatusTag from "@/components/StatusTag.vue";
export default {
  props: ["orderInfo", "isLoading", "orderStatusList", "paymentStatusList"],
  data() {
    return {
      updatedOrderStatus: null,
      selectedOrderStatus: null,
      orderUpdateLoader: false,
      updatedPaymentStatus: null,
      selectedPaymentStatus: null,
      paymentStatusLoader: false,
      updatedPaymentTerm: null,
      paymentTermDays: null,
      amount: 0,
    };
  },
  mounted() {
    this.selectedOrderStatus = { value: this.orderInfo.orderStatus.id, label: this.orderInfo.orderStatus.display_name, name: this.orderInfo.orderStatus.name };
    this.updatedOrderStatus = { value: this.orderInfo.orderStatus.id, label: this.orderInfo.orderStatus.display_name, name: this.orderInfo.orderStatus.name };
    this.selectedPaymentStatus = { value: this.orderInfo.paymentStatus.id, label: this.orderInfo.paymentStatus.display_name, name: this.orderInfo.paymentStatus.name };
    this.updatedPaymentStatus = { value: this.orderInfo.paymentStatus.id, label: this.orderInfo.paymentStatus.display_name, name: this.orderInfo.paymentStatus.name };
    this.updatedPaymentTerm = this.orderInfo.payment_term_days == 0 || this.orderInfo.payment_term_days == null ? "Advance" : `${this.orderInfo.payment_term_days} day(s)`;
    this.paymentTermDays = this.orderInfo.payment_term_days == null ? 0 : this.orderInfo.payment_term_days;
    this.amount = this.paymentAmount;
  },
  components: {
    StatusTag,
  },

  computed: {
    showAmountField() {
      if (this.selectedPaymentStatus.value == 2 || this.selectedPaymentStatus.value == 4) return true;
      else return false;
    },
    paymentFieldDisabled() {
      if (this.selectedPaymentStatus.value == 2) return true;
      else return false;
    },
    paymentAmount() {
      const total =
        this.orderInfo.total +
        this.orderInfo.freight +
        this.orderInfo.freight_gst_price +
        this.orderInfo.loading +
        this.orderInfo.loading_gst_price +
        this.orderInfo.unloading +
        this.orderInfo.unloading_gst_price;
      return total - this.orderInfo.receive_amount;
    },
  },

  methods: {
    redirectToMap() {
      window.open(`http://maps.google.com?q=${this.orderInfo.latitude},${this.orderInfo.longitude}`, "_blank");
    },
    updateVendorOrderStatus(type) {
      const id = this.$route.params.id;
      const params = {
        include: "orderStatus",
      };
      let payload = null;
      if (type == "orderStatus") {
        payload = {
          vendor_order_status_id: this.selectedOrderStatus.value,
        };
      } else {
        payload = {
          payment_term_days: this.paymentTermDays,
        };
      }

      this.orderUpdateLoader = true;
      updateOrder(id, payload, params)
        .then(() => {
          this.$refs.popover.hide();
          this.orderUpdateLoader = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          console.log(err);
          this.$shilp.notify({
            title: err.message,
            type: "danger",
          });
          this.orderUpdateLoader = false;
        });
    },

    updateVendorPaymentstatus() {
      const id = this.$route.params.id;
      const params = {
        include: "paymentStatus",
      };
      let payload = {
        vendor_payment_status_id: this.selectedPaymentStatus.value,
      };
      if (this.showAmountField) payload["receive_amount"] = Number(this.amount);
      this.paymentStatusLoader = true;
      updatePaymentStatus(id, payload, params)
        .then(() => {
          this.$refs.popover.hide();
          this.paymentStatusLoader = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          console.log(err);
          this.$shilp.notify({
            title: err.message,
            type: "danger",
          });
          this.paymentStatusLoader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vs__dropdown-option {
  margin: 9px;
}
::v-deep .status-label {
  @apply text-sm;
}
</style>
 