<template>
  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.41578 6.11022L6.96287 8.2558L4.81439 10.3935C4.01535 9.37802 3.14281 8.39427 2.61084 7.20441L3.7689 5.43037L5.54507 3.7168C4.95144 4.45046 4.99576 5.5295 5.67807 6.2118C6.40804 6.94177 7.59126 6.94177 8.3212 6.2118C8.35409 6.17891 8.38587 6.14492 8.41578 6.11022Z"
      fill="#ECB72B"
    />
    <path
      d="M8.44956 6.07016C9.04839 5.33614 9.00589 4.25339 8.32137 3.56925C7.5914 2.83928 6.40818 2.83928 5.67824 3.56925C5.64759 3.5999 5.61839 3.63132 5.59033 3.66344L6.81563 1.67154L8.478 0.227539C9.71959 0.620578 10.7465 1.49571 11.3395 2.63316L10.1267 4.58868L8.44956 6.07016Z"
      fill="#5085F7"
    />
    <path
      d="M5.54515 3.71695L2.61084 7.20461C2.31982 6.5543 2.13062 5.84243 2.1111 5.02999C2.10962 4.98381 2.10889 4.93727 2.10889 4.89073C2.10889 3.69532 2.53777 2.59962 3.25038 1.75L5.54515 3.71695Z"
      fill="#DA2F2A"
    />
    <path d="M5.54534 3.71736L3.25049 1.75027C4.1478 0.680449 5.49432 0 7.00003 0C7.51532 0 8.01199 0.0797344 8.47837 0.2275L5.54534 3.71736Z" fill="#4274EB" />
    <path
      d="M11.8908 4.89123C11.8908 4.07697 11.6918 3.30915 11.3398 2.63379L4.81445 10.3947C5.55582 11.3377 6.23324 12.3083 6.51477 13.4969C6.52379 13.535 6.5335 13.5795 6.54384 13.6295C6.64591 14.124 7.35403 14.124 7.45611 13.6295C7.46644 13.5795 7.47615 13.535 7.48517 13.4969C8.2357 10.328 11.8002 8.70853 11.8887 5.0308C11.8899 4.98442 11.8908 4.93791 11.8908 4.89123Z"
      fill="#60A850"
    />
  </svg>
</template>

<script>
export default {
  name: "GoogleMapMarker",
};
</script>