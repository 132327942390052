var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"header-actions"}},[_c('div',{staticClass:"header-actions"},[(_vm.isProductCreateGranted)?_c('s-button',{attrs:{"color":"primary","label":"Add New Product","icon":"IconIcRoundPlus","to":{ name: 'product-upsert', params: { id: '+' } }}}):_vm._e(),(_vm.isBulkActionsGranted)?_c('s-button',{staticClass:"ml-2",attrs:{"color":"primary","label":"Bulk Actions","icon":"IconIcExcel","to":{ name: 'bulk-actions-home' }}}):_vm._e()],1)]),_c('FilterBar',{on:{"resetFilters":_vm.resetFilters},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('s-field',{attrs:{"label":"Categories"}},[_c('v-select',{attrs:{"label":"name","placeholder":"Select","options":_vm.categories,"reduce":function (category) { return category.id; },"value":_vm.filters.category_id},on:{"input":function($event){_vm.resetFilters();
            _vm.setCategoryId($event);}}})],1),(_vm.isBrandListVisible)?_c('s-field',{staticClass:"mt-2",attrs:{"label":"Brands"}},[(_vm.brands)?_c('BrandSelect',{attrs:{"value":_vm.filters.brand_id},on:{"input":_vm.setBrandFilter}}):_vm._e()],1):_vm._e()]},proxy:true}])}),_c('sp-list',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreData),expression:"loadMoreData"}],ref:"list",attrs:{"pagination-mode":"infinite","attrs":_vm.columns,"filters":_vm.filters,"endpoint":"products","perPage":_vm.$options.params.limit,"params":_vm.$options.params,"actions":['search', 'filters', 'refresh']},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var refresh = ref.refresh;
return [_c('div'),_c('div',{staticClass:"filter-section"},[_c('SearchFilter',{attrs:{"value":_vm.filters.search},on:{"search":function($event){_vm.filters.search = $event}}}),_c('s-button-group',[_c('s-button',{staticClass:"filter",attrs:{"icon":"vmdi-filter","shape":"circle"},nativeOn:{"click":function($event){return _vm.$root.$emit('filter-bar-open')}}}),_c('s-button',{staticClass:"refresh",attrs:{"icon":"vmdi-refresh","shape":"circle"},nativeOn:{"click":function($event){return refresh()}}})],1)],1)]}},{key:"empty",fn:function(){return [_c('list-empty',{attrs:{"title":"No Products Found"}})]},proxy:true}])},[_c('sp-list-table',{on:{"rowClick":_vm.goToItem},scopedSlots:_vm._u([{key:"price",fn:function(ref){
            var item = ref.item;
return [_c('product-price',{attrs:{"price":item.price,"unit":item.unit}})]}},{key:"brand_logo",fn:function(ref){
            var item = ref.item;
return [(item.brand && item.brand.logo)?_c('img',{staticClass:"w-10 h-10",attrs:{"src":item.brand.logo.url}}):_vm._e()]}},{key:"status",fn:function(ref){
            var item = ref.item;
return [_c('Tag',{attrs:{"label":item.status,"color":item.status == 'active' ? 'green' : 'red'}})]}},{key:"actions",fn:function(ref){
            var item = ref.item;
return [(_vm.isProductDeleteGranted)?_c('s-button',{attrs:{"icon":"IconMdiTrashCanOutline","shape":"circle","color":"danger","theme":"muted"},nativeOn:{"click":function($event){return _vm.deleteProduct(item.id)}}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }