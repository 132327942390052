export default [
  {
    path: "users",
    component: require("@/views/private/master/users/Index.vue").default,
    redirect: { name: "user-list" },
    children: [
      {
        path: "list",
        name: "user-list",
        component: require("@/views/private/master/users/List.vue").default,
        meta: {
          breadcrumbs: ["dashboard", "user-list"],
          label: "Users",
        },
      },
      {
        path: ":id",
        props: true,
        name: "user-item",
        component: require("@/views/private/master/users/Item.vue").default,
        meta: {
          breadcrumbs: ["dashboard", "user-list", "user-item"],
          label: "Create New",
        },
      },
    ],
  },
];
