<template>
  <router-view :filter="filter" :columns="columns" :param="params" end-point="vendor/notifications" :type-options="$options.Options.vendor" module="vendor"></router-view>
</template>

<script>
import { getReadClassList } from "@/utilities/common";
import { formatDate } from "@/utilities/common";
import Options from "@/data/notificationTypeOptions";
export default {
  Options,
  created() {
    // console.log("inside build inquiry log", window.Echo);
    // window.Echo.private("build-inquiry").listen("inquiry", (e) => {
    //   this.messages.push({
    //     message: e.message.message,
    //     user: e.user,
    //   });
    // });
  },
  data() {
    return {
      filter: {
        notifiable_type: null,
        search: null,
        date_range: null,
        read_at: null,
      },
      params: {
        limit: 25,
        read_at: null,
        include: "user,vendorOrder",
      },
      columns: [
        {
          name: "title",
          classList(row) {
            return getReadClassList(row.read_at);
          },
        },
        {
          name: "description",
          label: "Description",
          classList(row) {
            return getReadClassList(row.read_at);
          },
        },
        // "description",
        {
          name: "Vendor Name",
          label: "Vendor Name",
          value: (item) => (item.vendorOrder && item.vendorOrder.user ? item.vendorOrder.user.name : ""),
          classList(row) {
            return getReadClassList(row.read_at);
          },
        },
        {
          name: "order_id",
          label: "Order ID",
          value: (item) => item.notification_detail.vendor_order_no,
          classList(row) {
            return getReadClassList(row.read_at);
          },
        },
        {
          name: "created_at",
          label: "Date & Time",
          value: (item) => formatDate(item.created_at),
          sortable: true,
          classList(row) {
            return getReadClassList(row.read_at);
          },
        },
      ],
    };
  },
  methods: {
    getReadClassList,
  },
};
</script>

<style lang="scss" scoped></style>
