// Plugins index file
import Vue from "vue";
import "./vee-validate";
import PortalVue from "portal-vue";
// Shilp Vue Plugin
import "./shilp-vue";
/**
 * Portal
 */
Vue.use(PortalVue);

// Vue infinite Scroll
// register globally
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

/*
 * Vue Select
 */

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

// ToolTip
import vTooltip from "v-tooltip";
Vue.use(vTooltip, {
  defaultClass: "custom-tooltip-class",
  defaultBoundariesElement: document.body,
});

// Vue File Agent
import "./vue-file-agent.js";

// Vue ApexChart
import "./apexchart";

// Vue Echo Laravel Push Notifications
import "./laravel-echo";
import "./vue-toastification";

// GraphQl
import "./graphql";
