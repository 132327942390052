<template>
  <VueFileAgentWrapper
    endpoint="medias"
    :baseUrl="$options.baseUrl"
    v-shilp-loader.overlay.dark="isDeleting"
    theme="list"
    :linkable="true"
    :value="value.invoice_media"
    :readonly="true"
    :multiple="true"
    :isDeletable="true"
    @deleted="deleteInvoiceItem"
  />
</template>

<script>
import { deleteInvoice } from "@/api/invoice";
import { alertApiError } from "@/utilities/common";
export default {
  baseUrl: process.env.VUE_APP_STORE_REST_API,
  props: {
    value: Object,
  },
  data() {
    return {
      isDeleting: false,
    };
  },
  components: {
    VueFileAgentWrapper: require("@/components/VueFileAgentWrapper").default,
  },
  methods: {
    deleteInvoiceItem() {
      this.$emit("refresh");

      if (this.value && this.value.invoice_media.length <= 1) {
        this.isDeleting = true;
        deleteInvoice(this.value.id)
          .then(() => {})
          .catch((error) => {
            alertApiError(error);
            console.error(error);
          })
          .finally(() => (this.isDeleting = false));
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
