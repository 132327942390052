export default {
  pending: {
    label: "Pending",
    value: "pending",
  },
  approve: {
    label: "Approved",
    value: "approve",
  },
  reject: {
    label: "Rejected",
    value: "reject",
  },
  incomplete: {
    label: "Incomplete",
    value: "incomplete",
  },
  inreview: {
    label: "In Review",
    value: "inreview",
  },
};
