import Vue from "vue";
import Vuex from "vuex";
import hydrate from "./modules/hydrate";
import root from "./root";
import product from "./modules/product";
import { cloneDeep } from "lodash-es";
import order from "./modules/order";
// import VuexPersistence from "vuex-persist";
// import storeApp from "./modules/storeApp";
// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
//   modules: ["storeApp"],
// });

Vue.use(Vuex);

const store = new Vuex.Store({
  ...root,
  modules: {
    hydrate,
    product,
    order,
    // storeApp,
  },
  // plugins: [vuexLocal.plugin],
});

const initialState = cloneDeep(store.state);

//Resets the store when user logs out
const resetStore = () => {
  store.replaceState(cloneDeep(initialState));
};

export default store;
export { resetStore };
