<template>
  <VueFileAgent
    ref="fileAgent"
    :key="fileAgentKey"
    :multiple="multiple"
    :deletable="isDeletable"
    :meta="true"
    :helpText="description"
    maxSize="5MB"
    :maxFiles="4"
    :value="modifiedValue"
    @beforedelete="onBeforeDelete($event)"
    @delete="onDelete($event)"
    :accept="accept"
    @select="filesSelected($event)"
    :linkable="linkable"
    :theme="theme"
    :thumbnailSize="thumbnailSize"
    :disabled="isDisabled"
    @upload="onUpload($event)"
    :readonly="readonly"
    v-shilp-loader.overlay="isUploading"
  ></VueFileAgent>
</template>

<script>
import $axios from "@/plugins/axios";
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    thumbnailSize: {
      type: Number,
    },
    theme: {
      type: String,
      default: "grid",
    },
    fileAgentKey: {
      type: Number,
      default: 1,
    },
    isDeletable: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    linkable: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
    },
    value: {
      type: [Array, Object, File],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: ".jpg,.png,.jpeg,application/pdf",
    },
    description: {
      type: String,
      default: "Choose an image or pdf document.",
    },
    endpoint: {
      type: String,
    },
    baseUrl: {
      type: String,
    },
  },
  created() {
    if (this.value) {
      if (Array.isArray(this.value) && this.value.length) {
        // If there are multiple files, then array will be passed
        this.modifiedValue = this.value.map((item) => {
          return this.getMetaData(item);
        });
      } else if (typeof this.value == "object" && Object.keys(this.value).length) {
        // this else block is checking whether value contains object and is not empty , since empty object or array is getting processed further and causing errors.
        this.modifiedValue = this.getMetaData(this.value);
      }
    }
  },
  data() {
    return {
      // localValue: null,
      modifiedValue: null,
      isUploading: false,
    };
  },
  computed: {
    token() {
      return localStorage.getItem("bit-admin-token");
    },
    computedBaseUrl() {
      if (this.baseUrl) return this.baseUrl;
      else return process.env.VUE_APP_API;
    },
    uploadHeaders() {
      return {
        Authorization: `Bearer ${this.token}`,
      };
    },
  },
  methods: {
    getMetaData(file) {
      if (file) {
        return {
          // GraphQL and REST both have different nameing conventions, therefore using logical OR
          // REFACTOR needed from backend to have same set of keys
          type: file.mimeType || file.mime_type,
          ext: file.extension,
          // Modified filename with extension to solve issue of File priview not displaying when file name has does not have any extension.
          name: this.getFileNameWithExtenstion(file.fileName || file.filename, file.extension),
          ...file,
        };
      }
    },
    getFileNameWithExtenstion(name, extension) {
      return name + "." + extension;
    },
    onBeforeDelete(fileRecord) {
      if (confirm("Are you sure?")) {
        this.$refs.fileAgent.deleteFileRecord(fileRecord);
      }
    },
    onDelete(fileRecord) {
      // fileRecord contains Object with Response in upload key and file data
      if (this.endpoint) {
        let id = null;
        if (fileRecord.upload && fileRecord.upload.data) id = fileRecord.upload.data[0].id;
        else if (fileRecord.id) id = fileRecord.id;

        if (id)
          $axios.delete(`${this.computedBaseUrl}/${this.endpoint}/${id}`).then(() => {
            if (this.multiple) {
              this.$emit("input", this.$refs.fileAgent.fileRecords);
            }
            // Manually setting null for delete
            else {
              this.$emit("input", null);
            }

            this.$emit("deleted");
          });
      }
    },
    filesSelected(files) {
      // arg files contains array of file objects that are selected.
      let updatedFiles = this.$refs.fileAgent.fileRecords;
      this.$emit("input", updatedFiles);

      this.$emit("filesSelected", updatedFiles);

      if (this.endpoint) {
        this.isUploading = true;
        this.$emit("uploading", this.isUploading);
        this.$refs.fileAgent
          .upload(`${this.computedBaseUrl}/${this.endpoint}`, this.uploadHeaders, files, this.createFormData)
          .then()
          .catch(() => {})
          .finally(() => {
            this.isUploading = false;
            this.$emit("uploading", this.isUploading);
            this.$emit("uploadComplete", this.isUploading);
          });
      }
    },

    isValidType(file) {
      if (file.error && file.error.type) return false;
      else return true;
    },

    createFormData(data) {
      let formData = new FormData();
      // formData.append("used_for", "inquiry");
      if (this.params) {
        Object.entries(this.params).forEach((item) => {
          formData.append(item[0], item[1]);
        });
      }

      // Appending files array in formdata like this.
      formData.append("files[]", data.file);
      return formData;
    },
    onUpload() {},
  },
};
</script>

<style lang="scss">
.vue-file-agent .file-preview .file-delete {
  font-size: 24px !important;
}
</style>
