<template>
  <div class="card-wrap grid-wrapper">
    <div class="details-wrapper">
      <p>Name</p>
      <p>{{ name }}</p>
    </div>

    <div class="details-wrapper">
      <p>Role</p>
      <p>{{ role }}</p>
    </div>

    <div class="details-wrapper">
      <p>Mobile Number</p>
      <p>{{ mobileNo }}</p>
    </div>

    <div class="details-wrapper" v-if="description">
      <p>Description</p>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "mobileNo", "description", "role"],
};
</script>

<style lang="scss" scoped>
.grid-wrapper {
  @apply grid grid-cols-1 md:grid-cols-3 gap-4 bg-white p-5 rounded-xl;
}
.details-wrapper {
  @apply border-2 py-3 px-4 rounded-md;

  p {
    @apply mb-2 font-semibold text-gray-500;
  }
}
</style>
