<template>
  <div class="card-wrap p-2" v-shilp-loader.overlay="isLoading">
    <h4 class="text-primary-500 font-bold">Order Sales</h4>

    <ApexChart type="bar" height="350" width="100%" :options="chartOptions" :series="series"></ApexChart>
  </div>
</template>

<script>
import VueApexChart from "vue-apexcharts";
import { dashboardChart } from "@/api/order";
import { formatPrice } from "@/utilities/common";
export default {
  formatPrice,
  props: ["dateRange"],
  components: {
    ApexChart: VueApexChart,
  },
  data() {
    return {
      isLoading: true,
      basePrice: [],
      gstTotal: [],
      month: [],
      total: [],
    };
  },
  computed: {
    series() {
      return [
        {
          name: "Base Price",
          data: this.basePrice,
        },
        {
          name: "GST",
          data: this.gstTotal,
        },
      ];
    },
    chartOptions() {
      return {
        yaxis: {
          title: {
            text: "Amount",
          },

          labels: {
            formatter: function (value) {
              return formatPrice(value, "compact");
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return formatPrice(value);
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#fff"],
          },
          formatter: function (value) {
            return formatPrice(value, "compact");
          },
        },
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            dataLabels: {
              hideOverflowingLabels: true,
              orientation: "vertical",
            },
          },
        },
        xaxis: {
          title: {
            text: "Months",
          },
          categories: this.month,
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      };
    },

    params() {
      return {
        filter: {
          date_range: this.dateRange,
        },
      };
    },
  },
  created() {
    this.getChartData();
  },
  destroy() {},
  watch: {
    dateRange() {
      this.getChartData();
    },
  },
  methods: {
    getChartData() {
      this.isLoading = true;
      dashboardChart(this.params)
        .then(({ base_price, gst_total, month, total }) => {
          this.basePrice = base_price;
          this.gstTotal = gst_total;
          this.month = month;
          this.total = total;
        })
        .catch((error) => {
          console.error("error", error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
