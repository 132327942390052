<template>
  <div class="p-4 card-wrap w-3/5" ref="item">
    <div v-if="!isLoading">
      <div>
        <p class="text-2xl font-medium mb-2">Bid Slot Setting</p>
        <s-field-validate class="w-5/12 mr-2 field--required" label="Bid Slot Time" rules="required" name="Category" after="hr">
          <STextbox type="text" v-model.number="bidTime" />
        </s-field-validate>
        <p class="mt-2 font-semibold"><span class="text-orange-500 italic mr-1">Note:</span><i class="text-gray-500 font-normal">This time will apply from the first bid.</i></p>
      </div>

      <div class="mt-4">
        <p class="text-2xl font-medium mb-2">Bid reminder time (hr)</p>
        <div class="flex flex--fit">
          <s-field-validate class="w-3/12 mr-2 field--required" label="Cement" rules="required" name="Cement">
            <STextbox type="text" v-model.number="cementTime" />
          </s-field-validate>
          <s-field-validate class="w-3/12 mr-2 field--required" label="AAC" rules="required" name="AAC">
            <STextbox type="text" v-model.number="aacTime" />
          </s-field-validate>
          <s-field-validate class="w-3/12 mr-2 field--required" label="Steel" rules="required" name="Steel">
            <STextbox type="text" v-model.number="steelTime" />
          </s-field-validate>
        </div>
      </div>

      <div class="mt-4">
        <p class="text-2xl font-medium mb-2">Service GST</p>
        <div class="flex flex--fit">
          <s-field-validate class="w-3/12 mr-2 field--required" rules="required" name="Service GST" label="Service GST" after="%">
            <STextbox type="text" v-model.number="service_gst_percentage" />
          </s-field-validate>
        </div>
      </div>

      <div class="mt-4">
        <p class="text-2xl font-medium mb-2">Payment Term</p>
        <div class="flex flex--fit">
          <s-field-validate class="w-3/12 mr-2 field--required" rules="required" name="payment_term" label="Payment Term" after="day(s)">
            <STextbox type="text" v-model.number="vendor_payment_term_day" />
          </s-field-validate>
        </div>
      </div>

      <s-button type="submit" label="Update" color="primary" class="button-sm mt-4 w-full" :loader="saving" :disabled="!bidTime || !steelTime || !aacTime || !cementTime" @click.native="updateTime" />
    </div>

    <Loader class="flex" v-else />
  </div>
</template>
<script>
import { getSlotSettings, updateSlotSettings } from "@/api/vendor/bid-slot-setting.js";
import Loader from "@/components/Loader.vue";
export default {
  data() {
    return {
      bidTime: null,
      saving: false,
      isLoading: false,
      steelTime: null,
      aacTime: null,
      cementTime: null,
      service_gst_percentage: null,
      vendor_payment_term_day: null,
    };
  },

  mounted() {
    this.getSots();
  },

  components: {
    Loader,
  },

  methods: {
    getSots() {
      this.isLoading = true;
      getSlotSettings()
        .then(({ data }) => {
          if (data && data.length > 0) {
            this.bidTime = data[0].time;
            this.cementTime = data[1].time;
            this.aacTime = data[2].time;
            this.steelTime = data[3].time;
            this.service_gst_percentage = data[4].service_gst_percentage;
            this.vendor_payment_term_day = data[5].payment_term_days;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$shilp.notify({
            title: err.message,
            type: "danger",
          });
        });
    },
    updateTime() {
      this.saving = true;
      const postData = [
        {
          type: "bid_time",
          time: this.bidTime,
        },
        {
          type: "bid_reminder_time",
          time: this.cementTime,
          category: "cement",
        },
        {
          type: "bid_reminder_time",
          time: this.aacTime,
          category: "aac_blocks",
        },
        {
          type: "bid_reminder_time",
          time: this.steelTime,
          category: "tmt",
        },
        {
          type: "service_gst_percentage",
          service_gst_percentage: this.service_gst_percentage,
        },
        {
          type: "vendor_payment_term_day",
          payment_term_days: this.vendor_payment_term_day,
        },
      ];
      updateSlotSettings({ setting: postData })
        .then(() => {
          this.$shilp.notify({
            title: "Settings updated successfully.",
            type: "success",
          });
          this.saving = false;
        })
        .catch((err) => {
          this.saving = false;
          this.$shilp.notify({
            title: err.message,
            type: "danger",
          });
        });
    },
  },
};
</script>