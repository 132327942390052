<template>
  <div>
    <!-- Companies -->
    <s-field label="Company">
      <v-select
        :loading="isLoading"
        placeholder="Select"
        :clearable="clearable"
        :options="companyOptions"
        @option:selected="onOptionSelected"
        label="name"
        :value="companyFilterId"
        @input="setCompanyFilterId($event)"
        :reduce="(item) => item.id"
      >
        <template #spinner="{ loading }">
          <div v-shilp-loader.overlay="loading"></div>
        </template>

        <template #footer v-if="address">
          <p class="text-grey font-medium text-sm line-clamp-2 mt-2" v-tooltip="address">
            {{ address }}
          </p>
        </template>
      </v-select>
    </s-field>
  </div>
</template>

<script>
import { getAllCompanies } from "@/api/company";
import { mapMutations, mapGetters } from "vuex";

export default {
  props: {
    kycStatus: String,
    clearable: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["companyFilterId"]),
  },
  data() {
    return {
      companyOptions: [],
      isLoading: false,
      params: {
        limit: -1,
        include: "projects",
      },
      address: null,
    };
  },

  watch: {
    companyFilterId(newValue) {
      if (!newValue) {
        this.address = null;
        this.$emit("cleared");
      }
    },
  },

  created() {
    if (this.companyFilterId) {
      this.$emit("selected", this.companyFilterId);
    } else {
      // this router push solves issue #27 Company state not getting updated when page refreshes in company dropdown
      // this.$router.push({ name: "dashboard" });
    }

    this.getAllCompanyOptions();
  },
  methods: {
    ...mapMutations(["setCompanyFilterId"]),
    setAddressFromOptions() {
      const company = this.companyOptions.find((company) => {
        return company.id == this.companyFilterId;
      });

      if (company) this.address = company.billing_address;
    },
    onOptionSelected($event) {
      this.address = $event.billing_address;

      this.$emit("selected", this.companyFilterId);
    },
    async getAllCompanyOptions() {
      this.isLoading = true;

      if (this.kycStatus)
        this.params["filter"] = {
          //TODO Ask backend to provide incompleteorPending filter directly instead of joining two status in array
          kyc_status: this.kycStatus == "incompleteOrPending" ? ["pending", "incomplete"] : this.kycStatus,
        };
      let companies = await getAllCompanies(this.params);
      this.companyOptions = companies.data;
      if (this.companyFilterId) this.setAddressFromOptions();

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
