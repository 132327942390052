import $axios from "@/plugins/axios";

export const create = (payload, params = null) => {
  return $axios.post(`users`, payload, { params }).then((res) => res.data);
};

export const update = (id, payload, params = null) => {
  return $axios.put(`users/${id}`, payload, { params }).then((res) => res.data);
};

export const getAll = (params = { limit : -1 }) => {
  return $axios.get("users", { params }).then((res) => res.data);
};

export const get = (id, params = null) => {
  return $axios.get(`users/${id}`, { params }).then((res) => res.data);
};

export const remove = (id, params = null) => {
  return $axios.delete(`users/${id}`, { params }).then((res) => res.data);
};
