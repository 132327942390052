<template>
  <!-- <item-form
    :get="get"
    breadcrumb="orderId"
    #default="{ res }"
    breadcrumb-route="order"
    class="h-100"
    ref="request"
    :params="params"
  > -->
  <!-- <s-layout full-height left-size="250px"> -->
  <!-- <template #left> -->

  <div v-if="isLoading">
    <Shimmer />
  </div>
  <div class="grid-layout" v-else>
    <portal to="header-actions">
      <div class="header-actions">
        <s-button v-if="order && order.po_download" size="sm" color="primary" label="Download PO" icon="IconMdiDownload" :loader="isDownloading" @click.native="downloadPdf" />
      </div>
    </portal>
    <div>
      <Card
        :order="order"
        :dateTime="order.created_at"
        :expectedDeliveryDate="order.expected_delivery_date"
        :paymentTerms="order.paymentTerms.description"
        :category="order.products[0].categories[0].name"
        :brand="order.products[0].brand"
        :paymentStatus="order.paymentStatus"
        :id="order.id"
        :orderStatus="order.orderStatus"
        @order-status-changed="get"
        @payment-status-changed="get"
        @discountApplied="get"
        @revertBack="get"
        @premiumApplied="get"
        @delivery-date-updated="get"
        @transport-charges-updated="get"
        :receiveAmount="order.receive_amount"
      />

      <OrderItems :order="order" @order-product-status-changed="get" @vendor-price-updated="get" />
    </div>

    <div>
      <AvailableCredit :value="order.company.available_credit" />
      <DeliveryAddressCard
        :company="order.company"
        class="detail-card"
        :delivery-incharge="order.project.deliveryIncharge"
        :address="order.project ? order.project.deliveryAddress : []"
        :project-name="order.project.name"
        style="margin-top: 20px"
      />
      <AdditionalInstructionsCard v-if="order.additional_instruction" :instructions="order.additional_instruction" class="detail-card" />

      <InvoiceUpload :value="order.invoices" @invoiceSubmitted="get" :orderId="order.id" :order_no="order.order_no" :isDisabled="isInvoiceUploadAllowed ? false : true" />

      <template v-if="order.invoices && order.invoices.length">
        <s-field label="Invoice(s) List" class="card-wrap mt-4">
          <div>
            <InvoiceDisplay :value="invoiceItem" :key="`invoice-display-${invoiceItem.id}`" v-for="invoiceItem in order.invoices" @refresh="get()" />
          </div>
        </s-field>
      </template>
    </div>

    <!-- <ProductStatusModal @order-product-status-changed="get" /> -->

    <!-- <status-modal @status-changed="get" /> -->
  </div>
  <!-- </template> -->

  <!-- <template #default> -->
  <!-- </template> -->
  <!-- </s-layout> -->
</template>

<script>
import { detail, downloadOrderPDF } from "@/api/order";
// import OrderStatusTag from "@/components/OrderStatusTag.vue";
// import PaymentStatusTag from "@/components/PaymentStatusTag.vue";
import orderStatusData from "@/data/orderStatus";

export default {
  orderStatusData,
  params: {
    include:
      "invoice,company,products,products.brand,products.categories,products.attributeOptions.attribute,paymentTerms,project.deliveryIncharge, project,orderStatus,paymentStatus,orderProducts.orderProductStatus,orderProducts.orderProductPaymentStatus, orderProducts.product.attributeOptions.attribute",
    read_status: 1,
  },
  props: {
    id: [Number, String],
  },
  components: {
    // SubNav: require("@/components/SubNav").default,
    // OrderStatusTag,
    // PaymentStatusTag,
    Shimmer: require("@/components/Shimmer.vue").default,
    OrderItems: require("@/components/order/Items.vue").default,
    AdditionalInstructionsCard: require("@/components/order/details/AdditionalInstructionsCard").default,
    DeliveryAddressCard: require("@/components/order/details/DeliveryAddressCard").default,
    // ProductStatusModal: require("./items/ProductStatusModal.vue").default,
    Card: require("@/components/order/Card.vue").default,
    // StatusModal: require("./StatusModal.vue").default,
    AvailableCredit: require("@/components/AvailableCredit").default,
    InvoiceUpload: require("@/components/order/InvoiceUpload.vue").default,
    InvoiceDisplay: require("@/components/invoice/Display.vue").default,
  },

  data() {
    return {
      isLoading: false,
      isDownloading: false,
      order: null,
      fields: ["id", "orderStatus", "paymentStatus"],
    };
  },
  computed: {
    isInvoiceUploadAllowed() {
      if (this.order.orderStatus.id == this.$options.orderStatusData.cancelled) return false;
      else return true;
    },
  },
  watch: {
    id() {
      this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, `Order No : ${this.id}`);
      this.get();
    },
  },
  created() {
    if (this.id) {
      this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, `Order No : ${this.id}`);
      this.get();
    }
  },
  methods: {
    downloadPdf() {
      this.isDownloading = true;
      downloadOrderPDF(this.id)
        .then((res) => {
          window.open(res.url, "_blank");
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    async get() {
      try {
        this.isLoading = true;
        const res = await detail(this.id, this.$options.params);
        this.order = res;
        return res;
      } catch {
        this.$shilp.notify({
          title: "Api Request Failed. Try Again after some time.",
          type: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.field-group {
  ::v-deep {
    .field__label {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
}

.detail-card {
  @apply my-8 p-5 bg-white;
}

.card-wrapper {
  @apply p-4;
}

.grid-layout {
  @apply grid gap-4 lg:gap-8;
  grid-template-columns: 1fr 400px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
</style>
