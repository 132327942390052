<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
import { mapMutations } from "vuex";
export default {
  beforeDestroy() {
    this.resetFilters();
  },
  methods: {
    ...mapMutations({
      resetFilters: "order/resetFilters",
    }),
  },
};
</script>

<style></style>
