<template>
  <s-field class="filter-search">
    <s-textbox @input="handleSearch" placeholder="Search" :value="value" />
  </s-field>
</template>

<script>
import { debounce } from "lodash-es";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleSearch: debounce(function (value) {
      this.$emit("search", value);
    }, 1000),
  },
};
</script>

<style lang="scss" scoped></style>
