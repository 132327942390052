<template>
  <div>
    <item-form :fields="fields" :redirect="false" :notify="false" :form-state.sync="formState" ref="item" :item="id" class="p-4 card-wrap" width="400px" :get="getItem" :save="saveItem">
      <template #default="{ form }">
        <s-field-validate rules="required" name="name" label="Name">
          <s-textbox v-model="form.name" type="text" placeholder="Enter name" />
        </s-field-validate>

        <s-field-validate rules="required" name="Brand" label="Brand">
          <v-select :options="brandOptions" placeholder="Select" :reduce="(brand) => brand.value" v-model="form.brand_id" />
        </s-field-validate>

        <s-field-validate name="Files" label="Files">
          <VueFileAgentWrapper
            :baseUrl="$options.baseUrl"
            :key="fileAgentKey"
            v-model="form.brochureFiles"
            endpoint="medias"
            :linkable="true"
            :params="{ used_for: 'brochure' }"
            :multiple="true"
            :isDeletable="true"
          />
        </s-field-validate>
      </template>
    </item-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { create, get, update } from "@/api/brochure";
import fileUploadMixin from "@/mixins/fileUpload";

export default {
  baseUrl: process.env.VUE_APP_STORE_REST_API,
  data() {
    return {
      fileAgentKey: 1,
      // fileRecords: [],
      formState: null,
    };
  },
  components: {
    VueFileAgentWrapper: require("@/components/VueFileAgentWrapper.vue").default,
  },
  props: ["id"],
  computed: {
    ...mapGetters(["brandOptions"]),
    fields() {
      return ["name", "brand_id", "brochureFiles"];
    },
  },
  mixins: [fileUploadMixin],
  methods: {
    checkIfAttachmentIsUploadedAndGetId(form) {
      if (form.brochureFiles.length) {
        let imageIds = [];
        form.brochureFiles.forEach((item) => {
          if (item.upload && item.upload.data && item.upload.data[0].id) {
            imageIds.push(item.upload.data[0].id);
          } else if (item.raw && item.raw.id) {
            imageIds.push(item.raw.id);
          } else imageIds.push(item.id);
          // });
        });
        return imageIds;
      }

      return false;
    },
    resetFileAgent() {
      // using key to refresh fue file agent let vue update in DOM.
      this.fileAgentKey++;
    },
    getItem() {
      return get(this.id).then((res) => {
        this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, res.name);
        return res;
      });
    },
    async saveItem(id, form) {
      if (this.checkFilePresent(form)) {
        const isValid = this.isMediaValid(form.brochureFiles);

        if (isValid) {
          const mediaIdArray = this.checkIfAttachmentIsUploadedAndGetId(form);
          if (mediaIdArray) form.media_id = mediaIdArray;
          let res;
          if (id) {
            res = await update(id, form);
            this.resetFileAgent();
          } else {
            res = await create(form);
          }

          this.$router.push({ name: "brochure-list" });
          return res;
        }
      }
    },

    checkFilePresent(form) {
      if (form.brochureFiles && form.brochureFiles.length) return true;
      else {
        this.$shilp.notify({
          title: "Brochure Field is Required",
          type: "danger",
        });

        return false;
      }
    },
  },
};
</script>

<style></style>
