<template>
  <span class="text-md">
    {{ formatPrice(price) }} <span v-if="unit"> /{{ unit }} </span></span
  >
</template>

<script>
import { formatPrice } from "@/utilities/common";
export default {
  props: {
    price: {
      type: [String, Number],
      required: true,
    },
    unit: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    formatPrice,
  },
};
</script>

<style></style>
