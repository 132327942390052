import $axios from "@/plugins/axios";

export const changeStatus = (id, status) => {
  return $axios.put(`companies/${id}/status`, { status }).then((res) => {
    return res.data;
  });
};

export const get = (id, params = null) => {
  return $axios
    .get(`companies/${id}`, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

export const changeKycStatus = (id, status, reason = null) => {
  return $axios.put(`companies/${id}/kycstatus`, { kyc_status: status, reason: reason }).then((res) => {
    return res.data;
  });
};

export const changeCredit = (id, credit) => {
  return $axios.put(`companies/${id}/credit`, { credit }).then((res) => {
    return res.data;
  });
};

export const getAllCompanies = (params = null) => {
  return $axios.get("companies", { params }).then((res) => {
    return res.data;
  });
};

export const getKycApproveCount = (params = null) => {
  return $axios.get("companies/count/kycapprove", { params }).then((res) => {
    return res.data;
  });
};

export const getCount = (params = null) => {
  return $axios.get("companies/kycStatus/count", { params }).then((res) => {
    return res.data;
  });
};

export const getChartData = (id, params = null) => {
  return $axios.get(`companies/${id}/orderChart`, { params }).then((res) => {
    return res.data;
  });
};

export const teamAssign = (companyId, data, params = null) => {
  return $axios.put(`companies/${companyId}/userAssign`, data, { params }).then((res) => res);
};

export const downloadProfile = (id, params = null) => {
  return $axios.get(`companies/${id}/profile/generate`, { params }).then((res) => {
    return res.data;
  });
};

export const getCompanyTypeOptions = (params = null) => {
  return $axios.get("masterCompanyTypes", { params }).then((res) => {
    return res.data;
  });
};

export const getActiveCompanyCount = (params = { filter: { active_company: 60 } }) => {
  return $axios.get("companies/active/count", { params }).then((res) => res.data);
};

export const getCompanyFromGstNo = (gst, params = {}) => {
  return $axios.get("companyFromGstNo", { params: { gst_no: gst, ...params } }).then((res) => res.data);
};

export const createCompany = (data, params = {}) => {
  return $axios.post("companies", data, { params }).then((res) => res.data);
};

export const editCompany = (id, data, params = {}) => {
  return $axios.put(`companies/${id}`, data, { params }).then((res) => res.data);
};
