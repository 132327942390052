<template>
  <div>
    <div class="relative z-10 bg-white py-3 px-4 mb-4 rounded-md flex flex--fit gap-8 flex--bottom">
      <CompanyFilter class="company-filter" :clearable="true" @cleared="filters.company_id = null" @selected="setCompanyFilter" />
    </div>
    <SpList
      endpoint="projects"
      :filters="filters"
      pagination-mode="infinite"
      ref="list"
      :perPage="$options.params.limit"
      :params="$options.params"
      :attrs="$options.columns"
      v-infinite-scroll="loadMoreData"
      :actions="['settings', 'refresh']"
    >
      <template #empty>
        <list-empty />
      </template>
      <!-- <template #filters>
        <div class="text-center">
          <s-field label="Date">
            <date-picker @v-model="filters.date_range" range placeholder="Select" valueType="DD-MM-YYYY" format="DD-MM-YYYY" />
          </s-field>
          <s-button class="mt-4" label="Clear" icon="IconMdiFilterRemoveOutline" color="danger" theme="muted" @click.native="resetFilters" />
        </div>
      </template> -->

      <template #header="{ refresh }">
        <div></div>
        <div class="filter-section">
          <SearchFilter @search="filters.search = $event" />
          <s-button class="refresh" icon="vmdi-refresh" shape="circle" @click.native="refresh()" />
        </div>
      </template>
      <SpListTable>
        <template #incharge="{ item }">
          <v-popover trigger="hover" placement="left" popoverWrapperClass="min-w-max" v-if="item.incharge && item.incharge.length">
            <template #popover>
              <InchargeList :data="item.incharge" :key="`incharge-table-${item.id}`" />
            </template>
            <s-button icon="IconMdiInformationOutline" theme="link" color="secondary" />
          </v-popover>
          <span v-else></span>
        </template>

        <template #deliveryAddress="{ item }">
          <p class="line-clamp-2" v-tooltip="item.deliveryAddress">{{ item.deliveryAddress }}</p>
        </template>
      </SpListTable>
    </SpList>
  </div>
</template>

<script>
import { formatDate } from "@/utilities/common";
import { mapGetters } from "vuex";
import tableMixin from "@/mixins/table";
export default {
  columns: [
    { name: "name", label: "Name" },
    { name: "updated_at", label: "Last Updated At", value: (item) => formatDate(item.updated_at), width: "200px", sortable: true },
    { name: "company_name", label: "Company Name", value: (item) => item.company && item.company.name },
    { name: "deliveryAddress", label: "Delivery Address", width: "400px" },
    { name: "incharge", label: "Incharge" },
    { name: "no_entry_zone", label: "No Entry Zone", value: (item) => (item.no_entry_zone ? "Yes" : "No") },
    "city",
    "pincode",
  ],
  params: {
    limit: 25,
    include: "company,incharge,deliveryIncharge",
  },

  components: {
    CompanyFilter: require("@/components/company/Filter.vue").default,
    InchargeList: require("@/components/company/projects/InchargeList.vue").default,
    SearchFilter: require("@/components/SearchFilter.vue").default,
  },
  mixins: [tableMixin],
  computed: {
    ...mapGetters({
      companyId: "companyFilterId",
    }),
  },
  data() {
    return {
      filters: {
        search: "",
        company_id: null,
        // date_range: null,
      },
    };
  },

  created() {
    if (this.companyId) {
      this.filters.company_id = this.companyId;
    }
  },

  methods: {
    // resetFilters() {
    //   this.filters = {
    //     company_id: null,
    //     search: null,
    //     date_range: null,
    //   };
    // },
    setCompanyFilter(id) {
      this.filters.company_id = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-section {
  @apply flex items-center gap-4;
}
</style>
