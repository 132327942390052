<template>
  <div class="rounded overflow-hidden bg-white p-2" v-if="!isLoading && orderDetails">
    <p class="font-medium p-2 bg-gray-100 radius-2">Sub Items</p>
    <table class="mt-2 order-detail-table">
      <tr class="border-t border-b">
        <th class="header-label">Grade</th>
        <th>Size/Packaging</th>
        <th class="header-label">Quantity</th>
        <th class="header-label">Price</th>
        <th class="header-label">Total</th>
        <th class="header-label">Buyer Price</th>
        <th class="header-label">Status</th>
        <th class="header-label">Action</th>
      </tr>
      <tr v-for="product in orderDetails.orderProducts" :key="product.id">
        <td>{{ product.grade_name }}</td>
        <td>{{ product.unit_name }}</td>
        <td>{{ product.dispatch_quantity }} {{ product.unit_name }}</td>
        <td>
          <div class="product-total-wrapper">
            <p class="mr-2">{{ formatPrice(product.price) }}</p>
            <v-popover trigger="hover" popoverWrapperClass="min-w-max">
              <template #popover>
                <PriceBreakUp :gstPercentage="product.gst_percentage" :gstAmount="product.gst_amount" :basePrice="product.single_base_price" />
              </template>
              <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
            </v-popover>
          </div>
        </td>
        <td>
          <div class="product-total-wrapper">
            <p class="mr-2">{{ formatPrice(product.total) }}</p>
            <v-popover trigger="hover" popoverWrapperClass="min-w-max">
              <template #popover>
                <PriceBreakUp :gstPercentage="product.gst_percentage" :gstAmount="product.gst_total_amount" :basePrice="product.total - product.gst_total_amount" />
              </template>
              <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
            </v-popover>
          </div>
        </td>
        <td>
          <p class="mr-2">{{ formatPrice(product.buyer_unit_price) }}</p>
        </td>
        <td class="p-2 status">
          <StatusTag :label="product.vendorOrderProductStatus.display_name" :name="product.vendorOrderProductStatus.name" />
        </td>
        <td>
          <a class="bg-blue-100 w-4 h-4 flex justify-center content-center rounded-full cursor-pointer" @click="openModal(product)">
            <s-icon name="IconMdiPencilOutline" class="w-2.5 h-2.5 text-blue-500" />
          </a>
        </td>
      </tr>
      <tr class="order-total">
        <td colspan="4">
          <h4 class="item-label">Base Price</h4>
        </td>
        <td>
          <h4 class="font-bold">{{ formatPrice(orderDetails.total - orderDetails.gst_total_amount) }}</h4>
        </td>
      </tr>

      <tr class="order-total">
        <td colspan="4">
          <h4 class="item-label">GST</h4>
        </td>
        <td>
          <h4 class="font-bold">{{ formatPrice(orderDetails.gst_total_amount) }}</h4>
        </td>
      </tr>

      <tr class="order-total">
        <td colspan="4">
          <h4 class="item-label">Loading</h4>
        </td>
        <td>
          <h4 class="font-bold">{{ formatPrice(orderDetails.loading) }}</h4>
        </td>
      </tr>

      <tr class="order-total">
        <td colspan="4">
          <h4 class="item-label">Unloading</h4>
        </td>
        <td>
          <h4 class="font-bold">{{ formatPrice(orderDetails.unloading) }}</h4>
        </td>
      </tr>

      <tr class="order-total">
        <td colspan="4">
          <h4 class="item-label">Freight</h4>
        </td>
        <td>
          <h4 class="font-bold">{{ formatPrice(orderDetails.freight) }}</h4>
        </td>
      </tr>

      <tr class="order-total">
        <td colspan="4">
          <h4 class="item-label">Service GST</h4>
        </td>
        <td>
          <h4 class="font-bold">{{ formatPrice(orderDetails.loading_gst_price + orderDetails.unloading_gst_price + orderDetails.freight_gst_price) }}</h4>
        </td>
      </tr>

      <tr class="order-total">
        <td colspan="2">
          <h4 class="item-label">Total</h4>
        </td>

        <td colspan="2" class="font-bold">{{ orderDetails.qty }} {{ orderDetails.unit_name }}</td>

        <td>
          <h4 class="font-bold">
            {{
              formatPrice(
                orderDetails.total +
                  orderDetails.loading +
                  orderDetails.unloading +
                  orderDetails.freight +
                  orderDetails.loading_gst_price +
                  orderDetails.unloading_gst_price +
                  orderDetails.freight_gst_price
              )
            }}
          </h4>
        </td>
      </tr>
    </table>
    <BidDetails :details="productDetail" :orderStatusList="orderStatusList" @close="modalClose" />
  </div>
</template>

<script>
import { formatPrice } from "@/utilities/common";
import BidDetails from "./BidDetails.vue";
import { getAll } from "@/api/vendor/order.js";
import StatusTag from "@/components/StatusTag.vue";
import PriceBreakUp from "@/components/order/PriceBreakUp.vue";

export default {
  props: ["orderInfo", "orderStatusList"],
  data() {
    return {
      productDetail: null,
      orderDetails: null,
      isLoading: false,
    };
  },
  components: {
    BidDetails,
    StatusTag,
    PriceBreakUp,
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    formatPrice,
    openModal(product) {
      this.productDetail = product;
      this.$root.$emit("shilp-modal-open", {
        id: "bid-details-modal",
        scope: {
          quantity: product.quantity,
          unit_name: product.unit_name,
          total: product.total,
          status: product.vendorOrderProductStatus.display_name,
          complete_order: product.complete_order,
        },
      });
    },
    modalClose() {
      this.$emit("refresh");
    },
    getProducts() {
      this.isLoading = true;
      const params = {
        include: "orderProducts,orderProducts.orderStatus",
      };
      getAll(params, this.$route.params.id)
        .then((res) => {
          this.orderDetails = res;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-detail-table {
  @apply w-full rounded-lg overflow-auto bg-white text-gray-900 text-base font-normal;
  thead {
    th {
      text-align: left;
    }
  }
  tr {
    @apply border-t border-b;
  }
  tr,
  thead {
    td,
    th {
      padding: 12px;
    }
  }
}
.product-total-wrapper {
  @apply flex gap-2 items-center min-w-max;
}
.order-total {
  @apply border-none;
}
.item-label {
  @apply text-gray-500 font-normal;
}
.status {
  font-size: small;
}
.header-label {
  @apply text-left w-2/12;
}
</style>