<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 18H1V7H3V16H15V7H17V18ZM0 0H18V6H0V0ZM2 2V4H16V2H2ZM7.5 8V11H5L9 15L13 11H10.5V8"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IcOrder",
};
</script>

<style></style>
