<template>
  <span class="text-md"> {{ formatPrice(price) }}</span>
</template>

<script>
import { formatPrice } from "@/utilities/common";
export default {
  props: {
    price: {
      type: [String, Number],
      required: true,
    },
  },
  methods: {
    formatPrice,
  },
};
</script>

<style></style>
