<template>
  <div>
    <div class="flex flex--fit flex--nowrap mb-2" v-if="bidsList && !isOrderListLoading">
      <div class="bg-white p-3 radius-2 w-1/2 mr-2">
        <div class="grid grid--3">
          <div>
            <p class="font-medium text-gray-500">Order ID</p>
            <p class="text-base font-medium text-gray-600">{{ bidsList.order_no }}</p>
          </div>
          <div>
            <p class="font-medium text-gray-500">Category</p>
            <p class="text-base font-medium text-gray-600">{{ bidsList.category.name }}</p>
          </div>
          <div>
            <p class="font-medium text-gray-500">Brand</p>
            <p class="text-base font-medium text-gray-600">{{ bidsList.brand.name }}</p>
          </div>
        </div>

        <div class="grid grid--3 mt-3">
          <div>
            <p class="font-medium text-gray-500">Order Date</p>
            <p class="text-base font-medium text-gray-600">{{ formatDate(bidsList.order.created_at) }}</p>
          </div>
          <div>
            <p class="font-medium text-gray-500">Buyer Total Amount</p>
            <p class="text-base font-medium text-gray-600">{{ formatPrice(bidsList.order.total) }}</p>
          </div>
          <div>
            <p class="font-medium text-gray-500">Delivery By</p>
            <p class="text-base font-medium text-gray-600">{{ formatDate(bidsList.delivery_date) }}</p>
          </div>
        </div>
      </div>
      <div class="bg-white p-3 radius-2 w-1/2">
        <div class="flex flex--fit">
          <div class="w-1/2">
            <p class="font-medium text-gray-500">Buyer Name</p>
            <p class="text-base font-medium text-gray-600">{{ bidsList.buyerName[0] }}</p>
          </div>
          <div class="w-1/2">
            <p class="font-medium text-gray-500">Project Name</p>
            <p class="text-base font-medium text-gray-600">{{ bidsList.project_name }}</p>
          </div>
        </div>

        <div>
          <div class="flex flex--fit">
            <p class="font-medium text-gray-500 mt-2 mr-5">Project Location</p>
            <a class="flex flex--fit border border-gray-200 rounded-full px-1 cursor-pointer" @click="redirectToGoogleMaps">
              <s-icon name="IconGoogleMapMarker" class="mt-1" /> <span class="leading-loose font-medium text-gray-400">See on map</span>
            </a>
          </div>
          <p class="text-base font-medium text-gray-600">{{ bidsList.delivery_address }}</p>
        </div>
      </div>
    </div>
    <div class="bg-white p-2 radius-2">
      <p class="font-medium p-2 bg-gray-100 text-sm radius-2 mb-2">Bids</p>
      <div class="bi-overflow-x-scroll" v-if="bidsList && bidsList.adminVendorBids.length > 0 && !isOrderListLoading">
        <table>
          <thead>
            <tr class="border-t border-b">
              <th class="header-label p-1">Vendor Name</th>
              <th class="header-label">Mobile No</th>
              <th class="header-label">
                <div class="flex flex--fit">
                  <span class="mr-1">Vendor Bid Amount</span>
                  <v-popover trigger="hover" popoverWrapperClass="min-w-max">
                    <template #popover>
                      <h6>This price inclusive of loading, unloading, freight and service GST charge.</h6>
                    </template>
                    <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
                  </v-popover>
                </div>
              </th>
              <th class="header-label pl-3">Delivery Days</th>
              <th class="header-label">Quantity</th>
              <th class="header-label">Bids Status</th>
              <th class="header-label">Contact</th>
              <th class="header-label">Action</th>
              <th class="header-label"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(bids, index) in bidsList.adminVendorBids">
              <tr :key="'orderId' + index" class="border-b bid-table-row" @click="expandRow(index, bids.id, true)">
                <td :class="'pl-1 ' + isBidPriceGreaterThenZero(bids.total)">
                  <div class="flex flex--fit">
                    <s-icon name="IconAdvanceBidFill" class="fill-current text-danger-500 h-3" v-if="bids.is_auto_submit_bid" v-tooltip.bottom="'Advance Bid'" />
                    {{ bids.user && bids.user.name }}
                  </div>
                </td>
                <td :class="isBidPriceGreaterThenZero(bids.total)">{{ bids.user && bids.user.mobile_no }}</td>
                <td :class="isBidPriceGreaterThenZero(bids.total)">
                  {{ bids.total ? formatPrice(bids.total + bids.freight + bids.freight_gst_price + bids.loading + bids.loading_gst_price + bids.unloading + bids.unloading_gst_price) : "NA" }}
                </td>
                <td :class="isBidPriceGreaterThenZero(bids.total)" class="text-center">{{ bids.delivery_days }}</td>
                <td :class="isBidPriceGreaterThenZero(bids.total)">{{ bids.qty + " " + bids.unit_name || "NA" }}</td>
                <td :class="isBidPriceGreaterThenZero(bids.total)"><StatusTag :label="bids.bid_status.name" :name="bids.bid_status.name.toLowerCase()" /></td>
                <td :class="'pr-5 ' + isBidPriceGreaterThenZero(bids.total)" @click.stop>
                  <v-popover trigger="click" class="mt-1" ref="freight">
                    <template #popover>
                      <div class="flex">
                        <s-button color="primary" label="Reminder for Bids" class="button--sm mt-2" @click.native="redirectToWhatsapp(bids, 'remainder')" />
                        <s-button color="primary" label="Others" class="button--sm button--outline mt-2" @click.native="redirectToWhatsapp(bids, 'other')" />
                      </div>
                    </template>
                    <s-button class="h-4 w-4 text-success-dark" icon="IconMdiWhatsapp" shape="circle" />
                  </v-popover>
                </td>
                <td colspan="2" :class="isBidPriceGreaterThenZero(bids.total)">
                  <div class="!flex flex--fit p-2 flex--evenly">
                    <s-button v-if="bids.bid_status_id != 4" label="Edit" class="button--info button--sm button--muted" @click.native="redirectToAssignBid(bids)"></s-button>
                    <s-button v-if="bids.bid_status_id == 4" label="Assigned" class="button--success button--sm" @click.native="redirectToAssignBid(bids)"></s-button>
                    <a v-if="expandedRow != index" :key="'orderId' + index" class="mt-1 down-arrow-animation"><s-icon class="text-2xl" name="IconIcOutlineKeyboardArrowDown"></s-icon></a>
                    <a v-if="expandedRow == index" :key="'orderId' + index" class="mt-1 down-arrow-animation"><s-icon class="text-2xl" name="IconIcOutlineKeyboardArrowUp"></s-icon></a>
                  </div>
                </td>
              </tr>
              <template v-if="expandedRow === index">
                <tr :key="'orderId1' + index" class="border-t border-b bg-blue-100 text-gray-500">
                  <th class="sub-header-label p-2">Grade</th>
                  <th class="sub-header-label">Size/Packing</th>
                  <th class="sub-header-label">QTY</th>
                  <th class="sub-header-label">Buyer Unit Price</th>
                  <th class="sub-header-label">GST</th>
                  <th class="sub-header-label">Vendor Unit Price</th>
                  <th class="sub-header-label" colspan="2">Vendor Total Amount</th>
                  <th></th>
                  <th></th>
                </tr>
                <template v-if="bidsDetailList.length > 0 && !isBidListLoading">
                  <tr v-for="(details, bidIndex) in bidsDetailList" :key="'bidId1_' + bidIndex + index" class="border-b bg-gray-100">
                    <td class="p-3">{{ details.grade_name }}</td>
                    <td>{{ details.unit_name }}</td>
                    <td>{{ details.quantity }}</td>
                    <td>{{ details.buyer_unit_price ? formatPrice(details.buyer_unit_price) : "NA" }}</td>
                    <td>
                      <span v-if="details.bid.is_gst_include" class="text-success-500">Included</span>
                      <span v-else class="text-danger-500">Excluded</span>
                    </td>
                    <td>{{ details.price ? formatPrice(details.price) : "NA" }}</td>
                    <td class="pr-1" colspan="2">{{ details.price ? formatPrice(details.total) : "NA" }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </template>
                <template v-else>
                  <Loader class="flex" v-if="isBidListLoading" :key="'bidId1' + index" />
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </div>
      <div :key="index" class="text-center mt-3 font-medium text-base" v-else-if="bidsList && bidsList.adminVendorBids.length == 0 && !isBidListLoading">No Bids Found!</div>
      <Loader class="flex" v-if="isOrderListLoading" />
    </div>
  </div>
</template>

<script>
import { getAllBids, getBidDetail } from "@/api/vendor/order.js";
import { formatPrice } from "@/utilities/common";
import { formatDate } from "@/utilities/common";
import Loader from "@/components/Loader.vue";
import StatusTag from "@/components/StatusTag.vue";
export default {
  params: {
    limit: 25,
    include: "orderStatus, category, brand",
  },
  data() {
    return {
      bidsList: null,
      isOrderListLoading: false,
      expandedRow: null,
      modalData: null,
      bidsDetailList: [],
      isBidListLoading: false,
    };
  },
  components: {
    Loader,
    StatusTag,
  },
  computed: {},
  created() {},
  mounted() {
    this.get();
  },
  methods: {
    formatPrice,
    formatDate,
    isBidPriceGreaterThenZero(price) {
      return price ? "bg-success-50" : "";
    },
    get() {
      this.isOrderListLoading = true;
      getAllBids(
        {
          limit: -1,
          include: "adminVendorBids,adminVendorBids.bidStatus,order,category,brand,adminVendorBids.user",
          read_status: 1,
        },
        this.$route.params.id
      )
        .then((res) => {
          this.bidsList = res;
          this.isOrderListLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isOrderListLoading = false;
        });
    },

    modalClose() {
      this.get();
    },

    redirectToAssignBid(details) {
      this.$router.push({ name: "vendor-bids-assign", params: { bidId: details.id, id: this.$route.params.id } });
    },

    getBidsDetail(bidId) {
      getBidDetail(
        {
          limit: -1,
          include: "bidDetail.bid.vendorOrder.order.orderProducts",
        },
        bidId
      )
        .then((res) => {
          this.bidsDetailList = res.bid_details;
          this.isBidListLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isBidListLoading = false;
        });
    },

    redirectToGoogleMaps() {
      window.open(`http://maps.google.com?q=${this.bidsList.latitude},${this.bidsList.longitude}`, "_blank");
    },

    expandRow(index, bidId = null, isRowExpanded) {
      this.expandedRow = this.expandedRow === index && isRowExpanded ? null : index;
      if (bidId && isRowExpanded) {
        this.getBidsDetail(bidId);
      }
    },

    redirectToWhatsapp(bids, type) {
      let textMsg = null;
      if (type == "other") {
        textMsg = `Hello ${bids.user.name} your order details: %0A Order Id: ${this.bidsList.order_no},%0A Category: ${this.bidsList.category.name},%0A Brand: ${this.bidsList.brand.name},%0A Quantity: ${this.bidsList.qty} %0A ${process.env.VUE_APP_BASE_URL}/bid-detail/${bids.id}`;
      } else {
        textMsg = `Hello ${bids.user.name} this is a gentle reminder to submit a bid for: %0A Order Id: ${this.bidsList.order_no},%0A Category: ${this.bidsList.category.name},%0A Brand: ${this.bidsList.brand.name},%0A Quantity: ${this.bidsList.qty} %0A ${process.env.VUE_APP_BASE_URL}/bid-detail/${bids.id}`;
      }
      window.open(`https://wa.me/${bids.user.mobile_no}?text=${textMsg}`, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.bid-table-row {
  .down-arrow-animation {
    transition: transform 0.3s ease-in-out;
  }
  &:hover .down-arrow-animation {
    transform: rotate(275deg);
  }
}
.header-label {
  @apply text-left w-[15%] text-gray-600 text-sm font-medium;
}
.sub-header-label {
  @apply text-left font-medium;
}
</style>