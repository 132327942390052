<template>
  <div class="p-4">
    <div class="relative z-10 bg-white py-3 px-4 mb-4 rounded-md flex flex--fit flex--between flex--bottom">
      <div class="buyer-type-section">
        <CompanyFilter class="company-filter" :kycStatus="routeQueryKycStatus" @selected="onCompanyFilterHandler" :clearable="false" />

        <BuyerTypeSelect
          class="w-48"
          :isDisabled="isCompanyUpdateGranted && isBuyerTypeListVisible ? false : true"
          :value="formState.buyerType ? formState.buyerType.id : null"
          v-if="formState"
          :company-id="companyFilterId || id"
          @buyerSelected="$refs.detailsForm.refresh()"
        />
      </div>

      <div class="flex flex--middle gap-2 flex--fit">
        <s-button label="Open Profile as PDF" color="primary" v-tooltip="'Open Profile as PDF'" @click.native="open" :loader="isProfileOpening" />
        <s-button icon="vmdi-refresh" color="primary" @click.native="$refs.detailsForm.refresh()" />
        <template v-if="isCompanyBlockGranted">
          <s-button
            label="Block"
            color="danger"
            v-tooltip="'Block Company'"
            @click.native="
              $root.$emit('shilp-modal-open', {
                id: 'company-block-modal',
                scope: {
                  id: id,
                  name: formState.name,
                },
              })
            "
            v-if="formState && formState.status == 'active'"
          />
        </template>
        <s-button
          label="Unblock"
          color="success"
          v-tooltip="'Unblock Company'"
          @click.native="
            $root.$emit('shilp-modal-open', {
              id: 'company-unblock-modal',
              scope: {
                id: id,
                name: formState.name,
              },
            })
          "
          v-if="formState && formState.status == 'inactive'"
        />
        <s-button
          label="Summary"
          color="primary"
          v-tooltip="'Go to Summary page'"
          @click.native="
            $router.push({
              name: routeQueryKycStatus == 'approve' ? 'approved-companies-statistics' : 'company-statistics',
              params: { id: companyFilterId || id },
              query: { status: routeQueryKycStatus },
            })
          "
        />
      </div>
    </div>

    <item-form :fields="$options.fields" :form-state.sync="formState" ref="detailsForm" :save="approve" :redirect="false" :notify="false" :item="id" :get="getDetails" breadcrumb="name">
      <template #default="{ form }">
        <div>
          <span class="company-detail-title">Details</span>
          <div class="details">
            <div class="company-details">
              <p>Name</p>
              <span>{{ form.name }}</span>
            </div>

            <div class="company-details">
              <p>GST Number</p>
              <span>{{ form.gst_no }}</span>
            </div>

            <div class="company-details">
              <p>PAN Number</p>
              <span>{{ form.pan_no }}</span>
            </div>

            <div class="company-details">
              <p>Mobile Number</p>
              <span>{{ form.mobile_no }}</span>
            </div>

            <div class="company-details">
              <p>Company Type</p>
              <span>{{ form.masterCompanyType.display_name }}</span>
            </div>

            <div class="company-details">
              <p>KYC Status</p>
              <div class="flex flex--fit flex--middle g-2">
                <KycStatusTag size="lg" :label="form.kyc_status" class="inline" />
                <v-popover trigger="hover" v-if="form.kyc_status === 'reject' && form.reason">
                  <InformationButton />
                  <template #popover>
                    <p>{{ form.reason }}</p>
                  </template>
                </v-popover>
              </div>
            </div>

            <div class="company-details">
              <p>Company Status</p>
              <Tag size="lg" :label="form.status" :color="form.status == 'active' ? 'green' : 'red'" />
            </div>

            <div class="company-details">
              <p>Date & Time</p>
              <span>{{ formatDate(form.created_at) }}</span>
            </div>

            <div class="company-details">
              <p>Username</p>
              <span>{{ form.users[0].username }}</span>
            </div>

            <div class="company-details" v-if="form.buyerType && form.buyerType.name">
              <p>Buyer Type</p>
              <span>{{ form.buyerType.name }}</span>
            </div>
          </div>

          <!-- Documents -->
          <div class="company-data mt-2">
            <div>
              <span class="company-detail-title">Documents</span>
              <div class="documents">
                <s-field label="GST Certificate" v-if="form.gstcertificate">
                  <!-- <pdf-document-link :url="form.msmeCertificate.url" /> -->
                  <!-- <a :href="form.gstcertificate.url" target="_blank"> -->
                  <VueFileAgentWrapper :linkable="true" :value="form.gstcertificate" :readonly="true" :isDeletable="false" />
                  <!-- </a> -->
                </s-field>

                <s-field label="PAN Card" optional v-if="form.pancard">
                  <!-- <pdf-document-link :url="form.pancard.url" /> -->
                  <!-- <a :href="form.pancard.url" target="_blank"> -->
                  <VueFileAgentWrapper :linkable="true" :readonly="true" :value="form.pancard" :isDeletable="false" />
                  <!-- </a> -->
                </s-field>
                <s-field label="MSME Certificate" optional v-if="form.msmeCertificate">
                  <!-- <pdf-document-link :url="form.msmeCertificate.url" /> -->
                  <!-- <a :href="form.msmeCertificate.url" target="_blank"> -->
                  <VueFileAgentWrapper :linkable="true" :value="form.msmeCertificate" :readonly="true" :isDeletable="false" />
                  <!-- </a> -->
                </s-field>

                <s-field label="Cancelled Cheque" v-if="form.cancelledCheque">
                  <!-- <pdf-document-link :url="form.cancelledCheque.url" /> -->
                  <!-- <a :href="form.cancelledCheque.url" target="_blank"> -->
                  <VueFileAgentWrapper :linkable="true" :value="form.cancelledCheque" :readonly="true" :isDeletable="false" />
                  <!-- </a> -->
                </s-field>

                <s-field label="Authority Letter" v-if="form.authorityLetter">
                  <!-- <pdf-document-link :url="form.authorityLetter.url" /> -->
                  <!-- <a :href="form.authorityLetter.url" target="_blank"> -->
                  <VueFileAgentWrapper :linkable="true" :value="form.authorityLetter" :readonly="true" :isDeletable="false" />
                  <!-- </a> -->
                </s-field>

                <!-- <s-field label="Partnership">
                <template v-if="form.partnership">
                  <a :href="form.partnership.url" target="_blank">
                    <VueFileAgentWrapper
                      :linkable="true"
                      :value="form.partnership"
                      :readonly="true"
                      :isDeletable="false"
                    />
                  </a>
                </template>
                <document-not-available
                  v-else-if="form.kyc_status == 'pending'"
                />
                <document-not-uploaded v-else />
              </s-field> -->

                <s-field label="Company AOA" v-if="form.partnerShipAoa">
                  <!-- <pdf-document-link :url="form.partnerShipAoa.url" /> -->
                  <!-- <a :href="form.partnerShipAoa.url" target="_blank"> -->
                  <VueFileAgentWrapper :linkable="true" :value="form.partnerShipAoa" :readonly="true" :isDeletable="false" />
                  <!-- </a> -->
                </s-field>

                <s-field label="Partnership Deed" v-if="form.partnerShipDeed">
                  <!-- <pdf-document-link :url="form.partnerShipDeed.url" /> -->
                  <!-- <a :href="form.partnerShipDeed.url" target="_blank"> -->
                  <VueFileAgentWrapper :linkable="true" :value="form.partnerShipDeed" :readonly="true" :isDeletable="false" />
                  <!-- </a> -->
                </s-field>

                <s-field label="Company MOA" v-if="form.partnerShipMoa">
                  <!-- <pdf-document-link :url="form.partnerShipMoa.url" /> -->
                  <!-- <a :href="form.partnerShipMoa.url" target="_blank"> -->
                  <VueFileAgentWrapper :linkable="true" :value="form.partnerShipMoa" :readonly="true" :isDeletable="false" />
                  <!-- </a> -->
                </s-field>
              </div>
            </div>
            <div v-if="form.partners.length">
              <span class="company-detail-title"> Partners</span>
              <sp-list :data="form.partners" :attrs="$options.partnerColumns" :actions="[]" class="m-0 card-wrap">
                <template #empty>
                  <list-empty />
                </template>
                <sp-list-table> </sp-list-table>
              </sp-list>
            </div>
          </div>
        </div>

        <div v-if="isTeamMemberListVisible">
          <span class="company-detail-title">Team Member</span>
          <div class="team-member-section">
            <div class="card-wrap mb-2 w-96">
              <s-field label="Assign Team Member"></s-field>
              <AssignTeamMember
                :isDisabled="isCompanyUpdateGranted && isTeamMemberListVisible ? false : true"
                :key="`assign-dropdown-${form.id}`"
                :teamMember="form.assignTo ? form.assignTo.id : null"
                :company-id="companyFilterId || id"
                @assigned="$refs.detailsForm.refresh()"
              />
            </div>

            <TeamMemberProfile
              class="grow"
              :name="form.assignTo.name"
              :role="form.assignTo.role[0]"
              :mobileNo="form.assignTo.mobile_no"
              :description="form.assignTo.description"
              v-if="form.assignTo"
            />
          </div>
        </div>
      </template>

      <template #footer="{ saving, form }">
        <div v-if="form && form.kyc_status == 'inreview'">
          <span class="company-detail-title"> Update KYC Status </span>
          <!-- below v-if form is applied because form object is null initially due to which error gets throwed -->
          <!-- when reading form.kyc_status -->

          <div class="card-wrap">
            <!-- Below ! important  flag is applied with grid class because it is conflicting with shilp css causing undesired results -->
            <div :class="{ grid: form.kyc_status != 'pending' }" class="!grid-cols-2 gap-4">
              <!-- <s-button v-if="form.kyc_status != 'pending'" label="Approve" :loader="saving" type="submit" color="primary" class="mx-auto w-56" /> -->
              <s-button v-if="form.kyc_status != 'pending'" :loader="saving" label="Approve" type="submit" color="primary" class="mx-auto w-56" />

              <!-- Below type button is mandatory in order to prevent default behavior of submit  -->
              <s-button
                label="Reject"
                color="danger"
                class="mx-auto w-56"
                @click.native="
                  $root.$emit('shilp-modal-open', {
                    id: 'modal-kyc-reject-reason',
                    scope: {
                      itemId: id,
                    },
                  })
                "
              />
            </div>
          </div>
        </div>
        <div v-else></div>
      </template>
    </item-form>

    <KycRejectReasonModal />
    <BlockModal @refresh="$refs.detailsForm.refresh()" />
    <UnblockModal @refresh="$refs.detailsForm.refresh()" />
  </div>
</template>

<script>
import { get, changeKycStatus, downloadProfile } from "@/api/company";
import { formatDate, alertApiError } from "@/utilities/common";
import { mapGetters } from "vuex";
import roleMixin from "@/mixins/role";
export default {
  mixins: [roleMixin],
  components: {
    BlockModal: require("@/views/private/buyer/companies/BlockModal.vue").default,
    UnblockModal: require("@/views/private/buyer/companies/UnblockModal.vue").default,

    KycStatusTag: require("@/components/KycStatusTag.vue").default,
    VueFileAgentWrapper: require("@/components/VueFileAgentWrapper").default,
    KycRejectReasonModal: require("./KycRejectReasonModal.vue").default,
    CompanyFilter: require("@/components/company/Filter.vue").default,
    InformationButton: require("@/components/InformationButton").default,
    TeamMemberProfile: require("@/components/company/TeamMemberProfile").default,
    AssignTeamMember: require("@/components/company/AssignTeamMember.vue").default,
    BuyerTypeSelect: require("@/components/buyerType/Select").default,
  },
  fields: [
    "name",
    "gst_no",
    "pan_no",
    "company_type",
    "mobile_no",
    "status",
    "kyc_status",
    "reason",
    "gstcertificate",
    "pancard",
    "msmeCertificate",
    "cancelledCheque",
    "authorityLetter",
    "partnership",
    "partnerShipAoa",
    "partnerShipMoa",
    "partnerShipDeed",
    "partners",
    "masterCompanyType",
    "created_at",
    "users",
    "assignTo",
    "buyerType",
  ],
  partnerColumns: [
    "name",
    {
      name: "email",
      value: (item) => (item.email ? item.email : "-"),
    },
    "mobile_no",
    {
      name: "main_partners",
      label: "Main Partner",
      value: (item) => (item.is_default ? "Yes" : "No"),
    },
    {
      name: "enable_notification",
      label: "Notification Enabled",
      value: (item) => (item.enable_notification ? "Yes" : "No"),
    },
  ],
  props: {
    id: Number,
  },
  computed: {
    routeQueryKycStatus() {
      return this.$route.query.status;
    },
    ...mapGetters(["companyFilterId"]),
  },
  data() {
    return {
      // companyId: null,
      formState: null,
      isProfileOpening: false,
    };
  },
  created() {
    // if (this.id) this.companyId = this.id;
    if (!this.companyFilterId || !this.id) this.$router.push("/");
  },
  watch: {
    id() {
      // This watch is used to dynamically update detail when notification is clicked.
      this.$refs.detailsForm.refresh();
    },
  },
  methods: {
    formatDate,

    open() {
      this.isProfileOpening = true;
      downloadProfile(this.companyFilterId || this.id)
        .then((res) => {
          window.open(res.url, "_blank");
        })
        .catch((error) => alertApiError(error))
        .finally(() => (this.isProfileOpening = false));
    },
    async approve() {
      try {
        if (!this.formState.buyerType) {
          this.$shilp.notify({
            title: "Please select buyer type before approving.",
            type: "info",
          });

          return;
        }
        return changeKycStatus(this.companyFilterId || this.id, "approve").then((res) => {
          this.$router.push({ name: "active-company" });
          return res;
        });
      } catch {
        this.$shilp.notify({
          title: "Something went wrong. Try Again.",
          type: "danger",
        });
      }
    },

    onCompanyFilterHandler() {
      // this.companyId = id;
      this.$refs.detailsForm?.refresh();
    },

    async getDetails() {
      try {
        // getting weird null in companyfilterId, therefore setting or operator
        const res = await get(this.companyFilterId || this.id, {
          include: "partners,masterCompanyType,projects,users,assignTo,buyerType",
          read_status: 1,
        });

        return res;
      } catch {
        this.$shilp.notify({
          title: "Api Request Failed. Try Again after some time.",
          type: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-group {
  display: flex;
  align-items: center;
}
.details {
  @apply grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-4 bg-white p-5 rounded-xl;
}

.company-details {
  @apply border-2 py-3 px-4 rounded-md;
  p {
    @apply mb-2;
  }
  span {
    @apply font-semibold text-gray-500;
  }
}

.company-data {
  @apply grid xl:grid-cols-2 gap-4;
}

.documents {
  @apply grid grid-cols-2 2xl:grid-cols-3 gap-5 bg-white p-8 rounded-xl;
  .field {
    @apply flex flex-col p-0 h-fit border-none;
  }
}

.partner {
  .field {
    span {
      @apply font-bold;
    }
  }
}

.team-member-section {
  @apply flex gap-4 items-center;
}

.buyer-type-section {
  @apply flex gap-4 items-start;
}
</style>
