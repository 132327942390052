export default [
    {
        path: "settings",
        name: "settings",
        component: require("@/views/private/buyer/settings/index").default,
        meta: {
            label: "Settings",
            breadcrumbs: ["dashboard", "settings"],
        },
    },
];
