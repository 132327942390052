<template>
  <div>
    <item-form :fields="fields" :redirect="false" :notify="false" class="p-4 card-wrap" ref="item" :get="getItem" :item="id" :footer="false" :save="saveItem">
      <template #default="{ form }">
        <div class="flex flex--fit">
          <s-field-validate label="Location" rules="required" name="City" class="w-4/12 mr-2">
            <treeselect
              v-model="form.vendor_product_location"
              :multiple="true"
              :options="locationOptions"
              valueFormat="object"
              label="name"
              placeholder="Select your Locations"
              :normalizer="normalizer"
            />
          </s-field-validate>
          <s-field-validate class="grow mr-2 w-4/12" label="Category" rules="required" name="Category">
            <v-select :options="categoryOption" placeholder="Select" v-model="form.category_id" :reduce="(category) => category.value" label="label" @input="categorySelected(form)" />
          </s-field-validate>
          <s-field-validate class="grow mr-2 w-3/12" label="Brand" rules="required" name="Brand">
            <BrandSelect :multiple="true" v-model="form.brand_id" :params="form.category_id" @input="setBrandId($event)" />
          </s-field-validate>
          <s-button type="submit" label="Add" color="primary" class="button--sm mt-4" />
        </div>
      </template>
    </item-form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import BrandSelect from "@/components/brand/BrandSelect.vue";
import { get, getLocationsByVendors, createVendorProduct } from "@/api/vendor/vendor.js";
import dropdownOptions from "@/data/options";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  params: {
    limit: 25,
  },
  props: ["id"],
  data() {
    return {
      brandOptions: [],
      categoryOptions: [],
      locationOptions: [],
      isBrandsLoading: false,
      dropdownOptions,
      isLoading: false,
      submitLoader: false,
      fields: ["vendor_product_location", "category_id", "brand_id"],
      normalizer(node) {
        return {
          id: node.name + "+" + node.id,
          label: node.name,
          children: node.cities,
        };
      },
    };
  },
  components: {
    BrandSelect,
    Treeselect,
  },
  computed: {
    ...mapGetters({
      categoryOption: "categoryOptions",
    }),
    ...mapMutations({
      setBrandId: "order/setBrandId",
    }),
  },
  created() {},
  mounted() {
    this.getLocations();
  },

  methods: {
    getItem(id) {
      return get(id).then((res) => {
        // this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, res.name);
        return {
          ...res,
          role: res.role.length ? res.role[0] : null,
        };
      });
    },

    categorySelected(form) {
      form.brand_id = null;
    },

    getLocations() {
      getLocationsByVendors(this.$route.params.id)
        .then((res) => {
          this.locationOptions = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveItem(id, form) {
      form.user_id = this.$route.params.id;
      return createVendorProduct(form).then(() => {
        this.$emit("productAdded");
        this.$shilp.notify({
          title: "Product created successfully",
          type: "success",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect__input {
  border: none !important;
}
::v-deep .vue-treeselect__control {
  border-color: #cdcdcd;
}
</style>
