<template>
  <div>
    <SpList :attrs="$options.columns" endpoint="importExcel" :actions="[]" class="mt-3">
      <SpListTable>
        <template #status="{ item }">
          <Tag :label="item.status" />
        </template>

        <template #media_name="{ item }">
          <a :href="item.url" download>
            <div class="flex flex--fit gap-2 flex--middle cursor-pointer hover:text-gray-500">
              <s-icon name="IconMdiDownload" /> <span class="">{{ item.media_name }}</span>
            </div>
          </a>
        </template>

        <template #result="{ item }">
          <s-button
            v-if="item.result"
            label="View Log"
            icon="IconIcLog"
            color="secondary"
            @click.native="
              modalLog = item.result.validation_failed_rows;
              modalStatus = item.status;
              $shilp.modal.open('bulk-actions-log-modal');
            "
          />
        </template>
      </SpListTable>
    </SpList>

    <LogModal :status="modalStatus" :data="modalLog" />
  </div>
</template>

<script>
import { formatDate } from "@/utilities/common";
export default {
  columns: ["status", { name: "media_name", label: "Download" }, { name: "result", label: "Log" }, { name: "created_at", label: "Date & Time", value: (item) => formatDate(item.created_at) }],
  components: {
    Tag: require("@/components/Tag").default,
    LogModal: require("@/components/products/bulkActions/LogModal.vue").default,
  },
  created() {},

  data() {
    return {
      modalStatus: null,
      modalLog: null,
    };
  },
  methods: {
    formatDate,
  },
};
</script>

<style lang="scss" scoped></style>
