<template>
  <div class="card-wrap p-2" v-shilp-loader.overlay="isLoading">
    <h4 class="text-primary-500 font-bold">Category Wise Quarterly Sales</h4>

    <apexchart type="bar" height="350" :options="chartOptions" :series="computedSeries"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { formatPrice } from "@/utilities/common";
import { categoryWiseSales } from "@/api/category";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [],

      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["Q1", "Q2", "Q3", "Q4"],
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return formatPrice(value);
            },
          },
        },
        yaxis: {
          // title: {
          //   text: "$ (thousands)",
          // },
          labels: {
            formatter: function (value) {
              return formatPrice(value, "compact");
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
      isLoading: false,
    };
  },

  created() {
    this.getData();
    this.convertSeriesDataStructure();
  },
  destroy() {},

  computed: {
    computedSeries() {
      return this.series;
    },
  },

  methods: {
    getData() {
      this.isLoading = true;
      categoryWiseSales()
        .then((res) => {
          this.convertSeriesDataStructure(res);
        })
        .finally(() => (this.isLoading = false));
    },
    convertSeriesDataStructure(data) {
      // This method converts backend data into  valid  chart format
      if (data && Object.keys(data).length) {
        for (let item in data) {
          this.series.push({
            name: item,
            data: Object.values(data[item]).map((item) => {
              return item[0].total;
            }),
          });
        }
      } else this.series = [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
