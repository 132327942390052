<template>
  <div class="bg-white rounded-lg p-4 max-w-xl" v-shilp-loader.overlay="isLoading">
    <h4 class="text-primary-500 font-bold flex flex--fit flex--between">
      Order Statistics
      <v-popover trigger="hover">
        <InformationButton />
        <template #popover>
          <p class="text-sm text-gray-500">Data is shown for Delivered orders only.</p>
        </template>
      </v-popover>
    </h4>

    <apexchart width="500" type="bar" :options="options" :series="series" ref="apexChart" v-if="!isLoading"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { formatPrice } from "@/utilities/common";
import { getChartData } from "@/api/company";

export default {
  props: ["companyId"],
  components: {
    apexchart: VueApexCharts,
    InformationButton: require("@/components/InformationButton").default,
  },
  created() {
    this.chartData();
  },
  data() {
    return {
      months: [],
      orderCount: [],
      amountStats: [],
      isLoading: false,
    };
  },

  computed: {
    series() {
      return [
        {
          name: "Total Amount",
          data: this.amountStats,
          orderCount: this.orderCount,
        },
      ];
    },
    options() {
      return {
        plotOptions: {
          bar: {
            dataLabels: {
              orientation: "vertical",
            },
          },
        },
        chart: {
          id: "vuechart-example",
          dropShadow: {
            enabled: true,
            top: 1,
            left: 2,
            blur: 3,
            opacity: 0.5,
          },
        },
        fill: {
          colors: ["#000080"],
        },
        dataLabels: {
          formatter: function (val) {
            return val ? formatPrice(val) : 0;
          },
          style: {
            colors: ["#C6C6F8"],
          },
        },
        xaxis: {
          title: {
            text: "Months",
          },
          categories: this.months,
        },

        yaxis: {
          title: {
            text: "Total Amount",
          },
          labels: {
            formatter: function (val) {
              return formatPrice(val);
            },
          },
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return `<div class="chart-tooltip-container">
              <p> Total Amount : <span class='font-bold'>${formatPrice(series[seriesIndex][dataPointIndex])}<span> </p>
              <p> Order Count : <span class='font-bold'> ${w.config.series[0].orderCount[dataPointIndex]} </span> </p>
                </div>`;
          },
        },
      };
    },
  },

  methods: {
    formatPrice,
    chartData() {
      this.isLoading = true;
      getChartData(this.companyId).then((res) => {
        this.months = res.month;
        this.orderCount = res.order_count;
        this.amountStats = res.value;

        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.chart-tooltip-container {
  @apply p-4;
}
</style>
