export default [
  {
    path: "products",
    name: "product",
    component: require("@/views/private/master/products/Index").default,
    meta: {
      label: "Products",
    },
    redirect: { name: "product-listing" },
    children: [
      {
        path: "list",
        name: "product-listing",
        component: require("@/views/private/master/products/List").default,
        meta: {
          label: "Products",
          breadcrumbs: ["dashboard", "product-listing"],
        },
      },
      {
        path: ":id",
        props: true,
        name: "product-upsert",
        component: require("@/views/private/master/products/Item").default,
        meta: {
          label: "Add New",
          breadcrumbs: ["dashboard", "product-listing", "product-upsert"],
        },
      },
      {
        path: "bulk-actions",
        name: "bulk-actions-home",
        component: require("@/views/private/master/products/bulkActions/Index.vue").default,
        icon: "IconIcExcel",
        redirect: { name: "bulk-actions-update" },
        meta: {
          label: "Bulk Actions",
          breadcrumbs: ["dashboard", "product-listing", "bulk-actions-home"],
        },
        children: [
          {
            path: "history",
            name: "bulk-actions-history",
            component: require("@/views/private/master/products/bulkActions/History").default,
            meta: {
              label: "History",
              breadcrumbs: ["dashboard", "product", "bulk-actions-history"],
            },
          },
          {
            path: "update",
            name: "bulk-actions-update",
            component: require("@/views/private/master/products/bulkActions/Update").default,
            meta: {
              label: "Update",
              breadcrumbs: ["dashboard", "product", "bulk-actions-update"],
            },
          },
        ],
      },
    ],
  },
];
