<template>
  <div class="filter-section">
    <SearchFilter @search="$emit('search', $event)" />
    <s-button-group>
      <!-- <s-button class="filter" icon="vmdi-filter" shape="circle" @click.native="$refs.list.toggleSidebar('filters')" /> -->
      <s-button class="filter" icon="vmdi-filter" shape="circle" @click.native="$emit('toggleFilterBar')" v-if="showFilterBar" />
      <s-button class="refresh" icon="vmdi-refresh" shape="circle" @click.native="$emit('refresh')" />
    </s-button-group>
  </div>
</template>

<script>
export default {
  props: {
    showFilterBar: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SearchFilter: require("@/components/SearchFilter.vue").default,
  },
};
</script>

<style lang="scss" scoped>
.filter-section {
  @apply flex gap-4 items-center;
}
</style>
