export default [
  {
    path: "dashboard",
    name: "dashboard",
    component: require("@/views/private/dashboard/Index").default,
    meta: {
      label: "Dashboard",
      icon: "IconMdiHomeOutline",
      breadcrumbs: ["dashboard"],
    },
  },
];
