<template>
  <div>
    <h4>
      <span class="text-primary-500">{{ label }}</span> : <span class="text-success-500">{{ $options.formatPrice(totalAmount) }}</span>
    </h4>
    <apexchart type="pie" width="480px" height="280px" :options="chartOptions" :series="series" ref="pieChart"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { formatPrice } from "@/utilities/common";
import { mapGetters, mapMutations } from "vuex";
export default {
  formatPrice,
  props: ["data", "label"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      brandOptions: "brandOptions",
      categoryOptions: "categoryOptions",
    }),
    labels() {
      return Object.keys(this.data);
    },
    series() {
      return Object.values(this.data);
    },
    totalAmount() {
      if (this.series.length) {
        return this.series.reduce((total, item) => {
          return (total = total + item);
        });
      } else return 0;
    },
    chartOptions() {
      return {
        chart: {
          width: 480,
          height: 280,
          type: "pie",

          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.resetFilters();
              let brand = chartContext?.w.config.labels[config.dataPointIndex];

              this.setCategoryId(this.categoryOptions.find((item) => item.label == this.label).value);

              if (brand) this.setBrandId(this.brandOptions.find((item) => item.label == brand).value);

              this.$router.push({ name: "order-list" });
            },
          },
        },
        labels: this.labels,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "16px",
          },
          formatter: function (val, { seriesIndex, w }) {
            return formatPrice(w.config.series[seriesIndex], "compact");
          },
        },
        legend: {
          show: true,
          fontSize: "16px",
        },
        noData: {
          text: "No Data Available",
          align: "center",
          verticalAlign: "middle",
          style: {
            fontSize: "14px",
          },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return formatPrice(value);
            },
          },
        },
      };
    },
  },

  components: {
    apexchart: VueApexCharts,
  },
  methods: {
    ...mapMutations({
      setCategoryId: "order/setCategoryId",
      setBrandId: "order/setBrandId",
      resetFilters: "order/resetFilters",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
