<template>
  <item-form :fields="fields" :redirect="false" :notify="false" class="p-4 card-wrap" width="400px" ref="item" :get="getItem" :item="id" :save="saveItem">
    <template #default="{ form }">
      <div v-if="gstError" class="p-4 radius-3 bg-danger-lightest mb-3">
        <h5 class="text-danger">
          {{ gstError }}
        </h5>
      </div>
      <s-field-validate label="GST Number" rules="required|gst" class="field--required">
        <UpperCaseTextBoxInput type="text" :maxLength="15" v-model="gstNumber" @input="gstNumber.length == 15 && getGstData(gstNumber, form)" />
      </s-field-validate>
      <s-field-validate label="Company Name" rules="required" class="field--required">
        <STextbox type="text" v-model="form.name" readOnly />
      </s-field-validate>

      <s-field-validate label="Company Type" rules="required" class="field--required">
        <STextbox type="text" v-model="form.company_type" readOnly />
      </s-field-validate>

      <s-field-validate label="Office Address" rules="required" class="mr-2 field--required">
        <s-textarea :rows="3" v-model="form.vendor_office_address" readOnly />
      </s-field-validate>

      <s-field-validate name="GST Document" label="GST Document" rules="required" class="field--required">
        <VueFileAgentWrapper
          :baseUrl="$options.baseUrl"
          :key="1"
          v-model="gstFileData"
          endpoint="medias"
          :linkable="true"
          :params="{ used_for: 'vendor_gst_certificate' }"
          :multiple="false"
          :isDeletable="true"
        />
      </s-field-validate>

      <s-field-validate label="Vendor Email Id" rules="email">
        <STextbox type="text" v-model="form.email" />
      </s-field-validate>

      <s-field-validate label="Mobile No" rules="required|mobileNo" class="field--required">
        <STextbox type="number" v-model="form.mobile_no" />
      </s-field-validate>
      <s-field-validate label="Location" rules="required" name="City" class="field--required">
        <treeselect v-model="form.vendor_product_location" :multiple="true" :options="allLocations" valueFormat="object" label="name" placeholder="Select your Locations" :normalizer="normalizer" />
      </s-field-validate>
    </template>

    <template #footer="{ saving }">
      <s-button type="submit" label="Add" color="primary" class="button-sm" :loader="saving" />
    </template>
  </item-form>
</template>

<script>
import dropdownOptions from "@/data/options";
import { get, create } from "@/api/vendor/vendor.js";
import VueFileAgentWrapper from "@/components/VueFileAgentWrapper.vue";
import UpperCaseTextBoxInput from "@/components/form/inputs/UpperCaseTextBoxInput.vue";
import { getCompanyFromGstNo } from "@/api/company";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { cloneDeep } from "lodash-es";

export default {
  baseUrl: process.env.VUE_APP_STORE_REST_API,
  props: ["id", "allLocations"],
  data() {
    return {
      teamMembers: [],
      isLoading: false,
      dropdownOptions,
      fields: ["name", "email", "mobile_no", "vendor_product_location", "vendor_gst_no", "gst_certificate_id", "type", "company_type"],
      gstError: "",
      gstNoValid: false,
      gstFileData: null,
      gstNumber: null,
      normalizer(node) {
        return {
          id: node.name + "+" + node.id,
          label: node.name,
          children: node.cities,
        };
      },
    };
  },
  components: { VueFileAgentWrapper, UpperCaseTextBoxInput, Treeselect },
  methods: {
    getItem(id) {
      return get(id).then((res) => {
        this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, res.name);
        return {
          ...res,
          role: res.role.length ? res.role[0] : null,
        };
      });
    },

    getGstData(gstNumber, form) {
      const params = {
        type: "vendor",
      };
      getCompanyFromGstNo(gstNumber, params)
        .then((res) => {
          form.name = res.name;
          form.vendor_office_address = res.office_address;
          form.company_type = res.company_type;
          this.getCityById(res.projects[0].state_id, res.projects[0].city_id, form);
          this.gstNoValid = true;
          this.gstError = "";
        })
        .catch((error) => {
          form.name = "";
          this.gstNoValid = false;
          const title = error.data.errors ? Object.values(error.data.errors)[0] : error.data.message;

          this.gstError = Array.isArray(title) ? title[0] : title;
          this.$shilp.notify({
            title: Array.isArray(title) ? title[0] : title,
            type: "danger",
          });
        });
    },

    getCityById(stateId, cityId, form) {
      const locations = cloneDeep(this.allLocations);
      if (stateId) {
        const selectedState = locations.find((res) => {
          return res.id == stateId;
        });
        const selectedCity = selectedState.cities.find((res) => {
          return res.id == cityId;
        });
        form.vendor_product_location = [selectedCity];
      }
    },

    saveItem(id, form) {
      form.vendor_gst_no = this.gstNumber;
      form.gst_certificate_id = this.gstFileData[0].upload.data[0].id;
      form.type = "vendor";
      return create(form).then((res) => {
        this.isLoading = false;
        this.$shilp.notify({
          title: "Vendor created successfully",
          type: "success",
        });
        this.$router.push({ name: "vendor-product", params: { id: res.id } });
      });
    },
  },
};
</script>

<style scoped>
::v-deep .vue-treeselect__input {
  border: none !important;
}
::v-deep .vue-treeselect__control {
  border-color: #cfd8dc;
}
</style>