<template>
  <div>
    <v-select
      :resetOnOptionsChange="true"
      :clearable="false"
      :options="paymentStatusOptions"
      placeholder="Select Payment Status"
      label="display_name"
      v-model="paymentStatusId"
      :reduce="(option) => option.id"
      :searchable="false"
    />
    <ValidationObserver>
      <template #default="{ invalid }">
        <s-field-validate name="Amount" label="Enter Received Amount" :rules="`required|max_value:${paymentAmount}`" class="mt-2" v-if="showAmountField">
          <STextbox type="number" v-model="amount" :disabled="paymentFieldDisabled" />
        </s-field-validate>
        <SButton color="primary" label="Submit" class="mt-2" @click.native="change" :disabled="invalid" :loader="isChanging" />
      </template>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { changeOrderOrPaymentStatus } from "@/api/order";
export default {
  props: ["status", "orderId", "totalAmount", "receivedAmount"],
  computed: {
    ...mapGetters({
      paymentStatusOptions: "paymentStatusOptions",
    }),

    paymentStatusLabel() {
      if (this.paymentStatusId) return this.paymentStatusOptions.find((item) => item.id == this.paymentStatusId).label;
      else return null;
    },

    showAmountField() {
      if (this.paymentStatusId == 2 || this.paymentStatusId == 4) return true;
      else return false;
    },

    paymentFieldDisabled() {
      if (this.paymentStatusId == 2) return true;
      else return false;
    },

    paymentAmount() {
      return this.totalAmount - this.receivedAmount;
    },
  },

  created() {
    if (this.status) this.paymentStatusId = this.status;
    this.amount = this.paymentAmount;
  },
  data() {
    // Id 2 => Paid
    // Id 4 => Partially paid
    return {
      isChanging: false,
      paymentStatusId: null,
      amount: null,
    };
  },
  watch: {
    paymentStatusId(newValue) {
      if (newValue == 2) this.amount = this.paymentAmount;
    },
  },

  methods: {
    resetState() {
      this.isChanging = false;
      this.paymentStatusId = null;
      this.amount = null;
    },
    async change() {
      // this.paymentStatusId = status;
      this.$shilp.confirm({
        title: `Are you sure you want to update payment status to ${this.paymentStatusLabel}?`,
        message: "Please think twice before changing this status, This action is IRREVERSIBLE.",
        ok: async ({ close }) => {
          try {
            close();
            this.isChanging = true;

            let formData = {
              payment_status_id: this.paymentStatusId,
            };

            if (this.showAmountField) formData["receive_amount"] = Number(this.amount);
            await changeOrderOrPaymentStatus(this.orderId, formData);
            this.$shilp.notify({
              title: "Payment Status Changed.",
              type: "success",
            });
            this.resetState();
            this.$emit("payment-status-changed");
          } catch {
            this.$shilp.notify({
              title: "Something went wrong. Try Again.",
              type: "danger",
            });
          } finally {
            this.isChanging = false;
          }
        },
      });
    },
  },
};
</script>

<style></style>
