<template>
  <VueCountDown :time="time">
    <template slot-scope="props">{{ label }} : {{ props.minutes }} minutes, {{ props.seconds }} seconds.</template>
  </VueCountDown>
</template>

<script>
import VueCountDown from "@chenfengyuan/vue-countdown";

export default {
  components: {
    VueCountDown,
  },
  props: {
    time: Number,
    label: {
      type: String,
      default: "Time Remaining",
    },
  },
};
</script>

<style lang="scss" scoped></style>
