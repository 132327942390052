export default [
  {
    path: "brochure",
    component: require("@/views/private/master/brochure/Index").default,
    meta: {
      label: "Brochure",
    },
    redirect: { name: "brochure-list" },
    children: [
      {
        path: "list",
        name: "brochure-list",
        component: require("@/views/private/master/brochure/List").default,
        meta: {
          label: "Brochures",
          breadcrumbs: ["dashboard", "brochure-list"],
        },
      },
      {
        path: ":id",
        props: true,
        name: "brochure-upsert",
        component: require("@/views/private/master/brochure/Item").default,
        meta: {
          label: "Add New",
          breadcrumbs: ["dashboard", "brochure-list", "brochure-upsert"],
        },
      },
    ],
  },
];
