<template>
  <s-field class="status-switch" inline :label="label" :desc="desc">
    <div class="list g-2">
      <s-button
        badge
        class="text-capitalize"
        :label="value == true || value == 'active' ? 'Active' : 'Inactive'"
        :color="color"
        theme="link"
      />
      <s-switch
        :class="disabled ? 'status-switch--disabled' : null"
        :value="value"
        :id="id"
        :on-value="isEnum ? true : 'active'"
        :off-value="isEnum ? false : 'inactive'"
        :size="size"
        shape="pill"
        color="primary"
        @input="$emit('input', $event)"
      />
    </div>
  </s-field>
</template>

<script>
export default {
  props: {
    isEnum: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Boolean],
      default: "active",
    },
    id: {
      type: String,
      default: "status-input",
    },
    label: {
      type: [String, Boolean],
      default: "Status",
    },
    desc: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    size: {
      type: String,
      default: "xs",
    },
  },
  computed: {
    color() {
      return this.value == "active" || this.value == true
        ? "success"
        : "warning";
    },
  },
};
</script>

<style lang="scss">
.status-switch--disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
