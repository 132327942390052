export default {
  buyer: [
    {
      label: "Company Registration",
      value: "User",
      name: "company_register",
    },
    {
      label: "New Order",
      value: "Order",
      name: "order",
    },
    {
      label: "New Inquiry",
      value: "Inquiry",
      name: "inquiry",
    },
    {
      label: "Invoice Request",
      value: "InvoiceRequest",
      name: "invoice_request",
    },
    {
      label: "KYC",
      value: "Kyc",
      name: "KYC",
    },
  ],
  vendor: [
    {
      label: "Bids",
      value: "admin_vendor_bid",
      name: "admin_vendor_bid",
    },
    {
      label: "Orders",
      value: "admin_vendor_order",
      name: "admin_vendor_order",
    },
    {
      label: "Issues",
      value: "admin_issue",
      name: "admin_issue",
    },
   {
      label: "Payment",
      value: "admin_payment",
      name: "admin_payment",
    },
  ]
}