import $axios from "@/plugins/axios";


export const getAll = (params, id) => {
  return $axios.get("vendor/orders/" + id, { params }).then((res) => res.data);
};

export const getAllBids = (params, id) => {
  return $axios.get(`vendor/orders/${id}/bids`, { params }).then((res) => res.data);
};

export const getBidDetail = (params, id) => {
  return $axios.get(`vendor/bids/${id}`, { params }).then((res) => res.data);
};

export const getVendroProducts = (params, id) => {
  return $axios.get(`vendor/${id}/products`, { params }).then((res) => res.data);
};

export const getOrderStatuses = (params) => {
  return $axios.get("orderStatuses", { params }).then((res) => res.data);
};

export const getPaymentStatuses = () => {
  return $axios.get("vendor/payment-status").then((res) => res.data);
};

export const updateOrder = (id, payload, params) => {
  return $axios.put(`vendor/orders/${id}`, payload, { params }).then((res) => res.data);
};

export const updatePaymentStatus = (id, payload, params) => {
  return $axios.put(`vendor/orders/${id}/payment-status-update`, payload, { params }).then((res) => res.data);
};

export const updateOrderProduct = (id, payload, params) => {
  return $axios.put(`vendor/order-product/${id}`, payload, { params }).then((res) => res.data);
};

export const updateBidAssign = (id, payload, params) => {
  return $axios.put(`vendor/bid/assign/${id}`, payload, { params }).then((res) => res.data);
};

export const updateBidDetail = (id, payload, params) => {
  return $axios.put(`vendor/bids/${id}`, payload, { params }).then((res) => res.data);
};

export const updateMultipleBidPrice = (payload, params) => {
  return $axios.put('vendor/bid-details/multiple/update', payload, { params }).then((res) => res.data);
};

export const orderCountVendorWise = (id, params) => {
  return $axios.get(`vendor/${id}/orders-count`, { params }).then((res) => res.data);
};

export const allOrderCountAndAmount = (params) => {
  return $axios.get("vendor/orders/ordercount/ordercountall", { params }).then((res) => res.data);
};

export const uploadInvoice = (id, payload, params) => {
  return $axios.post(`vendor/order/${id}/invoice`, payload, { params }).then((res) => res.data);
};

export const downloadPOPDF = (id) => {
  return $axios.get(`vendor/order/po-download/${id}`).then((res) => res.data);
};

export const deleteProduct = (payload) => {
  return $axios.delete(`vendor/deleteproducts`, payload).then((res) => res.data);
};