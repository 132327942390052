<template>
  <modal size="md" title="Bulk Operation Log" id="bulk-actions-log-modal" :close="true">
    <template #default>
      <Tag :label="status" />

      <SpList :attrs="$options.columns" :data="data" :actions="[]">
        <SpListTable>
          <template #row="{ item }">
            <span>{{ item.row }}</span>
          </template>

          <template #field="{ item }">
            <span class="capitalize">{{ item.column[0].label }}</span>
          </template>

          <template #error="{ item }">
            {{ item.column[0].errors[0] }}
          </template>
        </SpListTable>
      </SpList>
    </template>
  </modal>
</template>

<script>
export default {
  columns: ["row", "field", "error"],
  components: {
    Tag: require("@/components/Tag").default,
  },
  props: {
    status: String,
    data: Array,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
