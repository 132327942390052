<template>
  <modal
    size="sm"
    title="Mention Kyc Rejection Reason"
    id="modal-kyc-reject-reason"
    @close="close"
  >
    <template #default="{ data }">
      <s-field label="Reason">
        <s-textarea :rows="4" v-model="reason" />
      </s-field>

      <div class="text-center">
        <s-button
          class="mt-4"
          align="center"
          color="primary"
          label="Submit"
          :disabled="reason == null"
          :loader="isSubmitting"
          @click.native="reject(data.itemId)"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import { changeKycStatus } from "@/api/company";

export default {
  data() {
    return {
      isSubmitting: false,
      reason: null,
    };
  },
  methods: {
    close() {
      this.reason = null;
    },
    async reject(id) {
      this.isSubmitting = true;
      try {
        await changeKycStatus(id, "reject", this.reason);
        this.$router.push({ name: "company-listing" });
        this.$shilp.notify({
          title: "Success",
          type: "success",
        });
        this.$root.$emit("shilp-modal-close", "modal-kyc-reject-reason");
      } catch {
        this.$shilp.notify({
          title: "Something went wrong. Try Again.",
          type: "danger",
        });
      } finally {
        this.isSubmitting = false;
        this.reason = null;
      }
    },
  },
};
</script>

<style></style>
