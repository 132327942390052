export default [
  {
    path: "vendor-notifications",
    redirect: { name: "list" },
    component: require("@/views/private/vendor/notifications/Index").default,
    meta: {
      label: "Notifications",
    },
    children: [
      {
        path: "list",
        name: "vendor-notification-list",
        component: require("@/components/notifications/List").default,
        meta: {
          label: "Notification List",
          breadcrumbs: ["dashboard", "vendor-notification-list"],
        },
      },
    ],
  },
];
