<template>
  <div>
    <KycFilterTabs :status="status" />
    <DataTable
      :status="status"
      :filters="filters"
      @date-filter-selected="filters.date_range = $event"
      @resetFilters="resetFilters"
      @status-filter-selected="filters.status = $event"
      @search="filters.search = $event"
      @buyerTypeFilterSelected="filters.buyer_type_id = $event"
      @active-company-filter-selected="filters.active_company = $event ? $event : null"
      @company-create-admin="filters.company_create_admin = $event ? $event : null"
    />
  </div>
</template>

<script>
export default {
  components: {
    DataTable: require("@/components/company/DataTable.vue").default,
    KycFilterTabs: require("@/components/company/KycFilterTabs.vue").default,
  },

  data() {
    return {
      filters: {
        kyc_status: this.$route.params.status,
        status: null,
        search: null,
        date_range: null,
        buyer_type_id: null,
        active_company: null,
        company_create_admin: null,
      },
    };
  },
  // below watch is used instead of computed property because computed was causing issues in pagination , filters page 1 getting called again.
  watch: {
    "$route.params.status": {
      immediate: true,
      handler(newValue) {
        if (newValue == "incompleteOrPending") this.filters.kyc_status = ["pending", "incomplete"];
        else this.filters.kyc_status = newValue;
      },
    },
  },

  props: ["status"],

  methods: {
    resetFilters() {
      this.filters.status = null;
      this.filters.search = null;
      this.filters.date_range = null;
      this.filters.buyer_type_id = null;
      this.filters.active_company = null;
      this.filters.company_create_admin = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
