<template>
  <s-field label="Invoice Status" class="mt-2">
    <v-select placeholder="Select" :options="$options.options.invoiceStatus" :value="value" :reduce="(item) => item.value" @input="$emit('input', $event)" />
  </s-field>
</template>

<script>
import options from "@/data/options";
export default {
  options,
  props: ["value"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
