<template>
  <item-form :fields="fields" :redirect="false" :notify="false" class="p-4 card-wrap" width="400px" ref="item" :get="getItem" :item="id" :save="saveItem">
    <template #default="{ form }">
      <s-field-validate label="Name" rules="required">
        <STextbox v-model="form.name" placeholder="John" />
      </s-field-validate>

      <s-field-validate label="Mobile Number" rules="required|mobileNo">
        <STextbox type="number" v-model="form.mobile_no" placeholder="3950293940" />
      </s-field-validate>

      <s-field-validate label="Email" rules="required|email">
        <STextbox v-model="form.email" placeholder="dummy@gmail.com" />
      </s-field-validate>

      <s-field-validate label="Username" rules="required">
        <STextbox v-model="form.username" placeholder="johnny_21" />
      </s-field-validate>

      <!-- Password -->
      <s-field-validate rules="required" name="Password" :password-toggle="true" label="Password">
        <s-textbox v-model="form.password" type="password" placeholder="Enter Password" />
      </s-field-validate>

      <s-field-validate label="Select Role" rules="required">
        <v-select name="Team Member" placeholder="Select" v-model="form.role" :reduce="(item) => item.value" :options="$options.dropdownOptions.role" />
      </s-field-validate>

      <s-field-validate label="Select Manager" rules="required" v-if="form.role == 'executive'">
        <v-select name="Manager" placeholder="Select" v-model="form.parent_id" :options="managers" label="name" :reduce="(item) => item.id" />
      </s-field-validate>
    </template>

    <template #footer="{ saving }">
      <s-button type="submit" label="Submit" color="primary" :loader="saving" />
    </template>
  </item-form>
</template>

<script>
import dropdownOptions from "@/data/options";
import { create, get, update, getAll } from "@/api/user.js";

export default {
  dropdownOptions,
  props: ["id"],
  data() {
    return {
      teamMembers: [],
      fields: [
        {
          name: "name",
        },
        {
          name: "mobile_no",
        },
        {
          name: "mcc",
          value: "91",
        },
        {
          name: "email",
        },
        {
          name: "username",
        },
        {
          name: "password",
        },
        {
          name: "role",
        },
        "parent_id",
      ],
    };
  },
  computed: {
    managers() {
      return this.teamMembers.filter((item) => item.role[0] == "manager");
    },
  },
  created() {
    getAll().then((res) => {
      this.teamMembers = res.data;
    });
  },
  methods: {
    getItem(id) {
      return get(id).then((res) => {
        this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, res.name);
        return {
          ...res,
          role: res.role.length ? res.role[0] : null,
        };
      });
    },
    saveItem(id, form) {
      if (id) {
        return update(id, form).then((res) => {
          this.$shilp.notify({
            title: "User updated successfully",
            type: "success",
          });
          this.$router.push({ name: "user-list" });

          return res;
        });
      } else {
        return create(form).then((res) => {
          this.$shilp.notify({
            title: "User created successfully",
            type: "success",
          });

          this.$router.push({ name: "user-list" });
          return res;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
