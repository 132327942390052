import { cloneDeep } from "lodash-es";
const state = {
  filters: {
    category_id: null,
    brand_id: null,

    attribute_option_id: [],
    search: null,
  },
};

const initialFilterState = cloneDeep(state.filters);

const getters = {
  filters: (state) => {
    return state.filters;
  },
};

const mutations = {
  setCategoryId(state, payload) {
    state.filters.category_id = payload;
  },
  setBrandFilter(state, payload) {
    state.filters.brand_id = payload;
  },
  setAttributeFilter(state, payload) {
    state.filters.attribute_option_id.push(payload);
  },

  resetFilters(state) {
    state.filters = cloneDeep(initialFilterState);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
