<template>
  <router-view :inquiryStatusOptions="inquiryStatusOptions" endpoint="inquiries" :params="params" :filter="filters" :columns="columns" module="master"></router-view>
</template>

<script>
import { mapGetters } from "vuex";
import { getReadClassList } from "@/utilities/common";
import { formatDate } from "@/utilities/common";
export default {
  components: {},
  computed: {
    ...mapGetters(["inquiryStatusOptions"]),
  },
  data() {
    return {
      filters: {
        inquiry_type: null,
        company_id: null,
        read_status: null,
        search: null,
        date_range: null,
        inquiry_status_id: null,
      },
      params: {
        limit: 25,
        include: "company,product,inquiryStatus",
      },
      columns: [
        {
          name: "type",
          label: "Type",
          width: "260px",
          fix: true,
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "created_at",
          label: "Date & Time",
          width: "260px",
          fix: true,
          sortable: true,
          value: (item) => formatDate(item.created_at),
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "name",
          label: "Name",
          width: "260px",
          fix: true,
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "company",
          label: "Company",
          value: (item) => (item.company ? item.company.name : ""),
          width: "300px",
          fix: true,
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "company_mobileno",
          label: "Company Number",
          value: (item) => (item.company ? item.company.mobile_no : ""),
          width: "200px",
          fix: true,
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },

        {
          name: "category",
          label: "Category / Brand / Grade / Size/Packing",
          width: "260px",
          fix: true,
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "status",
          label: "Status",
          width: "260px",
          fix: true,
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },

        // { name: "brand", label: "Brand", width: "260px", fix: true },
        // { name: "grade", label: "Grade", width: "260px", fix: true },
        // { name: "size_packing", label: "Size/Packing", width: "260px", fix: true },
      ],
    };
  },
  methods: {
    getReadClassList,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .inquery {
  .vs__dropdown-option {
    margin: 9px;
  }
}
</style>