import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      hasAllPermissions: "hasAllPermissions",
      isPermissionGranted: "isPermissionGranted",
    }),
    isProductListVisible() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("product-list");
      }
    },
    isProductCreateGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("product-create");
      }
    },
    isProductUpdateGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("product-update");
      }
    },

    isProductDeleteGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("product-delete");
      }
    },

    isUserListVisible() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("users-list");
      }
    },

    isBrochureListVisible() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("brochure-list");
      }
    },

    isBrochureDeleteGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("brochure-delete");
      }
    },

    isBrandListVisible() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("brand-list");
      }
    },
    isBrandDeleteGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("brand-delete");
      }
    },

    isBuyerTypeListVisible() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("buyer-type-discount-category-list");
      }
    },

    isTeamMemberListVisible() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("user-list");
      }
    },

    isTeamMemberCreateGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("team-member-create");
      }
    },

    isTeamMemberUpdateGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("team-member-update");
      }
    },

    isTeamMemberDeleteGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("team-member-delete");
      }
    },

    isInquiryListVisible() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("inquiry-list");
      }
    },
    isInquiryUpdateGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("inquiry-update");
      }
    },

    isOrderListVisible() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("order-list");
      }
    },
    isCompanyCreateGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("company-create");
      }
    },

    isCompanyUpdateGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("company-update");
      }
    },
    isCreditUpdateGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("credit-update");
      }
    },
    isCompanyBlockGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("company-block");
      }
    },

    isBulkActionsGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("bulk-actions");
      }
    },

    isVendorPriceGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("vendor-price");
      }
    },

    isCompanyEditGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("company-edit");
      }
    },
  },
};
