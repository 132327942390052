<template>
  <div>
    <table class="order-detail-table">
      <thead>
        <th>Grade</th>
        <th>Size/Packing</th>
        <th>Quantity</th>
        <th>Price</th>
        <th>Total</th>
        <th>Order Status</th>
        <th>Additional Details</th>
        <!-- <th>Payment Status</th> -->
        <th v-if="isVendorPriceGranted">Vendor Price</th>
        <th>Actions</th>
      </thead>
      <tbody>
        <tr v-for="(product, index) in order.orderProducts" :key="`product-item-container-${index}`">
          <td>
            <div class="min-w-max">
              {{ product.products.attributeOptions[0].name }}
            </div>
          </td>
          <td>
            <div class="min-w-max">
              {{ product.products.attributeOptions[1].name }}
            </div>
          </td>
          <td>
            <div class="min-w-max">
              {{ product.dispatch_quantity + " " + product.products.unit }}
            </div>
          </td>
          <td>
            <!-- Product Unit Price  -->
            <div class="product-total-wrapper">
              <product-price :price="product.price" />
              <v-popover trigger="hover" popoverWrapperClass="min-w-max">
                <template #popover>
                  <PriceBreakUp :gstPercentage="product.gst_percentage" :gstAmount="product.gst_amount" :basePrice="product.single_base_price" />
                </template>
                <!-- <s-icon class="bi-text-secondary-default bi-w-5 bi-h-5" name="IconMdiInformationOutline" /> -->
                <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
              </v-popover>
            </div>
          </td>

          <!-- Total PRice  -->
          <td>
            <div class="product-total-wrapper">
              <ProductPrice :price="product.total" />
              <v-popover trigger="hover" popoverWrapperClass="min-w-max">
                <template #popover>
                  <PriceBreakUp :gstPercentage="product.gst_percentage" :gstAmount="product.gst_total_amount" :basePrice="product.total - product.gst_total_amount" />
                </template>
                <!-- <s-icon class="bi-text-secondary-default bi-w-5 bi-h-5" name="IconMdiInformationOutline" /> -->
                <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
              </v-popover>
            </div>
          </td>

          <!-- Order Status -->
          <td>
            <order-status-tag :name="product.orderProductStatus.name" :label="product.orderProductStatus.display_name" v-if="product.orderProductStatus" />
          </td>

          <td>
            <v-popover trigger="hover" v-if="product.additional_detail">
              <template #popover>
                <p>{{ product.additional_detail }}</p>
              </template>
              <s-button icon="IconMdiInformationOutline" color="secondary" shape="circle" theme="link" />
            </v-popover>
          </td>

          <td v-if="isVendorPriceGranted">
            <VendorPrice v-if="product.vendor_price" :price="product.vendor_price" class="min-w-max" />
          </td>

          <!-- Payment Status -->
          <!-- <td>
            <payment-status-tag :label="product.orderProductPaymentStatus.display_name" :name="product.orderProductPaymentStatus.name" />
          </td> -->

          <td class="flex flex--middle flex--nowrap gap-2">
            <s-button
              v-if="product.orderProductStatus.id !== $options.orderStatusData.cancelled && product.orderProductStatus.id !== $options.orderStatusData.delivered"
              title="Change Status"
              :key="`product-status-modal-btn-${product.id}`"
              icon="IconMdiPencilOutline"
              class="mr-2"
              color="grey"
              theme="muted"
              shape="circle"
              @click.native="
                productStatusId = product.orderProductStatus.id;
                itemId = product.id;
                itemQuantity = product.dispatch_quantity;
                totalPrice = product.total;
                productPrice = product.price;
                unit = product.products.unit;
                $root.$emit('shilp-modal-open', 'modal-order-product-status-change');
              "
            />

            <template v-if="isVendorPriceGranted">
              <v-popover v-if="product.orderProductStatus.id !== $options.orderStatusData.cancelled" trigger="click">
                <template #popover>
                  <VendorPriceInput :key="`vendor-price-popup-${product.id}`" :id="product.id" @done="$emit('vendor-price-updated')" :value="product.vendor_price" />
                </template>
                <s-button color="grey" theme="muted" shape="circle" icon="IconMdiCashPlus" :key="`vendor-price-btn-${product.id}`" title="Update vendor price" />
              </v-popover>
            </template>
          </td>
        </tr>

        <tr class="order-total">
          <td colspan="4">
            <h4 class="item-label">Base Price</h4>
          </td>
          <td>
            <h4 class="font-bold">{{ formatPrice(order.total - order.gst_total_amount) }}</h4>
          </td>
        </tr>

        <tr class="order-total">
          <td colspan="4">
            <h4 class="item-label">GST</h4>
          </td>
          <td>
            <h4 class="font-bold">{{ formatPrice(order.gst_total_amount) }}</h4>
          </td>
        </tr>

        <tr class="order-total">
          <td colspan="4">
            <h4 class="item-label">Loading</h4>
          </td>
          <td>
            <h4 class="font-bold">{{ formatPrice(order.loading) }}</h4>
          </td>
        </tr>

        <tr class="order-total">
          <td colspan="4">
            <h4 class="item-label">Unloading</h4>
          </td>
          <td>
            <h4 class="font-bold">{{ formatPrice(order.unloading) }}</h4>
          </td>
        </tr>

        <tr class="order-total">
          <td colspan="4">
            <h4 class="item-label">Freight</h4>
          </td>
          <td>
            <h4 class="font-bold">{{ formatPrice(order.freight) }}</h4>
          </td>
        </tr>

        <tr class="order-total">
          <td colspan="2">
            <h4 class="item-label">Total</h4>
          </td>

          <td colspan="2" class="font-bold">{{ order.total_quantity }}</td>

          <td>
            <h4 class="font-bold">{{ formatPrice(order.total) }}</h4>
          </td>
        </tr>

        <tr class="order-total">
          <td colspan="4">
            <h4 class="item-label">Discount</h4>
          </td>

          <td>
            <h4 class="font-bold" :class="{ 'text-danger-500': order.discount_amount }"><span v-if="order.discount_amount">-</span>{{ formatPrice(order.discount_amount) }}</h4>
          </td>
        </tr>
      </tbody>
    </table>

    <ProductStatusModal
      :productStatusId="productStatusId"
      :itemId="itemId"
      :itemQuantity="itemQuantity"
      :totalPrice="totalPrice"
      :unit="unit"
      :productPrice="productPrice"
      :order="order"
      @order-product-status-changed="$emit('order-product-status-changed')"
      @close="resetState"
    />
  </div>
</template>

<script>
import { formatPrice } from "@/utilities/common";
import orderStatusData from "@/data/orderStatus";
import VendorPriceInput from "./details/VendorPriceInput.vue";
import roleMixin from "@/mixins/role.js";

export default {
  orderStatusData,
  mixins: [roleMixin],
  props: ["order"],
  components: {
    ProductPrice: require("@/components/ProductPrice.vue").default,
    PriceBreakUp: require("@/components/order/PriceBreakUp.vue").default,
    OrderStatusTag: require("@/components/OrderStatusTag.vue").default,
    // PaymentStatusTag: require("@/components/order/PaymentStatusTag").default,
    ProductStatusModal: require("@/views/private/buyer/order/items/ProductStatusModal.vue").default,
    VendorPrice: require("./VendorPrice.vue").default,
    VendorPriceInput,
  },
  data() {
    return {
      productStatusId: null,
      itemId: null,
      itemQuantity: null,
      totalPrice: 0,
      productPrice: 0,
      unit: null,
    };
  },
  computed: {
    showOrderStatusUpdateField() {
      if (this.orderStatus.id == this.$options.orderStatusData.cancelled) return false;
      else return true;
    },
  },
  methods: {
    formatPrice,
    resetState() {
      this.productStatusId = null;
      this.itemId = null;
      this.itemQuantity = null;
      this.totalPrice = 0;
      this.productPrice = 0;
      this.unit = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-detail-table {
  @apply w-full shadow-lg rounded-lg overflow-auto bg-white text-gray-900 text-base font-normal;
  thead {
    th {
      text-align: left;
    }
  }
  tr {
    @apply border-t border-b;
  }
  tr,
  thead {
    td,
    th {
      padding: 12px;
    }
  }
}

.order-total {
  @apply border-none;
}

.item-label {
  @apply text-gray-500 font-normal;
}

.product-total-wrapper {
  @apply flex gap-2 items-center min-w-max;
}
</style>
