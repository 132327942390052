<template>
  <div class="w-screen h-screen">
    <div v-if="loading" class="flex flex--column h-100 flex--center flex--fit flex--middle bg-white">
      <img src="@/assets/logo.svg" alt="" width="200" height="200" />
      <Loader />
    </div>

    <s-layout
      v-else
      push-top
      full-height
      class="wh-layout"
      top-size="70px"
      :left-size="leftSize"
      ref="mainContainer"
      :content-classlist="['main-content']"
      :left-classlist="['br-lightest']"
      :top-classlist="['bb-lightest']"
      :right-classlist="['bl-lightest', 'p-0']"
    >
      <template #top>
        <div class="page-header">
          <div class="page-header__content">
            <s-breadcrumbs class="flex__fluid" :truncate="200" />
          </div>
          <div class="page-header__action">
            <div class="flex__fit pr-3">
              <div class="flex flex--fit flex--middle gap-2">
                <portal-target name="header-actions" slim />
                <div class="admin-icon-wrapper">
                  <NotificationWrapper />

                  <v-popover trigger="click">
                    <s-button icon="IconMdiAccountCircleOutline" shape="circle" color="primary" />
                    <template #popover>
                      <div class="text-gray-500 text-md font-medium">
                        {{ user.name }}
                        <span v-if="user.roles && user.roles.length" class="text-sm"> ({{ user.roles[0].display_name }})</span>
                      </div>

                      <Logout class="mt-2" />
                    </template>
                  </v-popover>
                </div>

                <div class="notification-container"></div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #left>
        <side-nav @toggle-collapse="handleCollapse" :width="$options.width" />
      </template>

      <template #default>
        <router-view />
      </template>
    </s-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { me } from "@/api/me";
import { getBrandOptions, getCategoryOptions, getOrderStatusOptions, getPaymentStatusOptions, getInquiryStatusOptions } from "@/api/common";

// import { getAll as getAllTeamMembers } from "@/api/teamMember";
import { getAll as getAllUsers } from "@/api/user.js";
import { get as getBuyerTypeOptions } from "@/api/buyerType";
// import { getCompanyTypeOptions } from "@/api/company";
import gql from "graphql-tag";
import masterCompanyType from "@/graph/company/getType.gql";
import { getAll as getAllRoles } from "@/api/role";
export default {
  width: "250px",
  widthCollapsed: "50px",
  components: {
    Loader: require("@/components/Loader").default,
    SideNav: require("@/components/SideNav").default,
    Logout: require("@/components/Logout").default,
    NotificationWrapper: require("@/components/notifications/Wrapper.vue").default,
  },
  apollo: {
    masterCompanyType: gql`
      ${masterCompanyType}
    `,
  },
  data() {
    return {
      loading: true,
      leftSize: "250px",
      masterCompanyType: null,
    };
  },
  watch: {
    masterCompanyType() {
      this.setCompanyTypeOptions(this.masterCompanyType.data);
    },
  },

  async mounted() {
    /**
     * Check for token
     * Set Axios token in header before sending the hydration call
     */
    this.startHydration();

    const token = localStorage.getItem("bit-admin-token");
    if (!token) {
      this.$router.push({ name: "login" });
      return;
    }
  },

  computed: {
    ...mapGetters({
      user: "user",
      rolePermissions: "getRolePermissionsOfUser",
      hasAllPermissions: "hasAllPermissions",
      isPermissionGranted: "isPermissionGranted",
    }),
  },
  methods: {
    ...mapActions(["hydrate", "setBrandOptions", "setCategoryOptions", "setPaymentStatusOptions", "setOrderStatusOptions", "setTeamMemberOptions"]),
    ...mapMutations(["setCompanyTypeOptions", "setTeamMemberOptions", "setInquiryStatusOptions", "setBuyerTypeOptions", "setRoleOptions"]),

    handleCollapse(collapsed) {
      if (collapsed) this.leftSize = this.$options.widthCollapsed;
      else this.leftSize = this.$options.width;
    },
    async startHydration() {
      // Api call will come here which will get loggen in admin data

      try {
        const profile = await me();

        const roleOptions = await getAllRoles();
        this.setRoleOptions(roleOptions.data);

        this.hydrate(profile);

        if (this.hasAllPermissions || this.isPermissionGranted("brand-list")) {
          const brandOptions = await getBrandOptions();
          this.setBrandOptions(brandOptions);
        }

        if (this.hasAllPermissions || this.isPermissionGranted("team-member-list")) {
          const userOptions = await getAllUsers();
          this.setTeamMemberOptions(userOptions.data);
        }

        const categoryOptions = await getCategoryOptions();
        this.setCategoryOptions(categoryOptions);

        const paymentStatusOptions = await getPaymentStatusOptions();
        this.setPaymentStatusOptions(paymentStatusOptions);

        if (this.hasAllPermissions || this.isPermissionGranted("inquiry-list")) {
          const inquiryStatusOptions = await getInquiryStatusOptions({ limit: -1 });

          this.setInquiryStatusOptions(inquiryStatusOptions);
        }

        if (this.hasAllPermissions || this.isPermissionGranted("buyer-type-discount-category-list")) {
          const buyerTypeOptions = await getBuyerTypeOptions({ limit: -1 });

          this.setBuyerTypeOptions(buyerTypeOptions.data);
        }

        const orderStatusOptions = await getOrderStatusOptions({
          limit: -1,
          sort: { by: "sort_order", order: "asc" },
        });
        this.setOrderStatusOptions(orderStatusOptions);

        // const companyTypeOptions = await getCompanyTypeOptions({
        //   limit: -1,
        // });

        this.loading = false;

        this.$shilp.notify({
          title: "Welcome",
          type: "success",
          icon: "IconIcTwotoneAccountCircle",
        });
      } catch {
        this.$shilp.notify({
          title: "Failed to Login. Try again in some time.",
          type: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.admin-icon-wrapper {
  @apply flex gap-2 items-center mx-2;
}
.admin-icon {
  @apply w-8 h-8;
}
.main-content {
  @apply p-4;
}
</style>
