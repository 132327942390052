<template>
  <modal size="sm" title="Update Product Details" id="bid-details-modal">
    <item-form :fields="fields" :redirect="false" :notify="false" class="p-4 card-wrap" width="400px" ref="item" :defaultData="details" :item="id" :save="saveItem">
      <template #default="{ form }">
        <div class="grid-wrap">
          <div class="info-section">
            <label for="" class="block"> Total Quantity</label>
            <span> {{ details.quantity }} {{ details.unit_name }}</span>
          </div>

          <!-- Total Price -->
          <div class="info-section">
            <label for="" class="block"> Total Price</label>
            <span>{{ formatPrice(details.total) }}</span>
          </div>
        </div>
        <div class="flex flex--fit flex--nowrap">
          <s-field-validate label="Dispatch Quantity" rules="required" class="w-1/2 mr-4 field--required" :after="details.unit_name">
            <STextbox type="number" v-model="form.dispatch_quantity"
          /></s-field-validate>

          <s-field-validate label="Price" rules="required" class="mr-2 w-1/2 field--required">
            <STextbox type="number" :rows="3" v-model="form.price" />
          </s-field-validate>
        </div>

        <div class="flex flex--fit flex--nowrap">
          <s-field-validate class="grow mr-4 w-1/2 field--required" label="Status" rules="required" name="Status">
            <v-select :loading="isLoading" :options="orderStatusList" placeholder="Select" v-model="order_status_id">
              <template v-slot:option="orderStatusList"> <StatusTag :label="orderStatusList.label" :name="orderStatusList.name.toLowerCase()" /> </template>
            </v-select>
          </s-field-validate>

          <div class="w-1/2">
            <p>Adjusted Price</p>
            <p class="text-2xl text-orange-500">{{ formatPrice(adjustedPrice(form.dispatch_quantity, form.price)) }}</p>
          </div>
        </div>
        <s-field label="Mark order as complete" class="my-2 p-2 rounded-md" inline>
          <s-switch id="mark-order-complete" :on-value="1" :off-value="0" shape="pill" color="success" v-model="form.complete_order" size="sm" />
        </s-field>
      </template>

      <template #footer="{ saving, validate }">
        <s-button type="submit" label="Submit" color="primary" class="button-sm" :loader="saving" :disabled="saving || validate.invalid" />
      </template> </item-form
  ></modal>
</template>

<script>
import { updateOrderProduct } from "@/api/vendor/order.js";
import { formatPrice } from "@/utilities/common";
import StatusTag from "@/components/StatusTag.vue";

export default {
  props: ["id", "details", "orderStatusList"],
  data() {
    return {
      isLoading: false,
      fields: ["dispatch_quantity", "price", "complete_order"],
      order_status_id: null,
    };
  },
  components: {
    StatusTag,
  },
  watch: {
    details() {
      this.order_status_id = { value: this.details.vendorOrderProductStatus.id, label: this.details.vendorOrderProductStatus.display_name };
    },
  },
  methods: {
    formatPrice,
    adjustedPrice(qty, price) {
      return price * Number(qty);
    },
    saveItem(id, form) {
      const productId = this.details.id;
      form.order_status_id = this.order_status_id.value;
      if (form.complete_order == null) {
        form.complete_order = 0;
      }
      return updateOrderProduct(productId, form).then((res) => {
        this.$emit("close");
        this.$root.$emit("shilp-modal-close", "bid-details-modal");
        this.$shilp.notify({
          title: "Product updated successfully",
          type: "success",
        });
        return res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-wrap {
  @apply grid grid-cols-2 gap-8 mt-4 items-center;
}

.info-section {
  label {
    @apply text-lg text-gray-500;
  }

  span {
    @apply text-lg;
  }
}
::v-deep .vs__dropdown-menu {
  height: 190px;
}
::v-deep .vs__dropdown-option {
  margin: 9px;
}
</style>
