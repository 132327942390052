<template>
  <div>
    <portal to="header-actions">
      <s-button v-if="isTeamMemberCreateGranted" label="Add New" @click.native="$router.push({ name: 'team-members-upsert', params: { id: '+' } })" color="primary" icon="IconIcRoundPlus" />
    </portal>
    <SpList ref="list" :filters="filters" sortBy="created_at" sortOrder="desc" :attrs="$options.columns" endpoint="teams" :actions="['refresh', 'settings']" :params="$options.params">
      <template #empty>
        <ListEmpty />
      </template>

      <template #header="{ refresh }">
        <div></div>
        <FilterSection @refresh="refresh()" @search="filters.search = $event" />
      </template>
      <SpListTable @rowClick="handleRowClick">
        <template #actions="{ item }">
          <s-button v-if="isTeamMemberDeleteGranted" shape="circle" theme="muted" icon="IconMdiTrashCanOutline" color="danger" @click.native="deleteMember(item.id, item.name)" />
        </template>
      </SpListTable>
    </SpList>
  </div>
</template>

<script>
import { remove } from "@/api/teamMember";
import { mapMutations } from "vuex";
import { getAll as getAllTeamMembers } from "@/api/teamMember";
import { formatDate } from "@/utilities/common";
import roleMixin from "@/mixins/role";
export default {
  mixins: [roleMixin],
  columns: [
    {
      name: "created_at",
      label: "Date & Time",
      sortable: true,
      value: (item) => formatDate(item.created_at),
    },
    "name",
    "mobile_no",
    "description",
    {
      name: "actions",
      rowClick: false,
    },
  ],
  params: {
    include: "company",
  },
  components: {
    FilterSection: require("@/components/TableFilterSection.vue").default,
  },
  data() {
    return {
      filters: {
        search: null,
      },
    };
  },

  methods: {
    ...mapMutations(["setTeamMemberOptions"]),

    handleRowClick($event) {
      if (this.isTeamMemberUpdateGranted) this.$router.push({ name: "team-members-upsert", params: { id: $event.id } });
    },

    deleteMember(id, name) {
      this.$shilp.confirm({
        title: "Are you sure",
        message: `Delete Team member ${name} `,
        okLabel: "Yes",
        cancelColor: "grey",
        ok: ({ close }) => {
          remove(id).then(() => {
            getAllTeamMembers({ limit: -1 }).then((teamMembers) => {
              this.setTeamMemberOptions(teamMembers.data);
            });
            close();
            this.$refs.list.refresh();
          });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
