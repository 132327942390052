<template>
  <img :src="url" :class="`w-${width} h-${width}`" class="object-contain" />
</template>

<script>
export default {
  // Size map
  props: {
    size: {
      type: String,
      default: "sm",
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sizeMap: {
        sm: 14,
        md: 16,
        lg: 14,
        xl: 20,
      },
    };
  },
  computed: {
    width() {
      return this.sizeMap[this.size];
    },
    height() {
      return this.sizeMap[this.size];
    },
  },
};
</script>

<style scoped lang="scss">
//  w-14 w-16 w-20 h-14 h-16 h-20 w-20 h-20
</style>
