<template>
  <quill-editor :value="value" @input="$emit('input', $event)" ref="myQuillEditor"> </quill-editor>
</template>

<script>
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
export default {
  props: {
    value: String,
  },
  components: {
    QuillEditor: quillEditor,
  },
  computed: {
    quill() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  mounted() {},
  data() {
    return {
      content: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
