export default [
  {
    path: "notifications",
    redirect: { name: "list" },
    component: require("@/views/private/buyer/notifications/Index").default,
    meta: {
      label: "Notifications",
    },

    children: [
      {
        path: "list",
        name: "notification-list",
        component: require("@/components/notifications/List").default,
        meta: {
          label: "Notification List",
          breadcrumbs: ["dashboard", "notification-list"],
        },
      },
    ],
  },
];
