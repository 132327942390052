<template>
  <div class="container relative mx-auto w-full h-full card-wrap">
    <PageHeader title="Additional Comments" />
    <div class="additional-comments-card">
      <div class="flex-1">
        <Card :index="index" :comment="comment" v-for="(comment, index) in comments" :key="`comment-${index}`" class="mt-5" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Card: require("./Card.vue").default,
    PageHeader: require("@/components/form/PageHeader").default,
  },
};
</script>

<style lang="scss" scoped>
.additional-comments-card {
  @apply flex;
  &::before {
    content: "";
    @apply border-2 border-opacity-20 border-gray-700  border -mr-3;
  }
}
</style>
