<template>
  <router-view></router-view>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  beforeDestroy() {
    this.resetFilters();
  },
  methods: {
    ...mapMutations({
      resetFilters: "product/resetFilters",
    }),
  },
};
</script>

<style></style>
