export default [
  {
    path: "vendor-unassigned-orders",
    name: "vendor-unassigned-orders",
    component: require("@/views/private/vendor/orders/un-assigned/Index").default,
    meta: {
      label: "Unassigned Order",
    },
    redirect: { name: "vendor-unassigned-order-list" },
    children: [
      {
        path: "list",
        name: "vendor-unassigned-order-list",
        component: require("@/views/private/vendor/orders/un-assigned/List").default,
        meta: {
          label: "Unassigned Orders",
          breadcrumbs: ["dashboard", "vendor-unassigned-order-list"],
        },
      },
      {
        path: ":id",
        props: true,
        name: "vendor-bids-list",
        component: require("@/views/private/vendor/orders/un-assigned/bids/List").default,
        meta: {
          label: "List",
          breadcrumbs: ["dashboard", "vendor-unassigned-order-list", "vendor-bids-list"],
        },
      },
      {
        path: ":id/assign/:bidId",
        props: true,
        name: "vendor-bids-assign",
        component: require("@/views/private/vendor/orders/un-assigned/bids/AssignBid").default,
        meta: {
          label: "Assign Bid",
          breadcrumbs: ["dashboard", "vendor-unassigned-order-list", "vendor-bids-list", "vendor-bids-assign"],
        },
      },
    ],
  },
  {
    path: "vendor-assigned-orders",
    name: "vendor-assigned-orders",
    component: require("@/views/private/vendor/orders/assigned/Index").default,
    meta: {
      label: "Assigned Order",
    },
    redirect: { name: "vendor-assigned-order-list" },
    children: [
      {
        path: "list",
        name: "vendor-assigned-order-list",
        component: require("@/views/private/vendor/orders/assigned/List").default,
        meta: {
          label: "Assigned Orders",
          breadcrumbs: ["dashboard", "vendor-assigned-order-list"],
        },
      },
      {
        path: "orders/:id",
        props: true,
        name: "vendor-order-details",
        component: require("@/views/private/vendor/orders/assigned/order-detail/View").default,
        meta: {
          label: "Details",
          breadcrumbs: ["dashboard", "vendor-assigned-order-list", "vendor-order-details"],
        },
      },
    ],
  },
];

