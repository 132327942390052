export default [
  {
    path: "projects",
    redirect: { name: "project-list" },
    component: require("@/views/private/projects/Index.vue").default,
    meta: {
      icon: "IconMdiOfficeBuildingMarkerOutline",
    },
    children: [
      {
        path: "",
        name: "project-list",
        component: require("@/views/private/projects/List").default,
        meta: {
          label: "Projects",
          breadcrumbs: ["dashboard", "project-list"],
        },
      },
    ],
  },
];
