import { cloneDeepItem } from "@/utilities/common";
// import rootState from "../root";

const state = {
  filters: {
    date_range: null,
    order_status_id: null,
    payment_status_id: null,
    // company_id: rootState.state.companyFilterId,
    company_id: null,

    project_id: null,
    brand_id: null,
    category_id: null,
    search: null,
    read_status: null,
    invoice_empty: null,
    expected_delivery_date_missing: null,
  },
  expectedDeliveryDate: null,
};

const initialFilterState = cloneDeepItem(state.filters);

const getters = {
  filters: (state) => {
    return state.filters;
  },
  expectedDeliveryDate: (state) => {
    return state.expectedDeliveryDate;
  },
};

const mutations = {
  setDateRange(state, payload) {
    state.filters.date_range = payload;
  },
  setOrderStatusId(state, payload) {
    state.filters.order_status_id = payload;
  },
  setPaymentStatusId(state, payload) {
    state.filters.payment_status_id = payload;
  },
  setCategoryId(state, payload) {
    state.filters.category_id = payload;
  },
  setBrandId(state, payload) {
    state.filters.brand_id = payload;
  },
  setCompanyId(state, payload) {
    state.filters.company_id = payload;
  },
  setProjectId(state, payload) {
    state.filters.project_id = payload;
  },

  setSearch(state, payload) {
    state.filters.search = payload;
  },
  setReadStatus(state, payload) {
    state.filters.read_status = payload;
  },
  setInvoiceEmpty(state, payload) {
    state.filters.invoice_empty = payload;
  },
  setExpectedDeliveryDate(state, payload) {
    state.expectedDeliveryDate = payload;
  },
  setExpectedDeliveryDateMissing(state, payload) {
    state.filters.expected_delivery_date_missing = payload;
  },
  resetFilters(state) {
    // const orderStatusId = state.filters.order_status_id;
    state.filters = cloneDeepItem(initialFilterState);
    // state.filters.company_id = rootState.state.companyFilterId;
    // state.filters.company_id = null;
    // state.filters.order_status_id = orderStatusId;
    state.expectedDeliveryDate = null;
    // rootState.state.companyFilterId = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
