import $axios from "@/plugins/axios";

export const uploadInvoice = (data, params) => {
  return $axios.post(`invoices`, data, { params }).then((res) => {
    return res.data;
  });
};

export const deleteInvoice = (id) => {
  return $axios.delete(`invoices/${id}`).then((res) => {
    return res.data;
  });
};
