<template>
  <div>
    <ValidationObserver>
      <template #default="{ invalid }">
        <s-field-validate name="Amount" label="Enter vendor price" :rules="`required|positiveNonZeroFloat`" class="mt-2">
          <STextbox type="number" v-model="amount" />
        </s-field-validate>
        <SButton color="primary" label="Submit" class="mt-2" @click.native="update" :disabled="invalid" :loader="isLoading" />
      </template>
    </ValidationObserver>
  </div>
</template>

<script>
import { updateVendorPrice } from "@/api/order";
export default {
  props: ["id", "value"],
  data() {
    return {
      amount: null,
      isLoading: false,
    };
  },
  created() {
    if (this.value) this.amount = this.value;
  },
  methods: {
    update() {
      this.isLoading = true;
      updateVendorPrice(this.id, {
        vendor_price: Number(this.amount),
      })
        .then(() => {
          this.$emit("done");
          this.$shilp.notify({
            title: "Vendor price saved.",
            type: "success",
          });
        })
        .catch((err) => console.log(err))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
