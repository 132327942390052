<template>
  <div class="status-tab">
    <s-button-group color="secondary" theme="tab" size="lg">
      <s-button label="History" :to="{ name: 'bulk-actions-history' }" icon="IconIcHistory" />
      <s-button label="Update" :to="{ name: 'bulk-actions-update' }" icon="IconIcUpdate" />
    </s-button-group>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.status-tab {
  @apply flex items-center justify-between;
  .button-group {
    @apply space-x-2;
    .button {
      @apply bg-white;
    }
  }
}
</style>
