var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"header-actions"}},[_c('div',{staticClass:"header-actions"},[_c('s-button',{attrs:{"color":"primary","label":"Add New Brochure","icon":"IconIcRoundPlus","to":{ name: 'brochure-upsert', params: { id: '+' } }}})],1)]),_c('sp-list',{ref:"list",staticClass:"h-100",attrs:{"sortBy":"created_at","sortOrder":"desc","filters":_vm.filters,"perPage":_vm.$options.params.limit,"params":_vm.$options.params,"attrs":_vm.columns,"endpoint":"brochures"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('list-empty')]},proxy:true},{key:"header",fn:function(ref){
var refresh = ref.refresh;
return [_c('div'),_c('FilterSection',{on:{"refresh":function($event){return refresh()},"search":function($event){_vm.filters.search = $event}}})]}},{key:"filters",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('s-button',{attrs:{"label":"Clear","icon":"IconMdiFilterRemoveOutline","color":"danger","theme":"muted"},nativeOn:{"click":function($event){return _vm.resetFilters.apply(null, arguments)}}})],1)]},proxy:true}])},[_c('sp-list-table',{on:{"rowClick":function($event){return _vm.$router.push({ name: 'brochure-upsert', params: { id: $event.id } })}},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("#"+_vm._s(item.id))])]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"created_by",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.created_by))])]}},{key:"brand_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.brand_id))])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [(_vm.isDeleteGranted)?_c('s-button',{attrs:{"icon":"IconMdiTrashCanOutline","shape":"circle","color":"danger","theme":"muted"},nativeOn:{"click":function($event){return _vm.deleteBrochure(item.id)}}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }