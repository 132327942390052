import $axios from "@/plugins/axios";

export const get = (params = null) => {
  return $axios.get(`buyerTypes`, { params }).then((res) => {
    return res.data;
  });
};

export const update = (data) => {
  return $axios.put(`buyerTypes`, data).then((res) => {
    return res.data;
  });
};

export const getBuyerTypeCategoryDiscounts = (params = null) => {
  return $axios.get(`buyerTypeCategoryDiscounts`, { params }).then((res) => {
    return res.data;
  });
};

export const updateBuyerTypeCategoryDiscounts = (data, params = null) => {
  return $axios.put(`buyerTypeCategoryDiscounts/update`, data, { params }).then((res) => {
    return res.data;
  });
};

export const assignBuyerTypeToCompany = (companyId, id, params = null) => {
  return $axios.put(`companies/${companyId}/assignBuyerType`, { buyer_type_id: id }, { params }).then((res) => res.data);
};
