<template>
  <div>
    <div class="p-4">
      <div class="relative z-10 bg-white py-3 px-4 mb-4 rounded-md mx-auto drop-shadow flex flex--fit gap-6 flex--bottom flex--between" v-shilp-loader.overlay="isCompanyLoading">
        <CompanyFilter @selected="onCompanyFilterHandler" :kycStatus="routeQueryKycStatus" class="company-filter" :clearable="false" />

        <CompanyGst v-if="companyGstNumber" :gstNumber="companyGstNumber" />

        <CompanyMobile v-if="companyMobileNumber" :number="companyMobileNumber" />

        <CompanyUserFullName v-if="companyFullName" :name="companyFullName" />

        <div class="flex flex--middle gap-2 flex--fit">
          <s-button icon="vmdi-refresh" color="primary" @click.native="$refs.statistics.refresh()" />

          <s-button
            label="Profile"
            color="primary"
            @click.native="
              $router.push({ name: routeQueryKycStatus == 'approve' ? 'approved-company-profile' : 'company-profile', params: { id: companyFilterId }, query: { status: routeQueryKycStatus } })
            "
            v-tooltip="'Go to Profile page'"
          />
        </div>
      </div>

      <!-- below redirect false is applied to avoid issues of redirecting user to dashboard instead of given push -->
      <item-form :fields="$options.fields" :footer="false" :redirect="false" :notify="false" :item="id" :get="getDetails" ref="statistics">
        <template #default="{ form }">
          <!-- Order Widget -->
          <OrderWidget :company-id="companyFilterId" @selected="onOrderWidgetSelect" type="info" v-if="isOrderListVisible" />

          <div>
            <div class="credit-details">
              <s-field label="Total Credit">
                <div class="credit-field-wrap">
                  <s-textbox type="text" class="text-lg" :disabled="true" name="Total" :value="formatPrice(form.credit)" :class="form.credit < 0 ? 'field-block-red' : 'field-block-green'" />

                  <v-popover trigger="click" popoverWrapperClass="p-4">
                    <template #popover>
                      <UpdateCredit :companyId="companyFilterId" :value="form.credit" @credit-updated="$refs.statistics.refresh()" />
                    </template>
                    <s-button v-if="isCreditUpdateGranted" icon="IconMdiPencilOutline" color="grey" theme="muted" shape="circle" title="Edit Credit" size="sm" />
                  </v-popover>
                </div>
              </s-field>

              <s-field label="Available Credit">
                <s-textbox
                  type="text"
                  class="text-lg"
                  :disabled="true"
                  name="Available"
                  :value="formatPrice(form.available_credit)"
                  :class="form.available_credit < 0 ? 'field-block-red' : 'field-block-green'"
                />
              </s-field>

              <s-field label="Utilized Credit">
                <s-textbox
                  type="text"
                  class="text-lg"
                  :disabled="true"
                  name="Utilized"
                  :value="formatPrice(form.utilize_credit)"
                  :class="form.utilize_credit < 0 ? 'field-block-red' : 'field-block-green'"
                />
              </s-field>
            </div>
          </div>

          <div class="total-amount-spent-wrap">
            <s-field label="Total Projects" class="card-clickable" @click.native="$router.push({ name: 'project-list' })">
              <span class="field-block-green text-lg font-bold">{{ form.total_project }}</span>
            </s-field>

            <s-field label="Last Order Date" v-if="form.last_order_date">
              <span class="font-bold text-primary-500 text-md">{{ formatDate(form.last_order_date) }}</span>
            </s-field>
          </div>

          <div class="flex flex--top gap-4 flex--nowrap">
            <OrderChart :company-id="companyFilterId" v-if="isOrderListVisible" />
            <RecentOrders class="card-wrap" v-if="isOrderListVisible" />
          </div>
        </template>
      </item-form>
    </div>
  </div>
</template>

<script>
import { get } from "@/api/company";
import { formatPrice, formatDate } from "@/utilities/common";
import { mapGetters } from "vuex";
import roleMixin from "@/mixins/role";
export default {
  fields: ["name", "mobile_no", "credit", "available_credit", "utilize_credit", "total_project", "last_order_date"],

  components: {
    OrderWidget: require("@/components/order/Widget.vue").default,
    OrderChart: require("@/components/company/OrderChart.vue").default,
    UpdateCredit: require("@/components/company/UpdateCredit.vue").default,
    RecentOrders: require("@/components/company/RecentOrders.vue").default,
    CompanyFilter: require("@/components/company/Filter.vue").default,
    CompanyMobile: require("@/components/company/Mobile.vue").default,
    CompanyGst: require("@/components/company/Gst").default,
    CompanyUserFullName: require("@/components/company/UserFullName").default,
    // AvailableCredit: require("@/components/AvailableCredit").default,
  },
  props: {
    id: Number,
  },
  mixins: [roleMixin],
  data() {
    return {
      updating: false,
      companyName: "",
      isCompanyLoading: false,
      companyGstNumber: null,
      companyMobileNumber: null,
      companyFullName: null,

      // companyId: null,
    };
  },
  computed: {
    routeQueryKycStatus() {
      return this.$route.query.status;
    },
    ...mapGetters(["companyFilterId"]),
  },
  created() {
    if (!this.companyFilterId) this.$router.push("/");
  },
  methods: {
    formatPrice,
    formatDate,

    onOrderWidgetSelect(id) {
      const query = {
        company_name: this.companyName,
      };
      if (id) query["order_status_id"] = id;

      this.$router.push({ name: "order-list", params: { categoryId: 3 }, query });
    },

    onCompanyFilterHandler() {
      // this.companyId = id;
      this.$refs.statistics?.refresh();
    },

    async getDetails() {
      this.isCompanyLoading = true;
      try {
        const res = await get(this.companyFilterId, {
          include: "users",
          read_status: 1,
        });
        this.companyName = res.name;
        this.companyGstNumber = res.gst_no;
        this.companyMobileNumber = res.mobile_no;
        this.companyFullName = res.users[0].name;
        this.$root.$emit("shilp-breadcrumbs-label", this.routeQueryKycStatus == "approve" ? "approved-companies-statistics" : "company-statistics", `${res.name} (${res.mobile_no})`);
        return res;
      } catch {
        this.$shilp.notify({
          title: "Api Request Failed. Try Again after some time.",
          type: "danger",
        });
      } finally {
        this.isCompanyLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-details,
.total-amount-spent-wrap {
  @apply grid grid-cols-2 xl:grid-cols-4 gap-3 2xl:w-3/4;
  .field {
    @apply border-2 bg-white pt-4 pb-2 px-5;
  }
}

.credit-field-wrap {
  @apply flex justify-between items-center;
}

.field {
  @apply border-2 pt-4 pb-2 px-5 rounded-md;
  &__content {
    display: block !important;
  }
  &-block {
    background-color: white !important;
    border: none !important;
    @apply font-bold p-0;
  }
}

.field-block-red {
  color: red;
}

.field-block-green {
  @apply text-success-700;
}

.tag {
  @apply mt-2;
}
</style>
