<template>
  <div class="footer-container">
    <s-button class="footer-button" size="lg" color="danger" theme="outline" label="Clear" icon="IconIcRoundClose" @click.native="$emit('resetFilters')" />
    <!-- <s-button class="footer-button" size="lg" color="info" theme="outline" label="Apply" @click.native="$emit('applyBtn')" /> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer-container {
  @apply p-4;

  .footer-button {
    @apply font-semibold;
  }
}
</style>
