<template>
  <div>
    <portal to="header-actions">
      <div class="header-actions">
        <AddNewCompanyBtn v-if="isCompanyCreateGranted" />
      </div>
    </portal>
    <router-view />
  </div>
</template>

<script>
import AddNewCompanyBtn from "@/components/company/buttons/AddNewCompany.vue";
import roleMixin from "@/mixins/role";
export default {
  mixins: [roleMixin],
  components: {
    AddNewCompanyBtn,
  },
};
</script>

<style lang="scss" scoped></style>
