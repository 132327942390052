import $axios from "@/plugins/axios";

export const getAllInquiries = (params = null) => {
  return $axios.get(`inquiries`, { params: params }).then((res) => {
    return res.data;
  });
};

export const categoryWiseInquiryCount = (params = null) => {
  return $axios.get(`inquiries/count/all`, { params: params }).then((res) => {
    return res.data;
  });
};

export const get = (id, params = null) => {
  return $axios.get(`inquiries/${id}`, { params }).then((res) => {
    return res.data;
  });
};

export const update = (id, params = null) => {
  return $axios.put(`inquiries/${id}`, params).then((res) => {
    return res.data;
  });
};

export const exportExcel = (params = null) => {
  return $axios.get("inquiryExport", { params: { ...params, limit: -1 } }).then((res) => {
    return res.data;
  });
};

export const getInquiryComments = (params = null) => {
  return $axios.get(`inquiryComments`, { params }).then((res) => {
    return res.data;
  });
};

export const createInquiryComments = (params = null) => {
  return $axios.post(`inquiryComments`, params).then((res) => {
    return res.data;
  });
};
