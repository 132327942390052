<template>
  <div>
    <!-- :save="save" -->
    <item-form :fields="fields" class="p-4 card-wrap" width="400px" ref="item" :get="getItem" :item="id" :form-state.sync="formState" :save="saveItem" @reset="resetForm">
      <template #default="{ form }">
        <!-- Product Name -->
        <s-field-validate rules="required" name="Product Name" label="Product Name">
          <s-textbox v-model="form.name" type="text" placeholder="Enter Product Name" />
        </s-field-validate>

        <!-- Category  -->
        <s-field-validate label="Category" rules="required" name="Category">
          <v-select
            :options="categoryOptions"
            @input="onCategorySelected(form.category_id)"
            :disabled="isItemAdded ? false : true"
            placeholder="Select"
            :reduce="(category) => category.value"
            v-model="form.category_id"
          />
        </s-field-validate>

        <!-- Brands -->
        <s-field-validate label="Brand" rules="required" name="Brand">
          <v-select :options="brandOptions" placeholder="Select" label="name" :loading="isBrandLoading" :disabled="isItemAdded ? false : true" :reduce="(brand) => brand.id" v-model="form.brand_id" />
        </s-field-validate>

        <!-- Attributes  -->
        <!-- below !flex-nowrap class has been given important modifier because
        by default flex class is applying wrap class -->

        <CementAttributes
          rules="required"
          v-if="form.category_id == 1"
          class="flex !flex-nowrap gap-4"
          :disabled="isItemAdded ? false : true"
          :gradeId="isItemAdded ? null : form.attribute.find((item) => item.attribute_id == 1).attribute_option_id"
          :packingId="isItemAdded ? null : form.attribute.find((item) => item.attribute_id == 5).attribute_option_id"
          @packing-selected="
            localAttributeFields.selectedPackingId = $event;
            localAttributeFields.selectedPackingAttributeId = 5;
          "
          @grade-selected="
            localAttributeFields.selectedGradeId = $event;
            localAttributeFields.selectedGradeAttributeId = 1;
          "
        />

        <!-- TMT -->

        <TmtBarAttributes
          rules="required"
          v-if="form.category_id == 2"
          class="flex !flex-nowrap gap-4"
          :disabled="isItemAdded ? false : true"
          :gradeId="isItemAdded ? null : form.attribute.find((item) => item.attribute_id == 2).attribute_option_id"
          :sizeId="isItemAdded ? null : form.attribute.find((item) => item.attribute_id == 4).attribute_option_id"
          @size-selected="
            localAttributeFields.selectedSizeId = $event;
            localAttributeFields.selectedSizeAttributeId = 4;
          "
          @grade-selected="
            localAttributeFields.selectedGradeId = $event;
            localAttributeFields.selectedGradeAttributeId = 2;
          "
        />

        <!-- Aac blocks -->

        <AacBlocksAttributes
          rules="required"
          v-if="form.category_id == 3"
          class="flex !flex-nowrap gap-4"
          :disabled="isItemAdded ? false : true"
          :gradeId="isItemAdded ? null : form.attribute.find((item) => item.attribute_id == 6).attribute_option_id"
          :sizeId="isItemAdded ? null : form.attribute.find((item) => item.attribute_id == 3).attribute_option_id"
          @size-selected="
            localAttributeFields.selectedSizeId = $event;
            localAttributeFields.selectedSizeAttributeId = 3;
          "
          @grade-selected="
            localAttributeFields.selectedGradeId = $event;
            localAttributeFields.selectedGradeAttributeId = 6;
          "
        />

        <!-- Price -->
        <div class="flex !flex-nowrap gap-4">
          <s-field-validate rules="required|positiveFloat" name="Price" label="Price" after="₹">
            <s-textbox v-model="form.price" type="number" placeholder="Enter Price" />
          </s-field-validate>

          <!-- Unit -->
          <s-field-validate rules="required" name="Unit" label="Unit">
            <v-select :reduce="(item) => item.value" :options="unitOptions" v-model="form.unit" placeholder="Select Unit" />
          </s-field-validate>
        </div>

        <!-- Out of Stock -->
        <s-field inline label="Out of Stock" desc="Turning this on will show the product as 'out of stock' even if the stock is available.">
          <s-switch id="outOfStock" size="xs" shape="pill" color="danger" v-model="form.out_of_stock" />
        </s-field>

        <!-- Status -->
        <status-input v-model="form.status" />
      </template>
    </item-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { create, update, get } from "@/api/product";
import { getCategories } from "@/api/category";
import { cloneDeep } from "lodash-es";
import { getBrands } from "@/api/brand";
export default {
  unitOptionsData: {
    cement: {
      packingType: {
        bag: [
          {
            label: "Bag",
            value: "Bag",
          },
        ],
        loose: [
          {
            label: "MT",
            value: "MT",
          },
        ],
      },
    },
    tmtBar: [
      {
        label: "MT",
        value: "MT",
      },
    ],
    aacBlocks: [
      {
        label: "CBM",
        value: "CBM",
      },
    ],
  },

  localAttributeFieldsPrototype: {
    selectedGradeId: null,
    selectedPackingId: null,
    selectedSizeId: null,
    selectedGradeAttributeId: null,
    selectedPackingAttributeId: null,
    selectedSizeAttributeId: null,
  },

  components: {
    // ProductAttributeDropdown: require("@/components/ProductAttributeDropdown.vue").default,
    CementAttributes: require("@/components/products/CementAttributes.vue").default,
    TmtBarAttributes: require("@/components/products/TmtBarAttributes.vue").default,
    AacBlocksAttributes: require("@/components/products/AacBlocksAttributes.vue").default,
  },
  props: ["id"],
  created() {
    this.getAttributes();
    this.getBrandOptions();
  },
  data() {
    return {
      attributes: [],
      attributeItem: [],
      formState: null,
      localAttributeFields: cloneDeep(this.$options.localAttributeFieldsPrototype),
      brandOptions: [],
      isBrandLoading: false,
    };
  },
  computed: {
    ...mapGetters(["categoryOptions"]),
    unitOptions() {
      if (this.formState.category_id == 1) {
        if (this.localAttributeFields.selectedPackingId == 16) return this.$options.unitOptionsData.cement.packingType.bag;
        else if (this.localAttributeFields.selectedPackingId == 17) return this.$options.unitOptionsData.cement.packingType.loose;
        else return [];
      } else if (this.formState.category_id == 2) return this.$options.unitOptionsData.tmtBar;
      else if (this.formState.category_id == 3) return this.$options.unitOptionsData.aacBlocks;
      else return [];
    },
    fields() {
      return [
        "name",
        "category_id",
        "brand_id",
        { name: "out_of_stock", value: false },
        "price",
        { name: "status", value: "active" },
        "unit",
        {
          name: "attribute",
          value: [],
        },
      ];
    },
    isItemAdded() {
      if (this.id == "+") return true;
      else return false;
    },
  },

  methods: {
    getBrandOptions() {
      // filter: {
      //   category_id: categoryId,
      // },
      this.isBrandLoading = true;
      getBrands({
        limit: -1,
      }).then((res) => {
        this.brandOptions = res.data;
        this.isBrandLoading = false;
      });
    },
    resetForm() {
      // this method is created to solve problem of attributes not getting reseted when reset button is clicked.
      this.attributeItem = [];
    },

    clearAttributeFields() {
      this.localAttributeFields = cloneDeep(this.$options.localAttributeFieldsPrototype);
    },

    onCategorySelected() {
      if (this.id == "+") this.formState.unit = null;
      // this.getBrandOptions(id);
      if (this.formState && this.formState.brand_id) {
        this.formState.brand_id = null;
      }

      this.clearAttributeFields();
    },

    async getAttributes() {
      const res = await getCategories({
        limit: -1,
        include: "attribute.attributeOption",
      });
      this.attributes = res.data;
    },
    async saveItem(id, form) {
      let res;
      if (id) {
        // form["_method"] = "PUT";
        res = await update(id, form);
        return res;
      } else {
        if (this.localAttributeFields.selectedGradeId) {
          form.attribute.push({
            attribute_id: this.localAttributeFields.selectedGradeAttributeId,
            attribute_option_id: this.localAttributeFields.selectedGradeId,
          });
        }

        if (this.localAttributeFields.selectedPackingId) {
          form.attribute.push({
            attribute_id: this.localAttributeFields.selectedPackingAttributeId,
            attribute_option_id: this.localAttributeFields.selectedPackingId,
          });
        }

        if (this.localAttributeFields.selectedSizeId) {
          form.attribute.push({
            attribute_id: this.localAttributeFields.selectedSizeAttributeId,
            attribute_option_id: this.localAttributeFields.selectedSizeId,
          });
        }
        res = await create(form);
        return res;
      }
    },
    getItem() {
      return get(this.id).then((res) => {
        this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, res.name);
        if (res.categories && res.categories.length) {
          const categoryId = res.categories[0].id;
          res["category_id"] = categoryId;

          let attribute = [];
          res.attributeOptions.forEach((item) => {
            attribute.push({
              attribute_id: item.attribute.id,
              attribute_option_id: item.id,
            });
          });

          res["attribute"] = attribute;
          // this.setAttributes(categoryId, res.attributeOptions);
        }

        return res;
      });
    },
  },
};
</script>

<style scoped>
.vs__selected-options {
  flex-wrap: nowrap !important;
}
</style>
