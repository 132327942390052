<template>
  <div class="rounded-lg shadow-lg">
    <div class="text-gray-500 text-base font-normal">Company Name</div>
    <div class="text-gray-800 font-medium">
      {{ company.name }}
    </div>

    <div class="text-gray-500 text-base font-normal mt-2">Project Name</div>

    <div class="text-gray-800 font-medium">
      {{ projectName }}
    </div>

    <div class="text-gray-500 text-base font-normal mt-2">Delivery Address</div>

    <div class="text-gray-800 font-medium">
      {{ address }}
    </div>

    <!-- Contact person name and number -->
    <template v-if="deliveryIncharge.length">
      <div class="text-gray-500 text-base font-normal mt-2">Contact Person</div>

      <div v-for="(inCharge, index) in deliveryIncharge" :key="index">
        <p class="contact-label">
          Name: <span class="contact-number">{{ inCharge.name }}</span>
        </p>
        <p class="contact-label">
          Mobile Number: <span class="contact-number">{{ inCharge.mobile_no }}</span>
        </p>
      </div>
    </template>

    <s-button class="mt-2" label="Company Profile" color="secondary" @click.native="goToProfile" />
  </div>
</template>

<script>
import companyMixin from "@/mixins/company";
export default {
  props: ["address", "deliveryIncharge", "projectName", "company"],
  mixins: [companyMixin],
  methods: {
    goToProfile() {
      if (this.company.kyc_status == "approve") this.goToApprovedCompanyProfile(this.company.id, this.company.kyc_status);
      else this.goToNotApprovedCompanyProfile(this.company.id, this.company.kyc_status);
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-label {
  @apply text-gray-500 font-normal;
}

.contact-number {
  @apply font-bold text-black;
}
</style>
