import Vue from "vue";
import VueRouter from "vue-router";
import privateRoutes from "./private";
import { scrollBehavior } from "@/directives/scrollIntoView";
Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "hydrate",
    redirect: { name: "dashboard" },
    component: require("@/views/private/Hydrate").default,
    meta: {
      label: "Hydrate",
      public: false,
    },
    children: privateRoutes,
  },
  {
    path: "/login",
    name: "login",
    component: require("@/views/Login").default,
    meta: {
      breadcrumbs: ["login"],
      public: true,
    },
  },
];
const router = new VueRouter({
  routes,
  scrollBehavior,
});

// router before each method intercepts request before navigating user to url
// to is the destination url
// from is the source url
// next is the method for custom redirection
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("bit-admin-token");
  if (to.meta.public === true) {
    if (token) next({ name: "hydrate" });
    else next();
  } else {
    if (token) {
      next();
    } else {
      next({ name: "login" });
    }
  }
});

export default router;
