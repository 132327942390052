<template>
  <div class="py-2 px-4 bg-white rounded-lg" :class="{ 'hover:shadow-lg hover:cursor-pointer': isClickable }">
    <div class="flex flex--fit gap-3 flex--middle flex-nowrap">
      <s-button :icon="icon" theme="muted" :color="color" shape="circle" size="sm" />
      <p :class="`text-md font-bold text-${color} `" v-if="count">{{ count }}</p>
      <p :class="`text-md font-bold text-${color} `" v-else-if="amount">{{ $options.formatPrice(amount) }}</p>
      <p :class="`text-md font-bold text-${color} `" v-else>0</p>
      <InformationButton v-tooltip="description" v-if="description"></InformationButton>
    </div>
    <!-- <div></div> -->
    <div class="text-base">
      {{ label }}<span v-if="subLabel" class="text-xs text-grey"> {{ subLabel }}</span>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/utilities/common";
import InformationButton from "../InformationButton.vue";

export default {
  formatPrice,
  props: ["icon", "color", "count", "label", "amount", "subLabel", "isClickable", "description"],
  components: { InformationButton },
};
</script>

<style lang="scss" scoped></style>
