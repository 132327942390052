<template>
  <div>
    <div>
      <div class="mt-2 bg-white rounded-md p-2 mb-2 flex flex--fit gap-8" v-shilp-loader.overlay="isVendorLoading" v-if="module == 'assigned'">
        <s-field label="Vendors" class="w-4/12">
          <v-select :options="vendorsOptions" label="name" :reduce="(vendor) => vendor.id" v-model="filters.user_id" @input="onVendorChange(filters.user_id)" placeholder="Select" :clearable="true">
            <template #footer v-if="vendorAddress">
              <p class="text-grey font-medium text-sm line-clamp-2 mt-2" v-tooltip="vendorAddress">
                {{ vendorAddress }}
              </p>
            </template>
          </v-select>
        </s-field>
        <CompanyGst v-if="vendorGSTNumber" :gstNumber="vendorGSTNumber" />

        <CompanyMobile v-if="vendorMobileNumber" :number="vendorMobileNumber" />
      </div>

      <div class="mt-2 bg-white rounded-md p-2 mb-2 flex flex--fit gap-8" v-shilp-loader.overlay="isCompanyLoading">
        <s-field label="Buyers" class="w-4/12">
          <v-select
            :options="companyOptions"
            label="name"
            :reduce="(company) => company.id"
            v-model="filters.company_id"
            @input="setCompanyFilter(filters.company_id)"
            placeholder="Select"
            :clearable="true"
          >
            <template #footer v-if="companyAddress">
              <p class="text-grey font-medium text-sm line-clamp-2 mt-2" v-tooltip="companyAddress">
                {{ companyAddress }}
              </p>
            </template>
          </v-select>
        </s-field>
        <CompanyGst v-if="companyGstNumber" :gstNumber="companyGstNumber" />

        <CompanyMobile v-if="companyMobileNumber" :number="companyMobileNumber" />
      </div>

      <OrderWidget
        class="mb-2"
        @selected="onOrderWidgetSelect"
        :activeId="$route.query.order_status_id"
        :vendor-filters="filters"
        :vendor-id="filters.user_id"
        v-if="module == 'assigned'"
        module="vendor"
      />

      <FilterBar @resetFilters="resetFilters">
        <s-field label="Date" v-if="module != 'unassigned'">
          <date-picker v-model="filters.date_range" range placeholder="Select" valueType="DD-MM-YYYY" format="DD-MM-YYYY" />
        </s-field>
        <s-field label="Categories" class="mt-2">
          <v-select
            :options="categoryOptions"
            label="name"
            :reduce="(category) => category.id"
            v-model="filters.category_id"
            placeholder="Select"
            :clearable="false"
            @input="categorySelected(filters)"
          />
        </s-field>
        <s-field label="Brand" v-shilp-loader.overlay="isBrandsLoading" class="mt-2">
          <BrandSelect :multiple="true" v-model="filters.brand_id" :params="filters.category_id" @input="setBrandId($event)" />
        </s-field>
        <s-field label="Bid Status" class="mt-2" v-if="module != 'unassigned'">
          <v-select :options="bidOptions" label="name" :reduce="(bid) => bid.id" v-model="filters.bid_status" placeholder="Select" :clearable="false">
            <template v-slot:option="bidOptions"> <StatusTag :label="bidOptions.name" :name="bidOptions.name.toLowerCase()" /> </template>
          </v-select>
        </s-field>
      </FilterBar>

      <sp-list ref="list" class="h-100" :filters="filters" :perPage="params.limit" :params="params" :attrs="columns" endpoint="vendor/orders">
        <template #empty>
          <list-empty />
        </template>
        <template #header="{ refresh }">
          <div class="mb-4 flex flex--fit !justify-end w-full">
            <SearchFilter :value="filters.search" @search="filters.search = $event" class="mr-2" />
            <div>
              <s-button class="filter mr-2" icon="vmdi-filter" shape="circle" @click.native="$root.$emit('filter-bar-open')" />
              <s-button class="refresh" icon="vmdi-refresh" shape="circle" @click.native="refresh()" />
            </div>
          </div>
        </template>
        <sp-list-table @rowClick="$router.push({ name: actionRoute, params: { id: $event.id, order_no: $event.order_no } })">
          <template #total="{ item }">
            {{ formatPrice(item.total + item.freight + item.freight_gst_price + item.loading + item.loading_gst_price + item.unloading + item.unloading_gst_price) }}
          </template>

          <template #vendor_bids_count="{ item }">
            <a @click="$router.push({ name: 'vendor-bids-list', params: { id: item.id } })" class="underline underline-offset-1 cursor-pointer text-blue-500">
              Eligible Vendors : {{ item.vendor_bids_count }} <br />
              Bidders : {{ item.vendor_bid_submit_count }}</a
            >
          </template>

          <template #category_id="{ item }">
            <p>{{ item.brand.name }}</p>
            <p class="text-gray-500">{{ item.category.name }}</p>
          </template>

          <template #qty="{ item }">
            <p>{{ item.qty }} {{ item.unit_name }}</p>
          </template>

          <template #buyerName="{ item }">
            {{ item.buyerName[0] }}
          </template>

          <template #project_name="{ item }">
            <p>{{ item.project_name }}</p>
            <p class="text-gray-500">{{ item.area_name }}, {{ item.city_name }}</p>
          </template>

          <template #user="{ item }">
            {{ (item.user && item.user.name) || "" }}
          </template>

          
          <template #created_at="{ item }" v-if="module == 'unassigned'">
            {{ formatDate(item.created_at) }}
          </template>
          <template #created_at="{ item }" v-else>
            {{ formatDate(item.assigned_date) }}
          </template>
          <template #vendor_order_status_id="{ item }">
            <StatusTag :label="item.orderStatus.display_name" class="status" :name="item.orderStatus.name" />
          </template>
          <template #action="{ item }">
            <a
              class="bg-blue-100 w-4 h-4 flex justify-center content-center rounded-full cursor-pointer"
              @click="$router.push({ name: 'vendor-bids-list', params: { id: item.id, order_no: item.order_no } })"
            >
              <s-icon name="IconMdiPencilOutline" class="w-3 text-blue-500" />
            </a>
          </template>

          <template #assign_action="{ item }">
            <div class="flex flex--fit flex--nowrap">
              <s-button
                title="UnAssign"
                icon="IconMdiPencilOutline"
                size="sm"
                color="info"
                theme="muted"
                shape="circle"
                class="mr-2"
                @click.native="$router.push({ name: 'vendor-order-details', params: { id: item.id, order_no: item.order_no } })"
              />
              <s-button
                title="Re-Assign"
                label="Re-Assign"
                theme="muted"
                size="sm"
                class="button--grey"
                :disabled="item.orderStatus.name == 'delivered'"
                @click.native="$router.push({ name: 'vendor-bids-list', params: { id: item.id, order_no: item.order_no } })"
              />
            </div>
          </template>
        </sp-list-table>
      </sp-list>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import BrandSelect from "@/components/brand/BrandSelect.vue";
import { cloneDeep } from "lodash-es";
import { formatPrice } from "@/utilities/common";
import { getAll, getBidStatus } from "@/api/vendor/vendor.js";
import StatusTag from "@/components/StatusTag.vue";
import { formatDate } from "@/utilities/common";
import { get } from "@/api/vendor/vendor.js";
import { get as getCompany, getAllCompanies } from "@/api/company.js";
export default {
  props: {
    columns: Array,
    actionRoute: String,
    params: Object,
    module: String,
  },
  data() {
    return {
      defautFilter: {
        search: null,
        date_range: null,
        category_id: null,
        brand_id: null,
        user_id: null,
        vendor_order_status_id: null,
        company_id: null,
        is_assign: this.module == "unassigned" ? "no" : "yes",
        bid_status: null,
      },
      filters: {},
      companyOptions: [],
      categoryOptions: [],
      bidOptions: [],
      isBrandsLoading: false,
      vendorsOptions: [],
      bidRecievedOptions: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
      vendorAddress: null,
      vendorGSTNumber: null,
      vendorMobileNumber: null,
      isVendorLoading: false,
      isCompanyLoading: false,
      companyMobileNumber: null,
      companyGstNumber: null,
      companyAddress: null,
    };
  },
  components: {
    ListEmpty: require("@/components/ListEmpty.vue").default,
    SearchFilter: require("@/components/SearchFilter.vue").default,
    FilterBar: require("@/components/FilterBar").default,
    BrandSelect,
    OrderWidget: require("@/components/order/Widget.vue").default,
    CompanyMobile: require("@/components/company/Mobile.vue").default,
    CompanyGst: require("@/components/company/Gst").default,
    StatusTag,
  },
  computed: {
    ...mapGetters({
      categoryOption: "categoryOptions",
      brandOptions: "brandOptions",
    }),
    ...mapMutations({
      setBrandId: "order/setBrandId",
    }),
  },
  created() {
    this.filters = cloneDeep(this.defautFilter);
  },
  mounted() {
    this.modifyCategoryOption();
    this.getAllVendors();
    this.getAllBidstatus();
    this.getAllCompanyOptions();
    this.setProjectOptions();
  },
  methods: {
    formatPrice,
    formatDate,
    modifyCategoryOption() {
      this.categoryOption.map((res) => {
        this.categoryOptions.push({
          id: res.value,
          name: res.label,
        });
      });
    },
    getAllVendors() {
      getAll({ "filter[role]": "vendor", limit: -1 }).then((res) => {
        this.vendorsOptions = res.data;
      });
    },
    getAllBidstatus() {
      getBidStatus({ limit: -1 }).then((res) => {
        this.bidOptions = res.data;
      });
    },
    resetFilters() {
      this.filters = cloneDeep(this.defautFilter);
    },
    categorySelected(filters) {
      filters.brand_id = null;
    },
    onOrderWidgetSelect(id) {
      this.filters["vendor_order_status_id"] = id;
    },
    onVendorChange(id) {
      this.getVendorDetail(id);
    },
    setCompanyFilter(id) {
      this.filters["company_id"] = id;
      this.setProjectOptions();
    },
    resetCompanyState() {
      this.filters.company_id = null;
      this.setProjectOptions();
    },
    getVendorDetail(id) {
      this.isVendorLoading = true;
      get(id)
        .then((res) => {
          this.vendorAddress = res.vendorDetail ? res.vendorDetail.vendor_office_address : "";
          this.vendorGSTNumber = res.vendorDetail ? res.vendorDetail.vendor_gst_no : "";
          this.vendorMobileNumber = res.mobile_no || "";
          this.isVendorLoading = false;
        })
        .catch((err) => {
          this.vendorAddress = "";
          this.vendorGSTNumber = "";
          this.vendorMobileNumber = "";
          this.isVendorLoading = false;
          console.log(err);
        });
    },

    async getAllCompanyOptions() {
      this.isLoading = true;
      const params = {
        limit: -1,
        include: "projects",
      };
      let companies = await getAllCompanies(params);
      this.companyOptions = companies.data;
      if (this.filters.company_id) this.setAddressFromOptions();

      this.isLoading = false;
    },

    setAddressFromOptions() {
      const company = this.companyOptions.find((company) => {
        return company.id == this.filters.company_id;
      });

      if (company) this.companyAddress = company.billing_address;
    },

    setProjectOptions() {
      if (this.filters.company_id) {
        this.isCompanyLoading = true;
        getCompany(this.filters.company_id, {
          limit: -1,
          include: "projects",
        })
          .then((res) => {
            this.companyMobileNumber = res.mobile_no;
            this.companyGstNumber = res.gst_no;
            this.isCompanyLoading = false;
            this.setAddressFromOptions();
          })
          .catch(() => {
            this.isCompanyLoading = false;
          });
      } else {
        this.companyMobileNumber = null;
        this.companyGstNumber = null;
        this.companyAddress = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  font-size: 8px;
}

::v-deep .vs__dropdown-option {
  margin: 9px;
}
</style>
