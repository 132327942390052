<template>
  <div class="tag" :class="`tag--${color} tag--${size}`">
    <span class="tag__emoji">{{ emoji }}</span>
    <span class="tag__label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    emoji: { type: String, default: null },
    label: { type: String, default: null },
    size: { type: String, default: "md" },
  },

  computed: {
    // REFACTOR Update Labels instead of showing just keys.
    color() {
      let color = "orange";
      switch (this.label) {
        case "pending":
          color = "orange";
          break;
        case "incomplete":
          color = "red";
          break;
        case "approve":
          color = "green";
          break;
        case "inreview":
          color = "blue";
          break;
        case "reject":
          color = "red";
          break;
        default:
          color = "blue";
      }

      return color;
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  background: var(--light);
  color: var(--dark);
  @apply h-5 inline-flex px-2 items-center rounded-full align-top;
}

.tag--sm {
  @apply p-3;
}

.tag--md {
  @apply p-4;
}

.tag--lg {
  @apply p-5;
}

.tag__emoji {
  @apply text-xs leading-none mr-1;
}

.tag__label {
  @apply font-medium text-xs capitalize;
}

.tag--orange {
  --light: #fff3e0;
  --dark: #fb8c00;
}
.tag--amber {
  --light: #fff8e1;
  --dark: #ffa000;
}
.tag--lime {
  --light: #f9fbe7;
  --dark: #afb42b;
}
.tag--green {
  --light: #e8f5e9;
  --dark: #388e3c;
}
.tag--cyan {
  --light: #e0f7fa;
  --dark: #0097a7;
}
.tag--blue {
  --light: #e3f2fd;
  --dark: #1976d2;
}

.tag--indigo {
  --light: #e8eaf6;
  --dark: #303f9f;
}

.tag--purple {
  --light: #f3e5f5;
  --dark: #7b1fa2;
}

.tag--pink {
  --light: #fce4ec;
  --dark: #c2185b;
}

.tag--red {
  --light: #ffebee;
  --dark: #d32f2f;
}

.tag--brown {
  --light: #efebe9;
  --dark: #6d4c41;
}
</style>
