<template>
  <div>
    <Tabs />

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {
    Tabs: require("@/components/products/bulkActions/Tabs.vue").default,
  },
};
</script>

<style></style>
