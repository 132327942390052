<template>
  <div class="card-wrap">
    <label for="" class="block">Available Credit</label>
    <div :class="value > $options.creditLimit ? 'text-success-700' : 'text-danger-500'" class="text-lg font-semibold">{{ formatPrice(value) }}</div>
  </div>
</template>

<script>
import { formatPrice } from "@/utilities/common";
import { creditLimit } from "@/data/index";
export default {
  creditLimit,
  props: {
    value: Number,
  },

  methods: {
    formatPrice,
  },
};
</script>

<style lang="scss" scoped>
.field {
  @apply border-2 pt-4 pb-2 px-5 rounded-md;
  &__content {
    display: block !important;
  }
  &-block {
    background-color: white !important;
    border: none !important;
    @apply font-bold p-0;
  }
}

.field-success {
  color: green;
}

.field-danger {
  color: red;
}
</style>
