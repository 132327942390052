<template>
  <modal size="sm" title="Are you sure?" id="company-unblock-modal">
    <template #default="{ data }">
      <label>Unblock {{ data.name }}</label>
      <div class="text-right">
        <s-button class="mr-2" color="danger" label="No" @click.native="$root.$emit('shilp-modal-close', 'company-unblock-modal')" />
        <s-button class="mt-8" color="primary" label="Yes" :loader="isSubmitting" @click.native="unblock(data.id)" />
      </div>
    </template>
  </modal>
</template>

<script>
import { changeStatus } from "@/api/company";

export default {
  data() {
    return {
      isSubmitting: false,
    };
  },
  methods: {
    unblock(id) {
      this.isSubmitting = true;
      changeStatus(id, "active")
        .then(() => {
          this.$root.$emit("shilp-modal-close", "company-unblock-modal");
          this.$emit("refresh");
          this.$shilp.notify({
            title: "Company Unblocked",
            type: "success",
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
