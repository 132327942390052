<template>
  <div class="w-full" v-shilp-loader.overlay="isLoading">
    <div class="wrapper border-b-2 md:border-b-0 border-gray-200">
      <div class="top-section border-b-2 pb-3 mb-3">
        <Card
          v-tooltip="isBrandListVisible ? 'Double Click For Details' : ''"
          @dblclick.native="isBrandListVisible ? brandCall() : ''"
          icon="IconMdiMicrosoftBing"
          color="primary"
          :count="brandCount"
          label="Brands"
          :isClickable="isBrandListVisible ? true : false"
        />
        <Card
          v-tooltip="isProductListVisible ? 'Double Click For Details' : ''"
          @dblclick.native="isProductListVisible ? productCall() : ''"
          icon="IconMdiPackageVariantClosed"
          color="warning"
          :count="productCount"
          label="Product SKUs"
          :isClickable="isProductListVisible ? true : false"
        />
        <Card icon="IconMdiCollage" color="success" :count="categoriesCount" label="Categories" />
        <Card
          v-tooltip="'Double Click For Details'"
          @dblclick.native="approveCompanyCall"
          icon="IconMdiCheck"
          color="success"
          :count="kycCount"
          label="Approved Companies"
          :isClickable="true"
          description="Those companies whose KYC Status is approved are known as Approved companies."
        />
        <Card
          v-tooltip="'Double Click For Details'"
          @dblclick.native="activeCompanyCall"
          icon="IconMdiClipboardCheckOutline"
          color="secondary"
          :count="activeCompanyCount"
          label="Active Companies"
          description="Those companies who have ordered since 60 days are considered as Active companies."
          :isClickable="true"
        />
      </div>

      <div class="card-wrapper border-b-2 pb-3">
        <Card v-shilp-loader.overlay="isWatching" icon="IconMdiAlignVerticalBottom" color="primary" :amount="averageOrder" label="Average Order Amount" />
        <Card v-shilp-loader.overlay="isWatching" icon="IconMdiCurrencyRupee" color="success" :amount="totalSales" label="Total Sales" />
        <Card
          v-tooltip="'Double Click For Details'"
          v-shilp-loader.overlay="isWatching"
          @dblclick.native="overDueCall"
          icon="IconMdiCreditCardClockOutline"
          color="danger"
          :count="overDueCount"
          label="Overdue Orders"
          description="Overdue orders means that payment is pending and date has passed."
          :isClickable="true"
        />
        <Card
          v-tooltip="'Double Click For Details'"
          @dblclick.native="upcomingDeliveryCall"
          icon="IconMdiTruckDeliveryOutline"
          color="success"
          :count="upcomingDeliveryCount"
          label="Upcoming Delivery"
          description="These count indicates about no of orders whose delivery is there within 5 days from today."
          subLabel="(in 5 Days)"
          :isClickable="true"
        />

        <Card
          v-tooltip="'Double Click For Details'"
          @dblclick.native="onOverdueDeliveryClick"
          icon="IconMdiTruckDeliveryOutline"
          color="danger"
          :count="overdueDeliveryOrderCount"
          label="Overdue Delivery"
          description="These count indicates about no of orders whose delivery date has been passed and not yet delivered."
          :isClickable="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { averageOrderSize, getCountForAllOrders, upcomingDeliveryOrderCount, overdueDeliveryOrderCount as getOverdueDeliveryOrderCount } from "@/api/order";
import { formatPrice } from "@/utilities/common";
import { allBrandCount } from "@/api/brand";
import { allCategoriesCount } from "@/api/category";
import { allProductCount } from "@/api/product";
import { getKycApproveCount, getActiveCompanyCount } from "@/api/company";
import { mapMutations } from "vuex";
import Card from "@/components/dashboard/Card.vue";
import moment from "moment";
import roleMixin from "@/mixins/role.js";

export default {
  getActiveCompanyCount,
  mixins: [roleMixin],
  data() {
    return {
      value: null,
      averageOrder: 0,
      brandCount: 0,
      productCount: 0,
      categoriesCount: 0,
      kycCount: 0,
      activeCompanyCount: 0,
      totalSales: 0,
      overDueCount: 0,
      upcomingDeliveryCount: 0,
      overdueDeliveryOrderCount: 0,
      isLoading: false,
      isWatching: false,
    };
  },
  created() {
    this.getData();

    this.$root.$on("dashboard-refresh", this.getData);
  },
  beforeDestroy() {
    this.$root.$off("dashboard-refresh", this.getData);
  },
  watch: {
    dateRange() {
      this.isWatching = true;
      this.getOverDue();
      this.getAverageOrderSize();
    },
  },
  props: ["dateRange"],
  computed: {
    params() {
      return {
        filter: {
          date_range: this.dateRange,
        },
      };
    },
  },
  methods: {
    ...mapMutations({
      setDateRange: "order/setDateRange",
      setPaymentStatusId: "order/setPaymentStatusId",
      setExpectedDeliveryDate: "order/setExpectedDeliveryDate",
      resetOrderFilters: "order/resetFilters",
      setExpectedDeliveryDateMissing: "order/setExpectedDeliveryDateMissing",
    }),

    getData() {
      this.getOverDue();
      this.getAverageOrderSize();
      this.getUpcomingDeliveryCount();
      this.$options.getActiveCompanyCount().then((res) => (this.activeCompanyCount = res.active_company_count));
      this.fetchProductBrandCategoriesCount();

      getOverdueDeliveryOrderCount().then((res) => (this.overdueDeliveryOrderCount = res));
    },

    onOverdueDeliveryClick() {
      this.setExpectedDeliveryDateMissing(1);
      this.$router.push({ name: "order-list" });
    },

    getUpcomingDeliveryCount() {
      this.isLoading = true;
      upcomingDeliveryOrderCount({
        expected_delivery_date: moment().format("DD-MM-YYYY"),
      })
        .then((res) => (this.upcomingDeliveryCount = res))
        .finally(() => (this.isLoading = false));
    },
    formatPrice,
    getOverDue() {
      getCountForAllOrders(this.params)
        .then(({ overdue_count }) => {
          this.overDueCount = overdue_count;
        })
        .finally(() => (this.isWatching = false));
    },
    getAverageOrderSize() {
      averageOrderSize(this.params)
        .then(({ average_order_size, total_amount }) => {
          this.averageOrder = average_order_size;
          this.totalSales = total_amount;
        })
        .finally(() => (this.isWatching = false));
    },
    fetchProductBrandCategoriesCount() {
      this.isLoading = true;
      allProductCount()
        .then(({ product_count }) => {
          this.productCount = product_count;
        })
        .finally(() => (this.isLoading = false));

      this.isLoading = true;
      allCategoriesCount()
        .then(({ category_count }) => {
          this.categoriesCount = category_count;
        })
        .finally(() => (this.isLoading = false));

      this.isLoading = true;
      allBrandCount()
        .then(({ brand_count }) => {
          this.brandCount = brand_count;
        })
        .finally(() => (this.isLoading = false));

      this.isLoading = true;
      getKycApproveCount()
        .then(({ kyc_count }) => {
          this.kycCount = kyc_count;
        })
        .finally(() => (this.isLoading = false));
    },
    brandCall() {
      this.$router.push({ name: "brand-listing" });
    },
    productCall() {
      this.$router.push({ name: "product-listing" });
    },
    approveCompanyCall() {
      this.$router.push({ name: "approved-companies", query: { date_range: this.params.filter.date_range } });
    },
    activeCompanyCall() {
      this.$router.push({ name: "approved-companies", query: { activeCompanyfilter: true } });
    },
    overDueCall() {
      this.setDateRange(this.params.filter.date_range);
      this.setPaymentStatusId(3);
      this.$router.push({ name: "order-list" });
    },
    upcomingDeliveryCall() {
      this.setExpectedDeliveryDate(moment().format("DD-MM-YYYY"));
      this.$router.push({ name: "order-list" });
    },
  },
  components: { Card },
};
</script>

<style lang="scss" scoped>
.top-section {
  @apply grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-3;
}
.card-wrapper {
  @apply grid  sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-3;
}
.card-wrap {
  @apply flex items-center hover:shadow-lg;
}
.order-amount {
  @apply block;
}
</style>
