export default {
  methods: {
    notifySizeInvalid() {
      this.$shilp.notify({
        title: "File Size Invalid!",
        message: "Please upload file with valid file size.",
        type: "danger",
      });
    },

    notifyTypeInvalid() {
      this.$shilp.notify({
        title: "File type Invalid!",
        message: "Please upload valid media type.",
        type: "danger",
      });
    },

    isMediaValid(files) {
      let isValid = true;
      for (let file of files) {
        if (file.error && file.error.type) {
          this.notifyTypeInvalid();
          isValid = false;
          break;
        } else if (file.error && file.error.size) {
          this.notifySizeInvalid();
          isValid = false;
          break;
        }
      }
      if (isValid) return true;
      else return false;
    },
  },
};
