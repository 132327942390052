<template>
  <s-field-validate :rules="rules" name="Mobile" v-bind="$attrs">
    <s-textbox type="number" :value="value" :placeholder="placeholder" @input="onInput" @keypress.native="checkInput" />
  </s-field-validate>
</template>

<script>
export default {
  props: {
    rules: {
      type: String,
      default: "required|mobileNo",
    },
    value: [Number, String],
    placeholder: {
      type: String,
      default: "Enter Mobile Number",
    },
  },
  methods: {
    onInput($event) {
      if ($event.length > 10) {
        this.$emit("input", $event.slice(0, 10));
      } else this.$emit("input", $event);
    },

    checkInput($event) {
      if ($event.target.value.length >= 10) {
        $event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
