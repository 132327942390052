<template>
  <div class="h-screen">
    <auth-layout>
      <template #default>
        <!-- Username -->
        <item-form title="Login to Admin panel" :fields="fields" :save="save" class="card-wrap" width="400px">
          <template #default="{ form }">
            <div class="space-y-2 mb-3">
              <!-- Username  -->
              <s-field-validate rules="required" name="Username" label="Username">
                <s-textbox v-model="form.username" type="text" placeholder="Enter User Name" />
              </s-field-validate>

              <!-- Password -->
              <s-field-validate rules="required" name="Password" :password-toggle="true" label="Password">
                <s-textbox v-model="form.password" type="password" placeholder="Enter Password" />
              </s-field-validate>
            </div>
          </template>

          <template #footer="{ saveItem, saving }">
            <div class="footer-actions">
              <s-button class="flex-none text-center" align="center" fluid color="primary" label="Login" :loader="saving" @click.native="saveItem" />
            </div>
          </template>
        </item-form>
      </template>
    </auth-layout>
  </div>
</template>

<script>
import { login } from "@/api/auth";
// import { mapActions } from "vuex";
import { setToken } from "@/plugins/axios";
export default {
  components: {
    AuthLayout: require("@/layout/AuthLayout").default,
  },
  data() {
    return {
      fields: ["username", "password"],
    };
  },
  methods: {
    // ...mapActions(["setUser"]),
    save(id, form) {
      return login(form).then((res) => {
        localStorage.setItem("bit-admin-token", res.token);
        // Setting Axios Authorizatio Header
        setToken(res.token);
        // this.setUser(res.user);
        this.$router.push("/");
        return res;
      });
    },
  },
};
</script>

<style></style>
