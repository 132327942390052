import $axios from "@/plugins/axios";

export const login = (data) => {
  return $axios.post(`login`, data).then((res) => {
    return res.data;
  });
};

export const logout = () => {
  return $axios.post("logout").then((res) => {
    return res.data;
  });
};
