import { SET_USER, SET_BRAND_OPTIONS, SET_CATEGORY_OPTIONS, SET_PAYMENT_STATUS_OPTIONS, SET_ORDER_STATUS_OPTIONS } from "./mutation-types";
const state = {
  user: {},
  options: {
    brandOptions: [],
    categoryOptions: [],
    paymentStatusOptions: [],
    orderStatusOptions: [],
    teamMemberOptions: [],
    inquiryStatusOptions: [],

    buyerTypeOptions: [],
    companyTypeOptions: [],
  },
  roles: [],

  companyFilterId: null,
};

const getters = {
  user: (state) => {
    return state.user || null;
  },
  brandOptions: (state) => {
    return state.options.brandOptions;
  },
  categoryOptions: (state) => {
    return state.options.categoryOptions;
  },
  paymentStatusOptions: (state) => {
    return state.options.paymentStatusOptions;
  },
  orderStatusOptions: (state) => {
    return state.options.orderStatusOptions;
  },
  teamMemberOptions: (state) => {
    return state.options.teamMemberOptions;
  },
  companyFilterId: (state) => {
    return state.companyFilterId;
  },
  inquiryStatusOptions: (state) => {
    return state.options.inquiryStatusOptions;
  },

  buyerTypeOptions: (state) => {
    return state.options.buyerTypeOptions;
  },
  companyTypeOptions: (state) => {
    return state.options.companyTypeOptions;
  },
  roles: (state) => {
    return state.roles;
  },
  getRoleOfUser: (state) => {
    return state.user && state.user.roles.length ? state.user.roles[0] : null;
  },
  getRolePermissionsOfUser: (state, getters) => {
    const result = getters.roles.filter((item) => {
      return item.name == getters.getRoleOfUser.name;
    });

    if (result && result.length) return result[0].permissions;
  },
  hasAllPermissions: (state, getters) => {
    // return getters.getRolePermissionsOfUser && getters.getRolePermissionsOfUser.length ? false : true;
    return getters.getRoleOfUser.name === 'admin' ? true : false
  },
  isPermissionGranted: (state, getters) => (permission) => {
    const result = getters.getRolePermissionsOfUser.filter((item) => {
      return item.name == permission;
    });

    if (result && result.length) return true;
    else return false;
  },
};

const mutations = {
  [SET_USER](state, payload) {
    state.user = payload;
  },
  [SET_BRAND_OPTIONS](state, payload) {
    state.options.brandOptions = payload;
  },
  [SET_CATEGORY_OPTIONS](state, payload) {
    state.options.categoryOptions = payload;
  },
  [SET_PAYMENT_STATUS_OPTIONS](state, payload) {
    state.options.paymentStatusOptions = payload;
  },
  [SET_ORDER_STATUS_OPTIONS](state, payload) {
    state.options.orderStatusOptions = payload;
  },
  setTeamMemberOptions(state, payload) {
    state.options.teamMemberOptions = payload;
  },
  setCompanyFilterId(state, payload) {
    state.companyFilterId = payload;
  },
  setInquiryStatusOptions(state, payload) {
    state.options.inquiryStatusOptions = payload;
  },
  setBuyerTypeOptions(state, payload) {
    state.options.buyerTypeOptions = payload;
  },
  setCompanyTypeOptions(state, payload) {
    state.options.companyTypeOptions = payload;
  },
  setRoleOptions(state, payload) {
    state.roles = payload;
  },
};

const actions = {
  setUser({ commit }, data) {
    commit(SET_USER, data);
  },
  setBrandOptions({ commit }, data) {
    commit(SET_BRAND_OPTIONS, data);
  },
  setCategoryOptions({ commit }, data) {
    commit(SET_CATEGORY_OPTIONS, data);
  },
  setPaymentStatusOptions({ commit }, data) {
    commit(SET_PAYMENT_STATUS_OPTIONS, data);
  },
  setOrderStatusOptions({ commit }, data) {
    commit(SET_ORDER_STATUS_OPTIONS, data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
