import $axios from "@/plugins/axios";

export const create = (payload, params) => {
  return $axios.post(`vendors`, payload, { params }).then((res) => res.data);
};

export const update = (id, payload, params = null) => {
  return $axios.put(`vendors/${id}`, payload, { params }).then((res) => res.data);
};

export const getAll = (params) => {
  return $axios.get("vendors", { params }).then((res) => res.data);
};

export const get = (id, params = null) => {
  return $axios.get(`vendors/${id}`, { params }).then((res) => res.data);
};

export const getBidStatus = (params = null) => {
  return $axios.get(`vendor/bid-statuses`, { params }).then((res) => res.data);
};

export const remove = (id, params = null) => {
  return $axios.delete(`vendors/${id}`, { params }).then((res) => res.data);
};

export const getLocations = (params) => {
  return $axios.get("states", { params }).then((res) => res.data);
};

export const getAllCities = (params) => {
  return $axios.get("cities", { params }).then((res) => res.data);
};

export const getLocationsByVendors = (id, params) => {
  return $axios.get(`vendor/${id}/cities`, { params }).then((res) => res.data);
};

export const createVendorProduct = (payload, params) => {
  return $axios.post(`vendor/products`, payload, { params }).then((res) => res.data);
};

export const assignUser = (id, payload, params = null) => {
  return $axios.put(`vendor/${id}/user-assign`, payload, { params }).then((res) => res.data);
};
