<template>
  <s-field-validate class="grow" :label="name" :rules="rules" :name="name">
    <v-select
      :resetOnOptionsChange="true"
      :clearable="clearable"
      :loading="isLoading"
      :disabled="disabled"
      :options="options"
      placeholder="Select"
      label="name"
      v-model="selectedValue"
      :reduce="(option) => option.id"
    />
  </s-field-validate>
</template>

<script>
export default {
  data() {
    return {
      selectedValue: null,
    };
  },
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
    },
    isLoading: Boolean,
    disabled: Boolean,
    name: String,
    options: {
      type: [Array],
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
  },
  created() {
    if (this.value) {
      this.selectedValue = this.value;
    }
  },
  watch: {
    selectedValue(newValue) {
      this.$emit("selected", newValue);
    },
  },
};
</script>

<style></style>
