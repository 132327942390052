export default [
    {
        path: "advance-bid",
        name: "advance-bid",
        component: require("@/views/private/vendor/advanceBid/list").default,
        meta: {
            label: "Advance Bid",
            breadcrumbs: ["dashboard", "advance-bid"],
        },
    },
];
