import $axios from "@/plugins/axios";

export const getAll = (params = null) => {
  return $axios.get(`brochures`, { params }).then((res) => {
    return res.data;
  });
};

export const get = (id, params = null) => {
  return $axios.get(`brochures/${id}`, { params }).then((res) => {
    return res.data;
  });
};

export const create = (data, params) => {
  return $axios.post(`brochures`, data, { params }).then((res) => {
    return res.data;
  });
};

export const update = (id, data, params = null) => {
  return $axios.put(`brochures/${id}`, data, { params }).then((res) => {
    return res.data;
  });
};

export const remove = (id) => {
  return $axios.delete(`brochures/${id}`).then((res) => {
    return res.data;
  });
};
