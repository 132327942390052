<template>
  <div>
    <div class="card-wrap p-2" v-if="!isLoading">
      <h4 class="text-primary-500 font-bold">Quantity Sold</h4>
      <VueApexChart width="100%" height="500" type="line" :options="options" :series="series" />
    </div>
    <div v-if="isLoading" v-shilp-loader.overlay="isLoading" class="preLoad"></div>
  </div>
</template>

<script>
import VueApexChart from "vue-apexcharts";
import { quantitySoldCategoryWise } from "@/api/order";
export default {
  props: ["dateRange"],
  components: {
    VueApexChart,
  },
  watch: {
    dateRange() {
      this.getData();
    },
  },
  data() {
    return {
      months: [],
      categories: [],
      series: [],
      isLoading: false,
    };
  },
  computed: {
    options() {
      return {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (value, { seriesIndex, w }) {
            return value + " " + w.config.series[seriesIndex].displayUnit;
          },
        },
        stroke: {
          curve: "smooth",
        },
        tooltip: {
          y: {
            formatter: function (value, { seriesIndex, w }) {
              return value + " " + w.config.series[seriesIndex].displayUnit;
            },
          },
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.months,
          title: {
            text: "Months",
          },
        },
        yaxis: {
          title: {
            text: "Quantity",
          },
          decimalsInFloat: 2,
        },
        legend: {
          position: "top",
        },
      };
    },
  },

  created() {
    this.getData();

    this.$root.$on("dashboard-refresh", this.getData);
  },
  beforeDestroy() {
    this.$root.$off("dashboard-refresh", this.getData);
  },
  methods: {
    getData() {
      this.isLoading = true;
      quantitySoldCategoryWise({
        filter: {
          date_range: this.dateRange,
        },
      })
        .then((res) => {
          if (Object.keys(res).length) {
            let categories = Object.keys(res);

            this.getMonthsArray(res[categories[0]]);

            this.getCategoriesArray(res);

            this.generateSeries(this.categories, res);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    getMonthsArray(data) {
      this.months = Object.keys(data);
    },

    getCategoriesArray(data) {
      this.categories = Object.keys(data);
    },
    getUnitName(category) {
      const result = Object.values(category).find((item) => {
        if (item.unit_name.length) {
          return item;
        } else return null;
      });

      if (result) return result.unit_name;
      else return "";
    },
    generateSeries(categories, data) {
      this.series = [];
      categories.forEach((category) => {
        this.series.push({
          name: category,
          data: Object.values(data[category]).map((item) => item.total_qty),
          displayUnit: this.getUnitName(data[category]),
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.preLoad {
  width: 100%;
  height: 500px;
}
</style>
