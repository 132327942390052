export default [
  {
    path: "buyer",
    component: require("@/views/private/buyer/buyerType/Index").default,
    meta: {
      label: "Buyer Type",
    },
    redirect: { name: "buyer-type" },
    children: [
      {
        path: "buyer-type",
        name: "buyer-type",
        component: require("@/views/private/buyer/buyerType/Item").default,
        meta: {
          label: "Buyer Type",
          icon: "IconMaterialSymbolsLayersOutlineRounded",
          breadcrumbs: ["dashboard", "buyer-type"],
        },
      },
      //   {
      //     path: "view/:id",
      //     props: true,
      //     name: "company-details",
      //     component: require("@/views/private/companies/View").default,
      //     meta: {
      //       label: "Company Details",
      //       breadcrumbs: ["dashboard", "company-listing", "company-details"],
      //     },
      //   },
    ],
  },
];
