<template>
  <modal size="sm" title="Add Invoice" id="add-invoice-modal">
    <template #default>
      <VueFileAgentWrapper
        :baseUrl="$options.baseUrl"
        :key="1"
        v-model="fileData"
        endpoint="medias"
        :linkable="true"
        :params="{ used_for: 'vendor_invoice' }"
        @uploading="isMediaUploading = $event"
        :multiple="true"
        :isDeletable="true"
      />
      <s-button color="primary" label="Upload" class="button--sm w-full mt-2" @click.native="upload" :loader="isUploading" :disabled="!fileData || isMediaUploading" />
    </template>
  </modal>
</template>
<script>
import VueFileAgentWrapper from "@/components/VueFileAgentWrapper";
import { uploadInvoice } from "@/api/vendor/order.js";
export default {
  baseUrl: process.env.VUE_APP_STORE_REST_API,
  components: {
    VueFileAgentWrapper,
  },
  data() {
    return {
      fileData: null,
      isUploading: false,
      isMediaUploading: false,
    };
  },
  methods: {
    upload() {
      this.isUploading = true;
      let postData = {
        media_id: [],
      };
      for (let file of this.fileData) {
        postData.media_id.push(file.upload.data[0].id);
      }
      uploadInvoice(this.$route.params.id, postData)
        .then(() => {
          this.isUploading = true;
          this.$shilp.notify({
            title: "Invoice uploaded.",
            type: "success",
          });
          this.$root.$emit("shilp-modal-close");
          this.$emit("refreshInvoice");
        })
        .catch((err) => {
          this.isUploading = false;
          this.$shilp.notify({
            title: err.message,
            type: "danger",
          });
        });
    },
  },
};
</script>