<template>
  <item-form class="card-wrap" breadcrumb="name" :save="save" :get="getCompany" :redirect="false" :form-state.sync="formState" :item="id" :fields="fields" width="400px" ref="item" :gap="4">
    <template #default="{ form }">
      <!-- GST NO  -->

      <div v-if="gstError" class="p-4 radius-3 bg-danger-lightest mb-3">
        <h5 class="text-danger">
          {{ gstError }}
        </h5>
      </div>
      <div class="mb-2">
        <s-field-validate name="GSTIN Number" rules="required|gst">
          <UpperCaseTextBoxInput :isDisabled="isDisabled" type="text" :maxLength="15" v-model="form.gst_no" @input="form.gst_no.length == 15 && getGstData(form.gst_no)" placeholder="GSTIN Number" />
        </s-field-validate>

        <label v-if="companyName" class="rounded-sm font-medium font-display block bg-grey-lightest-double-ultra text-md"> {{ companyName }}</label>
      </div>

      <div>
        <s-field v-if="isDisabled" label="GST Certificate">
          <VueFileAgentWrapper :readonly="true" description="Upload Image or PDF Document" accept=".jpg,.png,.jpeg,application/pdf" :value="form.gstcertificate" :linkable="true" />
        </s-field>
        <s-field-validate v-else rules="required" v-model="form.gst_certificate" name="GST Certificate" label="GST Certificate">
          <VueFileAgentWrapper description="Upload Image or PDF Document" accept=".jpg,.png,.jpeg,application/pdf" v-model="form.gst_certificate" :linkable="true" />
        </s-field-validate>
      </div>

      <!-- Contact Person Name  -->
      <s-field-validate name="Name" rules="required">
        <TextBoxCapitalize placeholder="Contact Person Name" v-model="form.name" />
      </s-field-validate>
      <!-- Mobile No  -->

      <CustomMobileInput v-model="form.mobile_no" />
      <!-- Email  -->
      <s-field-validate name="Email" rules="email">
        <s-textbox v-model="form.email" type="text" placeholder="Email" />
      </s-field-validate>

      <div>
        <label class="text-gray-500 text-sm mr-1">Activate this button to enable notifications</label>
        <s-switch id="is-send-notification" :on-value="true" :off-value="false" shape="pill" color="primary" v-model="form.is_send_notification" size="xs" />
      </div>

      <!--  User Name -->
    </template>

    <template #footer="{ saving, validate }">
      <div class="shrink">
        <s-button color="primary" v-if="id == '+' ? true : false" :disabled="saving || !gstNoValid || validate.invalid" label="Submit" type="submit" :loader="saving" />
        <s-button color="primary" v-else label="Submit" type="submit" :loader="saving" />
      </div>
    </template>
  </item-form>
</template>

<script>
import UpperCaseTextBoxInput from "@/components/form/inputs/UpperCaseTextBoxInput.vue";
import CustomMobileInput from "@/components/CustomMobileInput.vue";
import TextBoxCapitalize from "@/components/form/inputs/TextBoxCapitalize.vue";
// import gql from "graphql-tag";
import VueFileAgentWrapper from "@/components/VueFileAgentWrapper.vue";
import { getCompanyFromGstNo, createCompany, editCompany, get } from "@/api/company";
import { cloneDeep } from "lodash-es";

export default {
  baseUrl: process.env.VUE_APP_STORE_REST_API,
  components: {
    UpperCaseTextBoxInput,
    CustomMobileInput,
    TextBoxCapitalize,
    VueFileAgentWrapper,
  },
  props: {
    id: {
      type: String,
    },
  },

  data() {
    return {
      gstError: "",
      companyName: "",
      gstNoValid: false,
      formState: {},
    };
  },
  computed: {
    isDisabled() {
      return this.id == "+" ? false : true;
    },
    fields() {
      return [
        {
          name: "gst_no",
          value: "",
        },
        {
          name: "name",
          value: "",
        },
        {
          name: "mobile_no",
          value: "",
        },
        {
          name: "email",
          value: "",
        },
        {
          name: this.id == "+" ? "gst_certificate" : "gstcertificate",
        },
        {
          name: "is_send_notification",
          value: false,
        },
      ];
    },
  },
  methods: {
    getCompany(id) {
      return get(id, { include: "users" }).then((res) => {
        res.name = res.users[0].name;
        return res;
      });
    },
    getGstData(gst) {
      getCompanyFromGstNo(gst)
        .then((res) => {
          this.companyName = res.name;
          this.gstNoValid = true;
          this.gstError = "";
        })

        .catch((error) => {
          this.companyName = "";
          this.gstNoValid = false;
          const title = error.data.errors ? Object.values(error.data.errors)[0] : error.data.message;

          this.gstError = Array.isArray(title) ? title[0] : title;
          this.$shilp.notify({
            title: Array.isArray(title) ? title[0] : title,
            type: "danger",
          });
        });
    },

    save(id, data) {
      if (id) {
        const form = {
          name: data.name,
          mobile_no: data.mobile_no,
          email: data.email,
          is_send_notification: data.is_send_notification,
        };
        return editCompany(id, form).then((res) => {
          this.$router.push({
            name: "active-company",
          });
          return res;
        });
      }
      let form = cloneDeep(this.formState);
      form.gst_certificate = form.gst_certificate[0].file;
      let formData = new FormData();
      for (let key in form) {
        if (key == "is_send_notification") {
          formData.append("booleanValue", form["is_send_notification"]);
        } else {
          formData.append(key, form[key]);
        }
      }
      return createCompany(formData).then((res) => {
        this.$router.push({
          name: "active-company",
        });
        return res;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
