<template>
  <div v-shilp-loader.overlay="isLoading">
    <ValidationObserver #default="{ invalid }">
      <SFieldValidate :rules="`required|${ruleType}`" name="Premium" class="premium-field" label="Enter Premium (Applicable on unit price)">
        <STextbox v-model="premium" type="number" :placeholder="`Enter ${selectedType}`" :disabled="orderStatus.id === $options.orderStatusData.cancelled" />

        <template #after>
          <SRadioButton
            activeColor="secondary"
            inactiveColor="secondary"
            class="flex flex--fit flex--nowrap g-2"
            v-model="selectedType"
            name="premium_type"
            :options="[
              // {
              //   label: '%',
              //   value: 'percentage',
              // },
              {
                label: '₹',
                value: 'amount',
              },
            ]"
          />
        </template>
      </SFieldValidate>
      <s-button class="mt-2" label="Apply" color="secondary" @click.native="submit" :disabled="invalid || disabled" :loading="isLoading" />
    </ValidationObserver>
  </div>
</template>

<script>
import { changeOrderOrPaymentStatus } from "@/api/order";
import orderStatusData from "@/data/orderStatus";

export default {
  orderStatusData,
  props: ["orderId", "disabled", "orderStatus"],
  data() {
    return {
      selectedType: "amount",
      premium: null,
      isLoading: false,
    };
  },

  computed: {
    ruleType() {
      return this.selectedType == "percentage" ? "min_value:0|max_value:100" : "positiveFloat";
    },
  },

  methods: {
    submit() {
      this.isLoading = true;
      changeOrderOrPaymentStatus(this.orderId, {
        premium_type: this.selectedType,
        premium_amount: this.premium,
        order_price_type: "premium",
      })
        .then(() => {
          this.$shilp.notify({
            title: "Premium Applied",
            type: "success",
          });

          this.$emit("premiumApplied");
        })
        .catch(({ data }) => {
          this.$shilp.notify({
            title: "Something went wrong",
            type: "danger",
          });

          console.error(data);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss">
.premium-field {
  @apply text-lg;
}
.premium-field .field .field__content .field__after {
  background: white !important;
}
</style>
