<template>
  <div>
    <h4 class="text-primary-500 font-bold">Recent Orders</h4>
    <SpList :attrs="$options.columns" :endpoint="`orders`" :params="params" :actions="[]" :filters="filters" :footer="false">
      <template #empty>
        <list-empty />
      </template>
      <SpListTable @rowClick="goToOrders"></SpListTable>
    </SpList>
  </div>
</template>

<script>
import { formatPrice, formatDate } from "@/utilities/common";

export default {
  columns: [
    { name: "order_id", label: "Order ID ", value: (item) => item.id },
    { name: "created_at", label: "Date & Time", value: (item) => formatDate(item.created_at) },
    { name: "brand", label: "Brand", value: (item) => item.orderProducts[0].products?.brand?.name },
    { name: "total_quantity", label: "Total Quantity", value: (item) => item.total_quantity },

    { name: "total", label: "Total", value: (item) => formatPrice(item.total) },
  ],
  data() {
    return {
      params: {
        include: "orderProducts.product,orderProducts.product.categories,orderProducts.product.brand",

        limit: 5,
        sort: {
          by: "id",
          order: "desc",
        },
      },
      filters: {
        company_id: this.$route.params.id,
        recent_order: true,
      },
    };
  },

  methods: {
    goToOrders({ id }) {
      this.$router.push({ name: "orders", query: { id: id } });
    },
  },
};
</script>

<style lang="scss" scoped></style>
