<template>
  <div class="mt-2">
    <sp-list sortBy="created_at" sortOrder="desc" ref="list" class="h-100" :attrs="columns" :params="$options.params" :endpoint="`vendor/${vendorId}/products`">
      <template #empty>
        <list-empty />
      </template>
      <template #header="{}"> </template>
      <sp-list-table>
        <template #brands="{ item }">
          <div class="flex flex--fit">
            <div v-for="brand in item.brands.split(',')" :key="brand">
              <StatusTag class="location-chip mr-1" :label="brand" />
            </div>
          </div>
        </template>
        <template #states="{ item }">
          <v-popover trigger="click" popoverWrapperClass="w-[300px]">
            <template #popover>
              <div v-for="(state, index) in item.states" :key="state.state">
                <p class="word-wrap">{{ index + 1 }} - {{ state.state }}: {{ state.city_name }}</p>
                <br />
              </div>
            </template>
            <StatusTag class="location-chip mr-1" :label="`States: ${item.state_count}`" />
            <StatusTag class="location-chip" :label="`Cities: ${item.total_city_count}`" />
          </v-popover>
        </template>
        <template #action="{ item }">
          <s-button icon="IconMdiTrashCanOutline" shape="circle" color="danger" theme="muted" class="mr-1" @click.native="removeProduct(item)" />
          <!-- <s-button icon="IconMdiPencilOutline" shape="circle" color="info" theme="muted" /> -->
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>

<script>
import StatusTag from "@/components/StatusTag.vue";
import { deleteProduct } from "@/api/vendor/order.js";
export default {
  params: {
    limit: -1,
  },
  data() {
    return {
      vendorId: this.$route.params.id,
      columns: [
        {
          name: "states",
          label: "Location",
          rowClick: false,
        },
        {
          name: "categories",
          label: "Category",
          rowClick: false,
        },
        {
          name: "brands",
          label: "Brand",
          rowClick: false,
        },
        {
          name: "action",
          label: "Action",
          rowClick: false,
        },
      ],
    };
  },
  components: {
    StatusTag,
  },
  computed: {},
  created() {},
  mounted() {},

  methods: {
    refreshList() {
      this.$refs.list.refresh();
    },
    removeProduct(item) {
      this.$shilp.confirm({
        title: "Delete confirmation",
        message: `Are you sure you want to delete product`,
        okLabel: "Yes",
        cancelColor: "grey",
        ok: ({ close }) => {
          const payload = {
            data: {
              ids: item.ids,
            },
          };
          deleteProduct(payload)
            .then(() => {
              close();
              this.refreshList();
              this.$shilp.notify({
                title: "Product deleted successfully.",
                type: "success",
              });
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {});
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.location-chip {
  background-color: #eceff1;
  color: black;
}
.word-wrap {
  word-wrap: break-word;
}
::v-deep .v-list__footer {
  display: none !important;
}
</style>
