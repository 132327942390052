<template>
  <div>
    <div class="card-wrap">
      <s-field-validate label="Upload Invoice" rules="required" v-slot="{ invalid }">
        <div>
          <VueFileAgentWrapper
            :baseUrl="$options.baseUrl"
            key="invoice-upload-agent"
            v-model="files"
            endpoint="medias"
            :params="{ used_for: 'invoice', order_no: order_no }"
            :multiple="true"
            :isDeletable="true"
            :isDisabled="isDisabled"
            description="Choose one or more image/pdf files"
            :linkable="true"
            @uploading="isUploading = $event"
            @uploadComplete="isUploading = $event"
          />
          <s-button label="Submit" color="primary" :loader="isSubmitting" :disabled="invalid || isUploading" class="mt-4" @click.native="submitInvoice" />
        </div>
      </s-field-validate>
    </div>
  </div>
</template>

<script>
import { uploadInvoice } from "@/api/invoice";
import { alertApiError } from "@/utilities/common";
import fileUploadMixin from "@/mixins/fileUpload";
export default {
  baseUrl: process.env.VUE_APP_STORE_REST_API,

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    orderId: Number,
    order_no: String,
    isDisabled: Boolean,
  },
  mixins: [fileUploadMixin],
  data() {
    return {
      files: [],
      isSubmitting: false,
      isUploading: false,
    };
  },
  components: {
    VueFileAgentWrapper: require("@/components/VueFileAgentWrapper").default,
  },

  methods: {
    submitInvoice() {
      const isValid = this.isMediaValid(this.files);

      if (isValid) {
        this.isSubmitting = true;
        uploadInvoice({
          order_id: this.orderId,
          media_id: this.checkIfAttachmentIsUploadedAndGetId(),
        })
          // title: "Test Invoice",
          // description: "Invoice description",
          .then(() => {
            this.$emit("invoiceSubmitted");
          })
          .catch((error) => {
            alertApiError(error, this);
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },

    checkIfAttachmentIsUploadedAndGetId() {
      if (this.files.length) {
        let imageIds = [];
        this.files.forEach((item) => {
          if (item.upload && item.upload.data && item.upload.data[0].id) {
            imageIds.push(item.upload.data[0].id);
          }
          // });
        });
        return imageIds;
      }

      return false;
    },
  },
};
</script>

<style lang="scss"></style>
