export default [
  {
    path: "inquiry",
    name: "inquiries",
    component: require("@/views/private/buyer/inquiry/Index").default,
    meta: {
      label: "Inquiry",
    },
    redirect: { name: "inquiry-list" },
    children: [
      {
        path: "list",
        name: "inquiry-list",
        component: require("@/components/inquiry/List").default,
        meta: {
          label: "Inquiries",
          breadcrumbs: ["dashboard", "inquiries", "inquiry-list"],
        },
      },
      {
        path: ":id",
        props: (route) => ({ id: Number(route.params.id) }),
        name: "inquiry-details",
        component: require("@/components/inquiry/View").default,
        meta: {
          label: "Details",
          breadcrumbs: ["dashboard", "inquiries", "inquiry-details"],
        },
      },
    ],
  },
];
