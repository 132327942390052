<template>
  <modal size="sm" title="Add Comment" id="comment-modal">
    <template #default>
      <s-field label="Comments" class="field--required">
        <s-textarea v-model="comment" :rows="4" />
      </s-field>
      <div class="flex flex--fit justify-end">
        <s-button
          label="Cancel"
          class="mt-2 button--sm"
          @click.native="
            $root.$emit('shilp-modal-close');
            comment = null;
          "
          :loader="savingStatus"
        />
        <s-button color="primary" label="Comment" class="mt-2 button--sm" :disabled="!comment" @click.native="addComment" :loader="savingStatus" />
      </div>
    </template>
  </modal>
</template>
<script>
import { createComment } from "@/api/vendor/issue.js";
import { formatDate } from "@/utilities/common";
export default {
  props: {
    allComments: Array,
  },
  data() {
    return {
      comment: null,
      savingStatus: false,
    };
  },
  methods: {
    formatDate,
    addComment() {
      this.savingStatus = true;
      const postData = {
        inquiry_id: this.$route.params.id,
        description: this.comment,
      };
      createComment(postData)
        .then(() => {
          this.$emit("refreshComments");
          this.savingStatus = false;
          this.$root.$emit("shilp-modal-close");
        })
        .catch((err) => {
          this.savingStatus = false;
          this.$shilp.notify({
            title: err.data.message,
            type: "danger",
          });
        });
    },
  },
};
</script>
