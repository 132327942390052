<template>
  <sp-list :data="data" :attrs="$options.partnerColumns" :actions="[]">
    <template #empty>
      <list-empty title="No Project Incharge Found" />
    </template>
    <sp-list-table> </sp-list-table>
  </sp-list>
</template>

<script>
export default {
  partnerColumns: [
    "name",
    {
      name: "email",
      value: (item) => (item.email ? item.email : "Not Available"),
    },
    "mobile_no",
    {
      name: "main_partners",
      label: "Main Partner",
      value: (item) => (item.is_default ? "Yes" : "No"),
    },
    {
      name: "enable_notification",
      label: "Notification Enabled",
      value: (item) => (item.enable_notification ? "Yes" : "No"),
    },
  ],
  props: ["data"],
};
</script>

<style lang="scss" scoped></style>
