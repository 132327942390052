<template>
  <div>
    <portal to="header-actions">
      <div class="header-actions">
        <AddNewCompanyBtn />
      </div>
    </portal>
    <router-view />
  </div>
</template>

<script>
import AddNewCompanyBtn from "@/components/company/buttons/AddNewCompany.vue";
export default {
  components: {
    AddNewCompanyBtn,
  },
};
</script>

<style>
.company-detail-title {
  @apply text-2xl font-bold block text-primary-500 mb-2;
}
</style>
