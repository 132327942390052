<template>
  <div class="rounded-lg shadow-lg">
    <label class="text-gray-500 text-base font-normal">Additional Instructions</label>

    <div class="text-gray-800 font-medium">
      {{ instructions }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["instructions"],
};
</script>

<style lang="scss" scoped></style>
