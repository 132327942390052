<template>
  <div class="item-wrapper">
    <ItemForm
      class="card-wrap"
      title="Team Members"
      desc="You can add or edit team members from this page."
      :fields="fields"
      :item="id"
      width="400px"
      :get="getItem"
      :save="saveItem"
      :redirect="true"
      breadcrumb="name"
      redirectTo="team-members-list"
      :gap="2"
    >
      <template #default="{ form }">
        <s-field-validate rules="required" name="Name" label="Name">
          <s-textbox v-model="form.name"></s-textbox>
        </s-field-validate>

        <CustomMobileInput v-model="form.mobile_no" label="Mobile Number" />

        <s-field label="Description">
          <s-textarea :rows="4" v-model="form.description" placeholder="Description"></s-textarea>
        </s-field>
      </template>

      <template #footer="{ saving }">
        <s-button :loader="saving" label="Submit" color="primary" type="submit" />
      </template>
    </ItemForm>
    <template v-if="companies && companies.length">
      <AssignedCompanyList :companies="companies" class="company-list" />
    </template>
  </div>
</template>

<script>
import { get, create, update } from "@/api/teamMember";
import { getAll as getAllTeamMembers } from "@/api/teamMember";
import { mapMutations } from "vuex";
export default {
  params: {
    include: "company",
  },
  components: {
    CustomMobileInput: require("@/components/CustomMobileInput.vue").default,
    AssignedCompanyList: require("@/components/company/AssignedCompanyList").default,
  },
  data() {
    return {
      fields: ["name", "mobile_no", "description"],
      companies: null,
    };
  },

  props: ["id"],
  methods: {
    ...mapMutations(["setTeamMemberOptions"]),
    getItem() {
      return get(this.id, this.$options.params).then((res) => {
        this.companies = res.company;
        return res;
      });
    },
    saveItem(id, data) {
      let response = null;
      if (id) {
        response = update(id, data).then((res) => {
          return res;
        });
      } else {
        response = create(data).then((res) => {
          return res;
        });
      }

      getAllTeamMembers({ limit: -1 }).then((teamMembers) => {
        this.setTeamMemberOptions(teamMembers.data);
      });

      return response;
    },
  },
};
</script>

<style lang="scss" scoped>
.item-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.company-list {
  @apply lg:ml-2 mt-2 lg:mt-0;
}
</style>
