<template>
  <div v-shilp-loader.overlay="isLoading">
    <v-select
      name="Team Member"
      :disabled="isDisabled"
      :get-option-label="(option) => `${option.name} (${option.role && option.role.length ? option.role[0] : ''})`"
      :reduce="(item) => item.id"
      placeholder="Select"
      v-model="selected"
      @option:selected="assign"
      :options="teamMemberOptions"
    />
  </div>
</template>

<script>
import options from "@/data/options.js";
import { mapGetters } from "vuex";
import { teamAssign } from "@/api/company";
import { assignUser } from "@/api/vendor/vendor.js";
export default {
  options,
  data() {
    return {
      selected: null,
      isLoading: false,
    };
  },
  props: ["companyId", "teamMember", "isDisabled", "module"],
  computed: {
    ...mapGetters(["teamMemberOptions"]),
  },
  created() {
    if (this.teamMember) this.selected = this.teamMember;
  },
  mounted() {},
  methods: {
    async assign() {
      this.isLoading = true;
      let api = null;
      if (this.module == "vendor") {
        api = assignUser(this.companyId, {
          assign_to: this.selected,
        });
      } else {
        api = teamAssign(this.companyId, {
          assign_to: this.selected,
        });
      }
      api
        .then(() => {
          this.$shilp.notify({
            title: "Team Member Assigned.",
            type: "success",
          });
          this.selected = null;
          this.$emit("assigned");
        })
        .catch(() => {
          this.$shilp.notify({
            title: "Something went wrong.Try Again.",
            type: "danger",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
