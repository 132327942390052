<template>
  <date-picker :clearable="clearable" :value="value" @input="setDate" :range="range" placeholder="Select" :valueType="valueType" format="DD-MM-YYYY" :disabled-date="isDateDisabled" />
</template>

<script>
export default {
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    value: [String, Array, Number],
    range: {
      type: Boolean,
      default: true,
    },

    pastDateDisabled: {
      type: Boolean,
      default: false,
    },
    futureDateDisabled: {
      type: Boolean,
      default: true,
    },
    valueType: {
      type: String,
      default: "DD-MM-YYYY",
    },
  },
  methods: {
    setDate($event) {
      this.$emit("input", $event);
    },
    isDateDisabled(date) {
      if (this.futureDateDisabled) return new Date().getTime() < date.getTime() ? true : false;
      else if (this.pastDateDisabled) {
        if (date.getTime() < this.setDateTimeStartOfDay()) {
          return true;
        } else return false;
      }
      // }
    },

    setDateTimeStartOfDay() {
      return new Date().setHours(0, 0, 0, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
