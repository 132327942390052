import company from "./company";
import dashboard from "./dashboard";
import product from "./product";
import brand from "./brand";
import buyerType from "./buyerType";
import orders from "./orders";
import credit from "./credit";
import project from "./project";
import teamMember from "./teamMember";
import inquiry from "./inquiry";
import brochure from "./brochure";
import notification from "./notification";
import user from "./user";
import manageVendor from "./vendor/manageVendor";
import vendorOrders from "./vendor/orders";
import vendorInquery from "./vendor/inquiry";
import vendorNotification from "./vendor/notification";
import vendorSettings from "./vendor/setting";
import vendorAdvancedBid from "./vendor/advanceBid";
import settings from "./settings";
export default [...dashboard, ...company, ...product, ...brand, ...buyerType, ...orders, ...credit, ...project, ...teamMember, ...inquiry, ...brochure, ...notification, ...user, ...manageVendor, ...vendorOrders, ...vendorInquery, ...vendorNotification, ...vendorSettings, ...vendorAdvancedBid, ...settings];
