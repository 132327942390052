<template>
  <div>
    <div class="relative z-10 bg-white py-3 px-4 mb-4 rounded-md mx-auto drop-shadow flex flex--fit gap-6 flex--bottom" v-shilp-loader.overlay="isCompanyLoading">
      <CompanyFilter @selected="setCompanyFilter" class="company-filter" @cleared="resetCompanyState" />

      <CompanyGst v-if="companyGstNumber" :gstNumber="companyGstNumber" />

      <CompanyMobile v-if="companyMobileNumber" :number="companyMobileNumber" />
    </div>

    <OrderWidget class="mb-2" @selected="onOrderWidgetSelect" :activeId="$route.query.order_status_id" />

    <FilterBar @resetFilters="resetFilters()">
      <template #default>
        <s-field label="Date" class="mt-2">
          <CustomDatePicker :value="filters.date_range" @input="setDateRange($event)" />
        </s-field>

        <!-- Brand Options  -->
        <s-field label="Brand" v-shilp-loader.overlay="isBrandsLoading" class="mt-2" v-if="isBrandListVisible">
          <BrandSelect :multiple="true" :value="filters.brand_id" @input="setBrandId($event)" />
        </s-field>
        <!-- Payment Status -->
        <s-field label="Payment Status" class="mt-2">
          <v-select placeholder="Select" :options="paymentStatusOptions" label="display_name" :value="filters.payment_status_id" @input="setPaymentStatusId($event)" :reduce="(item) => item.id" />
        </s-field>

        <!-- Invoice Status Option -->
        <InvoiceStatusDropdown :value="filters.invoice_empty" @input="setInvoiceEmpty($event)" />

        <!-- Order Status  -->
        <!-- <s-field label="Order Status" class="mt-2">
          <v-select placeholder="Select" :options="orderStatusOptions" label="display_name" v-model="filters.order_status_id" :reduce="(item) => item.id" />
        </s-field> -->

        <!-- Projects  -->
        <s-field label="Projects" class="mt-2" v-if="filters.company_id">
          <v-select placeholder="Select" :options="projectOptions" label="name" :value="filters.project_id" @input="setProjectId($event)" :reduce="(item) => item.id" />
        </s-field>

        <!-- Expected in 5 Days -->
        <s-field label="Expected Delivery in 5 Days" class="mt-2">
          <CustomDatePicker :value="expectedDeliveryDate" @input="setExpectedDeliveryDate($event)" :range="false" :pastDateDisabled="true" :futureDateDisabled="false" />
          <!-- <div>
            <s-button label="Submit" color="primary" class="expected-custom-button" @click.native="filters.expected_delivery_date" />
          </div> -->
        </s-field>

        <s-field label="Expected Delivery Date Crossed" class="mt-3" inline>
          <s-switch :value="filters.expected_delivery_date_missing" @input="setExpectedDeliveryDateMissing($event)" :on-value="1" :off-value="0" shape="pill" color="primary" size="sm" />
        </s-field>
      </template>
    </FilterBar>

    <sp-list
      ref="list"
      sortBy="created_at"
      sortOrder="desc"
      :perPage="params.limit"
      pagination-mode="infinite"
      v-infinite-scroll="loadMoreData"
      :attrs="columns"
      :filters="filters"
      endpoint="orders"
      :params="params"
      :actions="['refresh']"
    >
      <template #header="{ refresh }">
        <FilterTabs ref="categoryFilterTabs" />

        <div class="filter-section">
          <s-field class="filter-search">
            <!-- <s-textbox v-model="filters.search" placeholder="Search" /> -->
            <SearchFilter :value="filters.search" @search="setSearch($event)" />
          </s-field>
          <s-button-group>
            <s-button class="filter" icon="vmdi-filter" shape="circle" @click.native="$root.$emit('filter-bar-open')" />
            <s-button
              class="refresh"
              icon="vmdi-refresh"
              shape="circle"
              @click.native="
                refresh();
                $refs.categoryFilterTabs.getCount();
              "
            />
          </s-button-group>
        </div>
      </template>
      <template #empty>
        <ListEmpty title="No Order Found" />
      </template>

      <template #default>
        <portal to="header-actions">
          <v-popover trigger="click">
            <s-button icon="IconIcExcel" class="mr-2" color="secondary" label="Export" theme="solid" />
            <template #popover>
              <s-button icon="IconIcExcel" class="" color="secondary" label="Vendor Export" v-tooltip="'Download vendor data'" @click.native="downloadVendorList()" :loader="isVendorExporting" />
              <s-button icon="IconIcExcel" class="mt-2" color="secondary" label="Export" v-tooltip="'Download data'" @click.native="downloadList" :loader="isExporting" />
            </template>
          </v-popover>
        </portal>

        <sp-list-table @rowClick="rowClick($event.id)">
          <template #order_id="{ item }">
            <!-- binding id here to provide scroll automatically into view -->
            <span class="capitalize" v-if="item.order_no" v-scroll-into-view="{ id: item.order_no }">#{{ item.order_no }}</span>
            <span class="capitalize" v-else v-scroll-into-view="{ id: item.id }">#{{ item.id }}</span>
          </template>

          <template #created_at="{ item }">
            <span> {{ formatDate(item.created_at) }}</span>
          </template>

          <template #categories="{ item }">
            <template v-if="item.products && item.products[0].categories[0].name">
              <template>
                <!-- <div
                :key="'productCategory' + index"
                class="overflow-ellipsis my-2"
              > -->
                {{ item.products[0].categories[0].name }}
                <!-- </div> -->
              </template>
            </template>
          </template>

          <!--        <template #company="{ item }">
          <span v-if="item.company && item.company.name" >
            {{ item.company.name }}
          </span>

          <span v-else>?</span>
        </template> -->

          <template #project-name="{ item }">
            <span v-if="item.project && item.project.name">
              {{ item.project.name }}
            </span>

            <span v-else>?</span>
          </template>

          <template #brand_name="{ item }">
            <!-- <brand-logo :url="item.products[0].brand.logo.url" v-if="item.products && item.products[0].brand && item.products[0].brand.logo.url" /> -->

            <span v-if="item.products && item.products[0].brand">{{ item.products[0].brand.name }}</span>
          </template>
          <template #products="{ item }">
            <template v-if="item.products && item.products.length">
              <template v-for="(product, index) in item.products">
                <div :key="'productName-' + index" class="overflow-ellipsis my-2">
                  {{ product.name }}
                </div>
              </template>
            </template>
            <span v-else> ?</span>
          </template>

          <!-- <template #quantity="{ item }">
          <template v-if="item.products && item.products.length">
            <template v-for="(product, index) in item.products">
              <div :key="'productQuantity-' + index" class="overflow-ellipsis my-2 font-semibold" >
                {{ product.quantity }}
              </div>
            </template>
          </template>
          <span v-else> ?</span>
        </template> -->

          <template #total_quantity="{ item }">
            <!-- <span > {{ item.total_quantity }} </span></template -->
            <span> {{ item.total_quantity }} </span></template
          >

          <template #total="{ item }">
            <product-price :price="item.total" v-if="item.total" />
          </template>

          <template #order_status="{ item }">
            <div class="flex flex--nowrap flex--fit gap-2 flex--middle justify-end" :key="`order-status-update-btn-${item.id}`">
              <order-status-tag :name="item.orderStatus.name" :label="item.orderStatus.display_name" v-if="item.orderStatus" />

              <!-- <v-popover trigger="click">
              <template #popover>
                <UpdateOrderStatus :orderId="item.id" :status="item.orderStatus.id" @order-status-changed="onOrderStatusUpdate" />
              </template>
              <s-button title="Change Status" icon="IconMdiPencilOutline" size="sm" color="grey" theme="muted" shape="circle" />
            </v-popover> -->
            </div>
          </template>

          <template #payment_status="{ item }">
            <div class="flex flex--nowrap flex--fit gap-2 flex--middle justify-end" :key="`payment-status-update-btn-${item.id}`">
              <payment-status-tag :name="item.paymentStatus.name" :label="item.paymentStatus.display_name" v-if="item.paymentStatus" />

              <!-- <v-popover trigger="click">
              <template #popover>
                <UpdatePaymentStatus :orderId="item.id" :status="item.paymentStatus.id" @payment-status-changed="onPaymentStatusUpdate" />
              </template>
              <s-button title="Change Payment Status" icon="IconMdiPencilOutline" size="sm" color="grey" theme="muted" shape="circle" />
            </v-popover> -->
            </div>
          </template>
          <!-- <template #actions="{ item }">
          <s-button
            title="Change Status"
            :key="`order-status-modal-btn-${item.id}`"
            icon="IconMdiPencilOutline"
            class="mr-2"
            @click.native="
              $root.$emit('shilp-modal-open', {
                id: 'modal-order-change',
                scope: {
                  itemId: item.id,
                  order_status_id: item.order_status_id,
                  payment_status_id: item.payment_status_id,
                },
              })
            "
            color="grey"
            theme="muted"
            shape="circle"
          />
        </template> -->
        </sp-list-table>
      </template>
    </sp-list>
    <!-- <status-modal @status-changed="$refs.list.refresh()" /> -->
  </div>
</template>

<script>
import { get } from "@/api/company";
import { mapGetters, mapMutations } from "vuex";
import { formatDate, getReadClassList } from "@/utilities/common";
import { getBrands } from "@/api/brand";
import { downloadHistory } from "@/api/order";
import tableMixin from "@/mixins/table";
import BrandSelect from "@/components/brand/BrandSelect.vue";
import bus from "@/bus";
import roleMixin from "@/mixins/role";

export default {
  components: {
    ListEmpty: require("@/components/ListEmpty.vue").default,
    ProductPrice: require("@/components/ProductPrice.vue").default,
    OrderStatusTag: require("@/components/OrderStatusTag.vue").default,
    PaymentStatusTag: require("@/components/order/PaymentStatusTag.vue").default,
    SearchFilter: require("@/components/SearchFilter.vue").default,
    // BrandLogo: require("@/components/BrandLogo.vue").default,
    FilterTabs: require("@/components/order/FilterTabs.vue").default,
    // StatusModal: require("./StatusModal.vue").default,
    // UpdateOrderStatus: require("@/components/order/UpdateStatus.vue").default,
    // UpdatePaymentStatus: require("@/components/order/UpdatePaymentStatus.vue").default,
    OrderWidget: require("@/components/order/Widget.vue").default,
    CompanyFilter: require("@/components/company/Filter.vue").default,
    CompanyMobile: require("@/components/company/Mobile.vue").default,
    CompanyGst: require("@/components/company/Gst").default,
    CustomDatePicker: require("@/components/CustomDatePicker.vue").default,
    FilterBar: require("@/components/FilterBar").default,
    InvoiceStatusDropdown: require("@/components/invoice/StatusOptionDropdown").default,
    BrandSelect,
  },
  mixins: [tableMixin, roleMixin],
  data() {
    return {
      isExporting: false,
      companyOptions: [],
      projectOptions: [],
      brandOptions: [],
      companyGstNumber: null,
      companyMobileNumber: null,
      isBrandsLoading: false,
      isCompanyLoading: false,
      isVendorExporting: false,
      // isOrderStatusPopUpOpen: false,
      // isPaymentStatusPopUpOpen: false,
      // paymentStatusKey: 1,
      columns: [
        {
          name: "order_id",
          label: "Order ID",
          width: "103px",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "created_at",
          label: "Date & Time",
          sortable: true,
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        // { name: "categories", label: "Category" },
        // { name: "company", label: "Company" },
        {
          name: "project-name",
          label: "Project Name",
          width: "300px",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "brand_name",
          label: "Brand",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        // { name: "products", label: "Products" },
        // { name: "quantity", label: "Quantity" },
        {
          name: "total_quantity",
          label: "Total Quantity",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "total",
          label: "Total",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "order_status",
          label: "Order Status",
          rowClick: false,
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "payment_status",
          label: "Payment Status",
          rowClick: false,
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        // { name: "actions", label: "Action", rowClick: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      paymentStatusOptions: "paymentStatusOptions",
      orderStatusOptions: "orderStatusOptions",
      companyFilterIdFromVuex: "companyFilterId",
      filters: "order/filters",
      expectedDeliveryDate: "order/expectedDeliveryDate",
      hasAllPermissions: "hasAllPermissions",
      isPermissionGranted: "isPermissionGranted",
    }),

    params() {
      return {
        limit: 10,
        include: "products,products.brand,products.categories,products.attributeOptions.attribute,orderStatus,paymentStatus,company,project",
        expected_delivery_date: this.expectedDeliveryDate,
      };
    },
    orderId() {
      return this.$route.query.id;
    },

    companyId() {
      return this.companyFilterIdFromVuex;
    },
  },
  created() {
    bus.$on("notification-arrived", () => {
      this.refresh();
    });

    if (this.isBrandListVisible) this.getAllBrands();

    if (this.companyId) this.setCompanyId();

    if (this.$route.query && this.$route.query.order_status_id) {
      let orderStatusId = this.$route.query.order_status_id;
      if (orderStatusId == "all") this.setOrderStatusId(null);
      else {
        if (Array.isArray(orderStatusId)) this.setOrderStatusId(orderStatusId);
        else this.setOrderStatusId(Number(orderStatusId));
      }
    }
  },

  watch: {
    "filters.category_id"() {
      this.filters.brand_id = null;
      this.getAllBrands();
    },
  },
  destroyed() {
    bus.$off("notification-arrived", this.refresh);
  },
  methods: {
    formatDate,
    refresh() {
      this.$refs.list.refresh();
    },
    getReadClassList,
    ...mapMutations({
      setDateRange: "order/setDateRange",
      setOrderStatusId: "order/setOrderStatusId",
      setPaymentStatusId: "order/setPaymentStatusId",
      setBrandId: "order/setBrandId",
      setCompanyId: "order/setCompanyId",
      setProjectId: "order/setProjectId",
      setSearch: "order/setSearch",
      setInvoiceEmpty: "order/setInvoiceEmpty",
      setExpectedDeliveryDate: "order/setExpectedDeliveryDate",
      setExpectedDeliveryDateMissing: "order/setExpectedDeliveryDateMissing",
      resetFilters: "order/resetFilters",
    }),
    onOrderWidgetSelect(orderStatus) {
      this.filters.order_status_id = orderStatus;
    },

    downloadList() {
      this.isExporting = true;
      downloadHistory({
        filter: this.filters,
      }).then((res) => {
        window.open(res.url, "_blank");
        this.isExporting = false;
      });
    },

    downloadVendorList() {
      this.isVendorExporting = true;
      downloadHistory({
        export_type: "vendor",
        filter: this.filters,
      }).then((res) => {
        window.open(res.url, "_blank");
        this.isVendorExporting = false;
      });
    },
    rowClick(orderId) {
      this.$router.push({
        name: "order-details",
        params: { id: orderId, storeScrollState: true },
      });
    },
    resetCompanyState() {
      this.filters.company_id = null;
      this.companyGstNumber = null;
      this.companyMobileNumber = null;
    },

    // onOrderStatusUpdate() {
    //   this.$refs.list.refresh();
    //   // this.isOrderStatusPopUpOpen = false;
    // },
    // onPaymentStatusUpdate() {
    //   this.$refs.list.refresh();
    //   // this.paymentStatusKey++;
    //   // this.isPaymentStatusPopUpOpen = true;
    //   // this.isPaymentStatusPopUpOpen = false;
    // },

    // toggleSidebar() {
    //   this.$refs.list.toggleSidebar("filters");
    // },

    setCompanyFilter(id) {
      this.setCompanyId(id);

      this.setProjectOptions();
    },
    async setProjectOptions() {
      this.isCompanyLoading = true;
      if (this.filters.company_id) {
        let res = await get(this.filters.company_id, {
          limit: -1,
          include: "projects",
        });

        this.companyMobileNumber = res.mobile_no;
        this.companyGstNumber = res.gst_no;
        this.projectOptions = res.projects;

        this.isCompanyLoading = false;
      }
    },

    getAllBrands() {
      this.isBrandsLoading = true;
      getBrands({
        limit: -1,
        filter: {
          category_id: this.filters.category_id,
        },
      }).then((res) => {
        this.brandOptions = res.data;
        this.isBrandsLoading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.sp-table {
  border-radius: 0;
}

.filter-section {
  @apply flex gap-4 items-center;
}

.button-group {
  @apply relative block;
}

.has-applied {
  @apply text-secondary-500 z-10;
  background: none !important;
}

.not-applied {
  @apply text-gray-500;
}

.animate {
  @apply absolute left-0 bg-secondary-100 animate-ping p-4 rounded-full;
}
</style>
