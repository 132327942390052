<template>
  <div class="border-b-2 border-gray-200 p-3 hover:shadow-lg cursor-pointer bg-white notification-card">
    <div>
      <Tag :label="title" class="mb-2" size="sm" />
      <h5 class="text-gray-500">{{ company ? company.name : "" }}</h5>
      <p class="text-grey">{{ description }}</p>
    </div>
    <div class="custom-date">
      <s-icon name="IconMdiClockTimeThreeOutline" class="text-grey mr-1 w-3 h-3" />
      <p class="text-gray-400">{{ relativeDate() }}</p>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utilities/common";
import moment from "moment";
export default {
  props: ["title", "dateTime", "id", "company", "description"],
  methods: {
    formatDate,
    relativeDate() {
      return moment(this.formatDate(this.dateTime)).fromNow();
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-card {
  min-width: 400px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.custom-date {
  min-width: 150px;
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
}
</style>
