var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SpList',{staticClass:"mt-3",attrs:{"attrs":_vm.$options.columns,"endpoint":"importExcel","actions":[]}},[_c('SpListTable',{scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('Tag',{attrs:{"label":item.status}})]}},{key:"media_name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"download":""}},[_c('div',{staticClass:"flex flex--fit gap-2 flex--middle cursor-pointer hover:text-gray-500"},[_c('s-icon',{attrs:{"name":"IconMdiDownload"}}),_vm._v(" "),_c('span',{},[_vm._v(_vm._s(item.media_name))])],1)])]}},{key:"result",fn:function(ref){
var item = ref.item;
return [(item.result)?_c('s-button',{attrs:{"label":"View Log","icon":"IconIcLog","color":"secondary"},nativeOn:{"click":function($event){_vm.modalLog = item.result.validation_failed_rows;
            _vm.modalStatus = item.status;
            _vm.$shilp.modal.open('bulk-actions-log-modal');}}}):_vm._e()]}}])})],1),_c('LogModal',{attrs:{"status":_vm.modalStatus,"data":_vm.modalLog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }