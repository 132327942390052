<template>
  <div>
    <div v-if="bidDetails && !isLoading">
      <div class="flex flex--fit flex--nowrap mb-2" v-if="bidDetails && !isLoading">
        <div class="bg-white p-3 radius-2 mr-2 w-full">
          <div class="grid grid--6">
            <div>
              <p class="font-medium text-gray-500">Vendor Name</p>
              <p class="text-sm font-medium text-gray-600">{{ bidDetails.user && bidDetails.user.name }}</p>
            </div>
            <div>
              <p class="font-medium text-gray-500">Mobile No</p>
              <p class="text-sm font-medium text-gray-600">{{ bidDetails.user && bidDetails.user.mobile_no }}</p>
            </div>
            <div>
              <p class="font-medium text-gray-500">Order ID</p>
              <p class="text-sm font-medium text-gray-600">{{ bidDetails.vendorOrder.order_no }}</p>
            </div>
            <div>
              <p class="font-medium text-gray-500">Category</p>
              <p class="text-sm font-medium text-gray-600">{{ bidDetails.category.name }}</p>
            </div>
            <div class="flex flex--fit">
              <img :src="bidDetails.brand.logo.url" class="w-5 h-5 mr-1" />
              <div>
                <p class="font-medium text-gray-500">Brand</p>
                <p class="text-sm font-medium text-gray-600">{{ bidDetails.brand.name }}</p>
              </div>
            </div>
            <div>
              <p class="font-medium text-gray-500">Assign Status</p>
              <p class="text-sm font-medium text-gray-600 w-1/2">
                <StatusTag :label="bidDetails.bid_status.name" :name="bidDetails.bid_status.name.toLowerCase()" class="flex flex--fit justify-center mt-1" />
              </p>
            </div>
          </div>

          <div class="grid grid--6 mt-3">
            <div>
              <p class="font-medium text-gray-500">Buyer Total Amount</p>
              <p class="text-sm font-medium text-gray-600">{{ formatPrice(bidDetails.vendorOrder.order.total) }}</p>
            </div>
            <div>
              <p class="font-medium text-gray-500">Delivery By</p>
              <p class="text-sm font-medium text-gray-600">{{ formatDate(bidDetails.delivery_date) }}</p>
            </div>

            <div class="!grow">
              <div class="flex flex--fit justify-between mb-2">
                <p class="font-medium text-gray-500">Payment Terms</p>
                <v-popover ref="popover">
                  <template #popover>
                    <div class="flex">
                      <s-field-validate name="payment terms" label="Enter Payment Terms" :rules="`required`" class="mb-2 field--required" after="day(s)">
                        <STextbox type="number" v-model="paymentTermDays" />
                      </s-field-validate>
                      <s-button label="save" class="button--primary text-center" @click.native="updateBid()" :loader="updateBidLoader" :disabled="!paymentTermDays" />
                    </div>
                  </template>
                  <a class="text-blue-500 font-medium cursor-pointer pr-3">Edit</a>
                </v-popover>
              </div>
              <StatusTag v-if="updatedPaymentTerm" :label="updatedPaymentTerm" :name="updatedPaymentTerm.toLowerCase()" />
            </div>
            <div class="note-grid">
              <p class="font-medium text-gray-500 mr-2 mt-1">Note</p>
              <p class="text-sm font-medium text-gray-600">{{ bidDetails.info || "NA" }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white p-3 radius-2">
        <div class="flex flex--fit justify-start gap-8">
          <div>
            <p class="font-medium text-gray-500">Buyer Name</p>
            <p class="text-sm font-medium text-gray-600">{{ bidDetails.vendorOrder.buyerName[0] }}</p>
          </div>
          <div>
            <p class="font-medium text-gray-500">Project Name</p>
            <p class="text-sm font-medium text-gray-600">{{ bidDetails.vendorOrder.project_name }}</p>
          </div>
          <div>
            <div class="flex flex--fit">
              <p class="font-medium text-gray-500 mr-5">Project Location</p>
              <a class="flex flex--fit border border-gray-200 rounded-full px-1 cursor-pointer" @click="redirectToGoogleMaps">
                <s-icon name="IconGoogleMapMarker" class="mt-1" /> <span class="leading-loose font-medium text-gray-400">See on map</span>
              </a>
            </div>
            <p class="text-sm font-medium text-gray-600">{{ bidDetails.vendorOrder.delivery_address }}</p>
          </div>
        </div>
      </div>
      <div class="bg-white rounded p-2 mt-2">
        <ValidationObserver #default="{ invalid }">
          <div class="bg-gray-200 radius-2 mt-2 p-2 flex flex--fit justify-between">
            <p class="font-medium v-list__header">Sub Items</p>
            <div>
              <s-button label="Save" class="button--accent button-sm text-center !h-[30px] mr-2" @click.native="saveAndAssign(invalid, 'save')" />
              <s-button label="Save & Assign" class="button--success button-sm text-center !h-[30px]" @click.native="saveAndAssign(invalid, 'assign')" />
            </div>
          </div>
          <table class="mt-2 overflow-y-scroll w-full bg-white rounded">
            <tr class="border-t border-b">
              <th class="text-left p-2">Grade</th>
              <th class="text-left">Size/Packing</th>
              <th class="text-left">Quantity<span class="text-danger-700">*</span></th>
              <th class="text-left">Price<span class="text-danger-700">*</span></th>
              <th class="text-left">Buyer Price<span class="text-danger-700">*</span></th>
              <th class="text-left">GST</th>
              <th class="text-left">Total Price</th>
            </tr>
            <tr v-for="(detail, bidIndex) in bidDetails.bid_details" :key="'bidId1' + bidIndex" class="border-b">
              <td class="p-3">{{ detail.grade_name }}</td>
              <td>{{ detail.unit_name }}</td>
              <td>
                <s-field-validate rules="required" class="w-3/5 mb-1" :after="detail.unit_name">
                  <STextbox type="number" v-model.number="detail.dispatch_quantity" class="!bg-transparent" />
                </s-field-validate>
              </td>
              <td>
                <s-field-validate rules="required" class="w-4/6" before="₹">
                  <STextbox type="number" v-model.number="detail.price" class="!bg-transparent" @input="calculateGSTPrice(detail)" />
                </s-field-validate>
              </td>

              <td>
                <div class="flex flex--fit items-center">
                  <s-field-validate rules="required" class="w-4/6" before="₹">
                    <STextbox
                      type="number"
                      v-model.number="detail.buyer_unit_price"
                      class="!bg-transparent mr-1"
                      :class="{ 'cursor-not-allowed': detail.isBuyerPriceDisabled === undefined || detail.isBuyerPriceDisabled }"
                      :disabled="detail.isBuyerPriceDisabled === undefined || detail.isBuyerPriceDisabled"
                      :ref="`price-${detail.id}`"
                    />
                  </s-field-validate>
                  <s-button
                    :key="bidIndex"
                    title="Edit"
                    icon="IconMdiPencilOutline"
                    size="sm"
                    color="info"
                    theme="muted"
                    shape="circle"
                    v-if="detail.isBuyerPriceDisabled === undefined || detail.isBuyerPriceDisabled"
                    @click.native="toggleBuyerPriceDisability(detail)"
                  />
                </div>
              </td>
              <td>
                <div class="flex flex--fit leading-relaxed">
                  <div class="mr-1">
                    <p v-if="detail.bid.is_gst_include" class="text-success-700">Included</p>
                    <p v-else class="text-danger-700">Excluded</p>
                  </div>
                  <v-popover trigger="hover" popoverWrapperClass="min-w-max">
                    <template #popover>
                      <PriceBreakUp :gstPercentage="detail.gst_percentage" :gstAmount="detail.single_gst_price" :basePrice="detail.single_base_price" />
                    </template>
                    <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
                  </v-popover>
                </div>
              </td>
              <td>{{ formatPrice(updatedTotalPrice(detail.dispatch_quantity, detail.price)) }}</td>
            </tr>

            <tr class="order-total">
              <td colspan="4">
                <h4 class="price-label">Loading</h4>
              </td>
              <td colspan="2" class="text-right">
                <h5>{{ formatPrice(bidDetails.loading) }}</h5>
              </td>
            </tr>

            <tr class="order-total">
              <td colspan="4">
                <h4 class="price-label">Unloading</h4>
              </td>
              <td colspan="2" class="text-right">
                <h5>{{ formatPrice(bidDetails.unloading) }}</h5>
              </td>
            </tr>

            <tr class="order-total">
              <td colspan="4">
                <h4 class="price-label">Freight</h4>
              </td>
              <td colspan="2" class="text-right">
                <h5>{{ formatPrice(bidDetails.freight) }}</h5>
              </td>
            </tr>

            <tr class="order-total">
              <td colspan="4">
                <h4 class="price-label">Service GST</h4>
              </td>
              <td colspan="2" class="text-right">
                <h5>{{ formatPrice(bidDetails.freight_gst_price + bidDetails.loading_gst_price + bidDetails.unloading_gst_price) }}</h5>
              </td>
            </tr>

            <tr class="order-total">
              <td colspan="2">
                <h4 class="price-label">Total</h4>
              </td>

              <td colspan="2">
                <h5>{{ calculateQuantity(bidDetails) }} {{ bidDetails.unit_name }}</h5>
              </td>

              <td colspan="2" class="text-right">
                <h5>{{ calculateTotalPrice(bidDetails) }}</h5>
              </td>
            </tr>
          </table>
        </ValidationObserver>
      </div>
    </div>
    <Loader class="flex" v-else />
  </div>
</template>

<script>
import { formatPrice } from "@/utilities/common";
import { getBidDetail, updateBidAssign, updateMultipleBidPrice, updateBidDetail } from "@/api/vendor/order.js";
import Loader from "@/components/Loader.vue";
import StatusTag from "@/components/StatusTag.vue";
import PriceBreakUp from "@/components/order/PriceBreakUp.vue";
import { formatDate } from "@/utilities/common";

export default {
  props: ["id"],
  data() {
    return {
      isLoading: false,
      fields: ["quantity", "total", "status"],
      bidDetails: null,
      bidId: null,
      paymentTermDays: null,
      updateBidLoader: false,
      updatedPaymentTerm: null,
    };
  },
  components: {
    Loader,
    StatusTag,
    PriceBreakUp,
  },
  mounted() {
    this.bidId = this.$route.params.bidId;
    this.getBids();
  },
  methods: {
    formatPrice,
    formatDate,

    calculateGSTPrice(detail) {
      detail.single_base_price = (detail.price / (100 + detail.gst_percentage)) * 100;
      detail.single_gst_price = detail.price - detail.single_base_price;
    },

    updatedTotalPrice(qty, price) {
      return qty * price;
    },

    calculateTotalPrice(details) {
      let totalPrice = null;
      for (let detail of details.bid_details) {
        totalPrice += this.updatedTotalPrice(detail.dispatch_quantity, detail.price);
      }
      return this.formatPrice(totalPrice + details.loading + details.unloading + details.freight + details.freight_gst_price + details.loading_gst_price + details.unloading_gst_price);
    },

    calculateQuantity(details) {
      let totalQty = null;
      let bagPrice = null;
      for (let detail of details.bid_details) {
        if (detail.unit_name == "Bag") {
          bagPrice = (detail.dispatch_quantity * 50) / 1000;
          totalQty += bagPrice;
        } else {
          totalQty += detail.dispatch_quantity;
        }
      }
      return totalQty;
    },

    getBids() {
      this.isLoading = true;
      try {
        getBidDetail(
          {
            limit: 25,
            include: "bidDetail,brand,user,bidStatus,bidDetail.bid.vendorOrder.order.orderProducts,vendorOrder.order,category,vendorOrder",
          },
          this.bidId
        )
          .then((res) => {
            this.bidDetails = res;
            this.updatedPaymentTerm = res.payment_term_days == 0 || res.payment_term_days == null ? "Advance" : `${res.payment_term_days} day(s)`;
            this.paymentTermDays = res.payment_term_days == null ? 0 : res.payment_term_days;
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } catch {
        this.isLoading = false;
        this.$shilp.notify({
          title: "Something went wrong. Try Again.",
          type: "danger",
        });
      }
    },

    redirectToGoogleMaps(data) {
      window.open(`http://maps.google.com?q=${data.latitude},${data.longitude}`, "_blank");
    },
    toggleBuyerPriceDisability(detail) {
      if (detail.isBuyerPriceDisabled === undefined) {
        this.$set(detail, "isBuyerPriceDisabled", false);
      } else {
        detail.isBuyerPriceDisabled = !detail.isBuyerPriceDisabled;
      }
    },

    async submitPrice(inValid, fromAssign = false, close) {
      let postData = {
        bid_details: [],
      };
      this.bidDetails.bid_details.map((bid) => {
        postData.bid_details.push({
          id: bid.id,
          price: bid.price,
          dispatch_quantity: bid.dispatch_quantity,
          buyer_unit_price: bid.buyer_unit_price,
        });
      });
      updateMultipleBidPrice(postData)
        .then(() => {
          if (fromAssign) {
            this.assignBid(inValid, close);
          }
          this.getBids();
          if (!fromAssign) {
            this.$shilp.notify({
              title: "Price updated successfully",
              type: "success",
            });
            this.$router.push({ name: "vendor-bids-list", params: { id: this.$route.params.id, order_no: "" } });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$shilp.notify({
            title: err.data.message,
            type: "danger",
          });
        });
    },

    saveAndAssign(inValid, from) {
      if (!inValid && from == "save") {
        this.submitPrice(inValid, false);
      } else if (!inValid && from == "assign") {
        this.$shilp.confirm({
          title: "Assign Confirmation",
          message: `Are you sure you want to assign bid to ${this.bidDetails.user.name}?`,
          ok: ({ close }) => {
            this.submitPrice(inValid, true, close);
          },
        });
      } else {
        this.$shilp.notify({
          title: "Please enter bid price.",
          type: "danger",
        });
      }
    },
    assignBid(inValid, close) {
      try {
        const postData = {
          bid_status_id: 4,
        };
        updateBidAssign(this.bidDetails.id, postData)
          .then(() => {
            this.$emit("close");
            this.$root.$emit("shilp-modal-close", "bid-assign-modal");
            this.$shilp.notify({
              title: "Bid Assigned successfully",
              type: "success",
            });
            this.$router.push({ name: "vendor-assigned-order-list" });
            close();
          })
          .catch((err) => {
            console.log(err);
            this.$shilp.notify({
              title: err.data.message,
              type: "danger",
            });
          });
      } catch {
        this.$shilp.notify({
          title: "Something went wrong. Try Again.",
          type: "danger",
        });
      }
    },

    updateBid() {
      const postData = {
        payment_term_days: this.paymentTermDays,
      };
      updateBidDetail(this.bidId, postData).then(() => {
        this.getBids();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.price-label {
  @apply text-sm font-medium text-gray-600;
}
.price-value {
  @apply font-medium text-xl text-gray-900;
}
.order-total {
  td {
    @apply p-2;
  }
  @apply border-none;
}
.note-grid {
  grid-column-start: 4;
  grid-column-end: 7;
}
::v-deep .status-label {
  @apply text-sm;
}
</style>