<template>
  <div>
    <label class="text-gray-500 block"> GST Number</label>
    <span class="text-lg">{{ gstNumber }}</span>
  </div>
</template>

<script>
export default {
  props: ["gstNumber"],
};
</script>

<style lang="scss" scoped></style>
