<template>
  <div>
    <portal to="header-actions">
      <div class="header-actions">
        <s-button color="primary" label="Add New Brochure" icon="IconIcRoundPlus" :to="{ name: 'brochure-upsert', params: { id: '+' } }" />
      </div>
    </portal>
    <sp-list sortBy="created_at" sortOrder="desc" ref="list" class="h-100" :filters="filters" :perPage="$options.params.limit" :params="$options.params" :attrs="columns" endpoint="brochures">
      <template #empty>
        <list-empty />
      </template>

      <template #header="{ refresh }">
        <div></div>
        <FilterSection @refresh="refresh()" @search="filters.search = $event" />
      </template>
      <template #filters>
        <div class="text-center">
          <s-button label="Clear" icon="IconMdiFilterRemoveOutline" color="danger" theme="muted" @click.native="resetFilters" />
        </div>
      </template>

      <sp-list-table @rowClick="$router.push({ name: 'brochure-upsert', params: { id: $event.id } })">
        <template #id="{ item }">
          <span>#{{ item.id }}</span>
        </template>

        <template #name="{ item }">
          <span>{{ item.name }}</span>
        </template>

        <template #created_by="{ item }">
          <span>{{ item.created_by }}</span>
        </template>

        <template #brand_id="{ item }">
          <span>{{ item.brand_id }}</span>
        </template>

        <!-- <template #brochureFiles="{ item }">
          <span v-if="item.brochureFiles === null">-</span>
          <span v-else>{{ item.brochureFiles }}</span>
        </template> -->

        <template #actions="{ item }">
          <s-button v-if="isDeleteGranted" icon="IconMdiTrashCanOutline" shape="circle" color="danger" theme="muted" @click.native="deleteBrochure(item.id)" />
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { remove } from "@/api/brochure";
import { formatDate } from "@/utilities/common";
export default {
  params: {
    include: "brand",
    limit: 25,
  },
  components: {
    ListEmpty: require("@/components/ListEmpty.vue").default,
    FilterSection: require("@/components/TableFilterSection.vue").default,
  },
  computed: {
    ...mapGetters({
      brandOptions: "brandOptions",
      hasAllPermissions: "hasAllPermissions",
      isPermissionGranted: "isPermissionGranted",
    }),

    isDeleteGranted() {
      if (this.hasAllPermissions) return true;
      else {
        return this.isPermissionGranted("brochure-delete");
      }
    },
  },
  data() {
    return {
      filters: {
        search: null,
      },
      columns: [
        {
          name: "name",
          label: "Name",
        },
        {
          name: "created_at",
          label: "Date & Time",
          value: (item) => formatDate(item.created_at),
          sortable: true,
        },
        {
          name: "brand",
          label: "Brand",
          value: (item) => (item.brand ? item.brand.name : null),
        },
        {
          name: "actions",
          rowClick: false,
        },
      ],
    };
  },
  methods: {
    async deleteBrochure(id) {
      try {
        const deleteBrochure = confirm("Are you sure you want to delete?");
        if (deleteBrochure) {
          await remove(id);
          this.$shilp.notify({
            title: "Brochure has been deleted successfully",
            type: "success",
          });
          this.$refs.list.refresh();
        }
      } catch {
        this.$shilp.notify({
          title: "Something went wrong while deleting the Brochure. Please try again!!",
          type: "danger",
        });
      }
    },
  },
};
</script>

<style></style>
