<template>
  <v-select label="name" :multiple="multiple" placeholder="Select" :options="brandOptions" :reduce="(brand) => brand.id" :value="value" @input="$emit('input', $event)">
    <template #option="{ logo, name }">
      <img v-if="logo && logo.url" :src="logo.url" class="w-4 h-4 object-contain mr-3 inline-block" />
      <span class="leading-6">{{ name }}</span>
    </template>
  </v-select>
</template>

<script>
import { getBrands } from "@/api/brand.js";
export default {
  props: {
    value: [Array, Number],
    multiple: {
      type: Boolean,
      default: false,
    },
    params: [Object, Number],
  },

  data() {
    return {
      brandOptions: [],
    };
  },
  mounted() {
    this.getAllBrands();
  },

  watch: {
    params(value) {
      this.getAllBrands(value);
    },
  },

  methods: {
    getAllBrands(id = null) {
      let params = {
        filter: {
          category_id: id,
        },
        limit: -1,
      };
      getBrands(params).then((res) => {
        this.brandOptions = res.data;
      });
    },
  },
};
</script>

<style scoped></style>
