export default [
  {
    path: "vendor-inquiries",
    name: "vendor-inquiries",
    component: require("@/views/private/vendor/inquiry/Index").default,
    meta: {
      label: "Inquiry",
    },
    redirect: { name: "vendor-inquiry-list" },
    children: [
      {
        path: "vendor-inquiry-list",
        name: "vendor-inquiry-list",
        component: require("@/components/inquiry/List").default,
        meta: {
          label: "Issues",
          breadcrumbs: ["dashboard", "vendor-inquiries", "vendor-inquiry-list"],
        },
      }, 
      {
        path: ":id",
        props: (route) => ({ id: Number(route.params.id) }),
        name: "vendor-inquiry-details",
        component: require("@/views/private/vendor/inquiry/Item").default,
        meta: {
          label: "Issue Details",
          breadcrumbs: ["dashboard", "vendor-inquiries", "vendor-inquiry-details"], 
        },
      },
    ],
  },
];
