var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"grid-wrapper"},[_c('SpList',{ref:"aacBlocksTable",attrs:{"endpoint":"buyerTypeCategoryDiscounts","attrs":_vm.columns,"filters":{ category_id: 3 },"params":_vm.$options.params,"actions":[],"footer":false}},[_c('SpListTable',{scopedSlots:_vm._u([{key:"category",fn:function(ref){
var item = ref.item;
var rowIndex = ref.rowIndex;
return [(rowIndex == 0)?_c('span',[_vm._v(_vm._s(item.category.name))]):_c('span')]}},{key:"percentage",fn:function(ref){
var item = ref.item;
return [_c('s-field',[_c('s-textbox',{key:("percentage-input-" + (item.id)),attrs:{"type":"number","value":item.percentage},on:{"input":function($event){_vm.update(item, Number($event), 'aacBlocksTable')}},nativeOn:{"focus":function($event){return $event.target.select()}}})],1)]}}])})],1),_c('SpList',{ref:"tmtBarTable",attrs:{"endpoint":"buyerTypeCategoryDiscounts","attrs":_vm.columns,"filters":{ category_id: 2 },"params":_vm.$options.params,"actions":[],"footer":false}},[_c('SpListTable',{scopedSlots:_vm._u([{key:"category",fn:function(ref){
var item = ref.item;
var rowIndex = ref.rowIndex;
return [(rowIndex == 0)?_c('span',[_vm._v(_vm._s(item.category.name))]):_c('span')]}},{key:"percentage",fn:function(ref){
var item = ref.item;
return [_c('s-field',[_c('s-textbox',{key:("percentage-input-" + (item.id)),attrs:{"type":"number","value":item.percentage},on:{"input":function($event){_vm.update(item, Number($event), 'tmtBarTable')}},nativeOn:{"focus":function($event){return $event.target.select()}}})],1)]}}])})],1),_c('SpList',{ref:"cementTable",attrs:{"endpoint":"buyerTypeCategoryDiscounts","attrs":_vm.columns,"filters":{ category_id: 1 },"params":_vm.$options.params,"actions":[],"footer":false}},[_c('SpListTable',{scopedSlots:_vm._u([{key:"category",fn:function(ref){
var item = ref.item;
var rowIndex = ref.rowIndex;
return [(rowIndex == 0)?_c('span',[_vm._v(_vm._s(item.category.name))]):_c('span')]}},{key:"percentage",fn:function(ref){
var item = ref.item;
return [_c('s-field',[_c('s-textbox',{key:("percentage-input-" + (item.id)),attrs:{"type":"number","value":item.percentage},on:{"input":function($event){_vm.update(item, Number($event), 'cementTable')}},nativeOn:{"focus":function($event){return $event.target.select()}}})],1)]}}])})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-2 border-gray-200 p-2 my-2 rounded-md flex flex--fit gap-2 flex--wrap"},[_c('div',{staticClass:"text-grey text-md"},[_c('sup',[_vm._v("*")]),_vm._v(" "),_c('b',[_vm._v("Discount")]),_vm._v(" will be considered when percentage will be "),_c('b',[_vm._v("negative")]),_vm._v(" "),_c('span',{staticClass:"hidden md:inline"},[_vm._v(" | ")])]),_c('div',{staticClass:"text-grey text-md"},[_c('sup',[_vm._v("*")]),_vm._v(" "),_c('b',[_vm._v("Premium")]),_vm._v(" will be considered when percentage will be "),_c('b',[_vm._v("positive")])])])}]

export { render, staticRenderFns }