
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M15.83 9.81c-1.13-.11-2.14.57-2.37 1.69c0 .34 1.35.79 2.59.79c1.24 0 2.36-.79 2.36-1.01c0-.23-.78-1.35-2.58-1.47m-7.65 0c-1.8.12-2.59 1.13-2.59 1.46c0 .23 1.23 1.02 2.36 1.02s2.59-.45 2.59-.79c-.23-1.12-1.35-1.8-2.36-1.69M16.95 16c-1.91 0-3.15-2.25-4.95-2.25S8.85 16 7.05 16C4.69 16 3 13.86 3 10.04C3 7.68 3.68 7 6.71 7S10.54 8.24 12 8.24S14.36 7 17.29 7S21 7.79 21 10.04c0 3.82-1.69 5.96-4.05 5.96Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiDominoMask",
};
</script>