export default [
  {
    path: "credits",
    name: "credit",
    component: require("@/views/private/credits/Index").default,
    meta: {
      label: "Credit",
      breadcrumbs: ["dashboard", "credit"],
    },
  },
];
