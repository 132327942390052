<template>
  <svg width="24" height="24" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2212_13928" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="124" height="124">
      <rect width="124" height="124" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2212_13928)">
      <path
        d="M20.6667 108.5V98.1668H82.6667V108.5H20.6667ZM49.8584 83.4418L20.6667 54.2501L31.5167 43.1418L60.9667 72.3335L49.8584 83.4418ZM82.6667 50.6335L53.4751 21.1835L64.5834 10.3335L93.7751 39.5252L82.6667 50.6335ZM106.433 103.333L39.0084 35.9085L46.2417 28.6752L113.667 96.1001L106.433 103.333Z"
        fill="white"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "AdvanceBid",
};
</script>