<template>
  <router-view :inquiryStatusOptions="inquiryStatusOptions" endpoint="issues" :params="params" :filter="filters" :columns="columns" module="vendor"></router-view>
</template>

<script>
import { mapGetters } from "vuex";
import { getReadClassList } from "@/utilities/common";
import { formatDate } from "@/utilities/common";
export default {
  components: {},
  computed: {
    ...mapGetters(["inquiryStatusOptions"]),
  },
  data() {
    return {
      filters: {
        search: null,
        date_range: null,
        inquiry_status_id: null,
        vendor_issue_type_id: null,
        user_id: null,
        read_status: null,
      },
      params: {
        limit: 25,
        include: "issueStatus,vendorIssueType,user",
      },
      columns: [
        {
          name: "issue_no",
          label: "Issue ID",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "vendor_order_no",
          label: "Order ID",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "user",
          label: "Vendor Name",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "created_at",
          label: "Date & Time",
          sortable: true,
          value: (item) => formatDate(item.created_at),
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "vendor_issue_type",
          label: "Issue Type",
          width: "260px",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "issueStatus",
          label: "Status",
          width: "260px",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
      ],
    };
  },
  methods: {
    getReadClassList,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .inquery {
  .vs__dropdown-option {
    margin: 9px;
  }
}
</style>