<template>
  <div class="v-list order-list v-list--sidebar">
    <!-- <div class="v-list__sidebar" :style="visible ? 'display : block;' : 'display: none'"> -->
    <div class="v-list__sidebar" :style="visible ? 'display : block;' : 'display: none'">
      <filter-sidebar-header @filterBarClose="visible = false" />
      <div class="h-full">
        <div class="filter-header">
          <slot></slot>
        </div>
        <filter-footer @resetFilters="$emit('resetFilters')" @applyBtn="$emit('applyBtn')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    FilterSidebarHeader: require("@/components/tableFilter/FilterSidebarHeader").default,
    FilterFooter: require("@/components/tableFilter/Footer").default,
  },
  props: {},

  created() {
    this.$root.$on("filter-bar-open", this.openSidebar);
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    openSidebar() {
      this.visible = true;
    },
  },

  beforeDestroy() {
    this.$root.$off("filter-bar-open", this.openSidebar);
  },
};
</script>

<style lang="scss" scoped>
.filter-header {
  @apply p-4;
}
</style>
