<template>
  <div class="card-wrap">
    <h3 class="text-primary-500">Assigned Companies</h3>
    <SpList :data="companies" :attrs="$options.columns" :actions="[]">
      <template #empty>
        <ListEmpty title="No companies assigned yet." />
      </template>
      <SpListTable></SpListTable>
    </SpList>
  </div>
</template>

<script>
export default {
  props: ["companies"],
  columns: ["name", "mobile_no"],
};
</script>

<style lang="scss" scoped></style>
