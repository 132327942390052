<template>
  <div class="min-w-[200px]">
    <v-select
      :resetOnOptionsChange="true"
      :clearable="false"
      :options="allowedStatusOptions"
      placeholder="Select Status"
      label="display_name"
      v-model="orderStatusId"
      :reduce="(option) => option.id"
      :searchable="false"
    />
    <div class="flex flex--nowrap items-center mt-2 p-1">
      <input style="max-width: 20px; height: 20px" type="checkbox" :checked="sendSms" v-model="sendSms" class="mr-2" id="send_sms" />
      <label class="min-w-max text-gray-500 text-sm font-medium" for="send_sms"> Send SMS </label>
    </div>
    <s-button label="Submit" @click.native="change" color="primary" class="mt-2" :loader="isChanging" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { changeOrderOrPaymentStatus } from "@/api/order";
import orderStatusData from "@/data/orderStatus";

export default {
  orderStatusData,
  props: ["status", "orderId", "cancellationAllowed"],
  computed: {
    ...mapGetters({
      orderStatusOptions: "orderStatusOptions",
    }),

    allowedStatusOptions() {
      if (this.cancellationAllowed) return this.orderStatusOptions;
      else
        return this.orderStatusOptions.filter((item) => {
          // Cancelled status
          return item.id !== this.$options.orderStatusData.cancelled;
        });
    },
  },

  created() {
    if (this.status) this.orderStatusId = this.status;
  },
  data() {
    return {
      sendSms: false,
      isChanging: false,
      orderStatusId: null,
    };
  },

  methods: {
    resetState() {
      this.isChanging = false;
      this.orderStatusId = null;
      this.sendSms = false;
    },
    async change() {
      this.isChanging = true;

      try {
        await changeOrderOrPaymentStatus(this.orderId, { order_status_id: this.orderStatusId, is_sms_send: this.sendSms });
        this.$shilp.notify({
          title: "Status Changed.",
          type: "success",
        });
        this.resetState();
        this.$emit("order-status-changed");
      } catch {
        this.$shilp.notify({
          title: "Something went wrong. Try Again.",
          type: "danger",
        });
      } finally {
        this.isChanging = false;
        this.sendSms = false;
      }
    },
  },
};
</script>

<style></style>
