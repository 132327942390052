<template>
  <!-- Grade  -->

  <div>
    <ProductAttributeDropdown :rules="rules" name="Grade" :disabled="disabled" :options="gradeOptions" :value="gradeId" @selected="$emit('grade-selected', $event)" v-if="gradeOptions.length" />

    <ProductAttributeDropdown
      :rules="rules"
      name="Packing"
      :disabled="disabled"
      :options="packingOptions"
      :value="packingId"
      @selected="$emit('packing-selected', $event)"
      v-if="packingOptions.length"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { getCategories } from "@/api/category";
export default {
  params: { limit: -1, include: "attribute.attributeOption", filter: { id: 1 } },
  components: {
    ProductAttributeDropdown: require("@/components/ProductAttributeDropdown.vue").default,
  },
  props: {
    rules: String,
    disabled: Boolean,
    gradeId: Number,
    packingId: Number,
  },
  data() {
    return {
      attributes: [],
      isLoading: false,
    };
  },
  created() {
    this.getOptions();
  },
  computed: {
    ...mapGetters(["categoryOptions"]),
    gradeOptions() {
      if (this.attributes && this.attributes.length) {
        let temp = this.attributes.find((item) => {
          return item.id == 1;
        });

        return temp.attributeOption;
      } else return [];
    },

    packingOptions() {
      if (this.attributes && this.attributes.length) {
        let temp = this.attributes.find((item) => {
          return item.id == 5;
        });

        return temp.attributeOption;
      } else return [];
    },
  },

  methods: {
    getOptions() {
      this.isLoading = true;
      getCategories(this.$options.params).then((res) => {
        this.attributes = res.data[0].attributes;

        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
