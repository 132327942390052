<template>
  <div class="step p-4 bg-white radius-3">
    <h2 class="step__count text-grey-light">{{ no }}</h2>
    <h4 v-if="title">{{ title }}</h4>
    <div class="step__desc ml-3">
      <slot />
    </div>
    <div class="step__action">
      <slot name="action" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    no: Number,
    title: String,
  },
};
</script>

<style lang="scss">
.step {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.step__desc {
  flex: 1 1 auto;
  @apply text-lg font-medium text-gray-500;
}
.step__count {
  position: relative;
}
.step__action {
  .button {
    font-weight: 600 !important;
  }
}
</style>
