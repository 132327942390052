<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path fill="currentColor" d="M6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m-1 1.5L18.5 9H13m-2.95 2.22l2.83 2.83L15 11.93V19H7.93l2.12-2.12l-2.83-2.83"></path>
  </svg>
</template>

<script>
export default {
  name: "MdiFileImport",
};
</script>
