<template>
  <div v-shilp-loader.overlay="isLoading">
    <h4 class="text-primary-500">No Orders</h4>
    <div class="flex flex--fit gap-2 flex--middle">
      <!-- <div class="card-wrapper" @click="onClick(45)" :class="{ active: selected == 45 }">
        <s-button icon="IconMdiFormatListNumbered" theme="muted" color="primary" shape="circle" size="lg" />
        <div>
          <p class="text-lg font-bold text-primary-500">{{ last45DaysCount }}</p>
          <span class="text-base">45 Days</span>
        </div>
      </div> -->

      <div class="card-wrapper" @click="onClick($options.days)" :class="{ active: selected == $options.days }">
        <s-button icon="IconMdiFormatListNumbered" theme="muted" color="primary" shape="circle" size="lg" />
        <div>
          <p class="text-lg font-bold text-primary-500">
            {{ last60DaysCount }} <InformationButton v-tooltip="'This count indicates No of companies which have not ordered from last 60 days and more.'" />
          </p>
          <span class="text-base">{{ $options.days }} Days</span>
        </div>
      </div>

      <!-- <div class="card-wrapper" @click="onClick(90)" :class="{ active: selected == 90 }">
        <s-button icon="IconMdiFormatListNumbered" theme="muted" color="warning" shape="circle" size="lg" />
        <div>
          <p class="text-lg font-bold text-warning-500">{{ last90DaysCount }}</p>
          <span class="text-base">45 Days to 90 Days</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getAllCompanies } from "@/api/company";
import InformationButton from "@/components/InformationButton.vue";

export default {
  days: 60,
  components: {
    InformationButton,
  },
  data() {
    return {
      isLoading: false,
      selected: null,
      // last45DaysCount: 0,
      // last90DaysCount: 0,
      last60DaysCount: 0,
      params: {
        limit: -1,
        days: null,
        filter: {
          kyc_status: "approve",
          days: this.$options.days,
        },
      },
    };
  },
  created() {
    this.getData();
    // if (this.$route.query.activeCompanyfilter) {
    //   this.onClick(this.$options.days);
    // }
  },
  methods: {
    onClick(days) {
      if (days == this.selected) this.selected = null;
      else this.selected = days;
      this.$emit("selected", this.selected);
    },
    getData() {
      // this.get45DaysCount();
      // this.get90DaysCount();
      this.get60DaysCount();
    },

    // get45DaysCount() {
    //   this.isLoading = true;
    //   this.params.days = 45;

    //   getAllCompanies(this.params).then((res) => {
    //     this.last45DaysCount = res.data.length;
    //     this.isLoading = false;
    //   });
    // },
    // get90DaysCount() {
    //   this.isLoading = true;
    //   this.params.days = 90;
    //   getAllCompanies(this.params).then((res) => {
    //     this.isLoading = false;
    //     this.last90DaysCount = res.data.length;
    //   });
    // },

    get60DaysCount() {
      this.isLoading = true;
      this.params.days = this.$options.days;
      getAllCompanies(this.params).then((res) => {
        this.isLoading = false;
        this.last60DaysCount = res.data.length;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  &.active {
    @apply bg-success-200;
  }
  @apply min-w-[150px] bg-white flex items-center justify-start gap-2 p-2 rounded-md border-2 hover:drop-shadow-lg transition-all duration-300 cursor-pointer;
}
</style>
