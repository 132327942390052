export const inquiryType = [
  {
    label: "General",
    name: "general",
    value: 0,
  },
  {
    label: "Request For Price",
    name: "request_for_price",
    value: 1,
  },
  {
    label: "Out Of Stock",
    name: "out_of_stock",
    value: 2,
  },
  {
    label: "Credit Limit Crossed",
    name: "credit_limit_crossed",
    value: 3,
  },
  {
    label: "Invoice Request",
    name: "invoice_request",
    value: 4,
  },
  {
    label: "Website Inquiry",
    name: "web_inquiry",
    value: 5,
  },
];

export const getInquiryType = (id) => {
  return inquiryType.find((item) => id == item.value);
};

export const getInquiryTypeByName = (name) => {
  return inquiryType.find((item) => item.name == name);
};

export const creditLimit = -1500000;
