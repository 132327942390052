import Vue from "vue";
import App from "./App.vue";
import "./style/index.scss";
import "./plugins/";
import router from "./router/index";
import "./global";
import store from "./store";
import "@/directives/scrollIntoView.js";
Vue.config.productionTip = false;
import { apolloProvider } from "./plugins/graphql";

new Vue({
  store,
  router,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
