<template>
  <div>
    <div v-shilp-loader.overlay="isLoading" v-if="labels.length && !isLoading" class="brand-pie-chart-wrapper">
      <TopBrandPieChart v-for="(label, index) in labels" :key="`brand-pie-chart-${index}`" class="card-wrap p-2" :label="label" :data="values[index]" />
    </div>
    <div v-if="isLoading" class="brand-pie-chart-wrapper">
      <div v-shilp-loader.overlay="isLoading" class="w-[350px] h-[250px] bg-white rounded-md"></div>
      <div v-shilp-loader.overlay="isLoading" class="w-[350px] h-[250px] bg-white rounded-md"></div>
      <div v-shilp-loader.overlay="isLoading" class="w-[350px] h-[250px] bg-white rounded-md"></div>
    </div>
  </div>
</template>

<script>
import { topBrandsCategoryWise } from "@/api/brand";
import TopBrandPieChart from "@/components/dashboard/pieCharts/brands/TopBrandPieChart.vue";
export default {
  components: {
    TopBrandPieChart,
  },
  props: ["dateRange"],
  data() {
    return {
      labels: [],
      values: [],
      isLoading: false,
    };
  },
  computed: {
    params() {
      return {
        filter: {
          date_range: this.dateRange,
        },
      };
    },
  },
  created() {
    this.getData();
    this.$root.$on("dashboard-refresh", this.getData);
  },
  beforeDestroy() {
    this.$root.$off("dashboard-refresh", this.getData);
  },
  watch: {
    dateRange() {
      this.getData();
    },
  },
  methods: {
    reset() {
      this.labels = [];
      this.values = [];
    },
    getData() {
      this.isLoading = true;
      this.reset();
      topBrandsCategoryWise(this.params)
        .then((res) => {
          const keys = Object.keys(res);
          if (keys.length) {
            this.labels = keys;
            this.values = Object.values(res);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-pie-chart-wrapper {
  @apply flex flex-wrap  gap-4;
  > div {
    min-width: 496px;
    width: calc(100% / 3 - 11px);
  }
}
</style>
