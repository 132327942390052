import $axios from "@/plugins/axios";

export const getAll = () => {
  return $axios.get(`products?include=brand`).then((res) => {
    return res.data;
  });
};

export const get = (id) => {
  return $axios.get(`products/${id}?include=categories,attributeOptions.attribute`).then((res) => {
    return res.data;
  });
};

export const create = (data) => {
  return $axios.post("products", data).then((res) => {
    return res.data;
  });
};

export const update = (id, data) => {
  return $axios.put(`products/${id}`, data).then((res) => {
    return res.data;
  });
};

export const remove = (id) => {
  return $axios.delete(`products/${id}`).then((res) => {
    return res.data;
  });
};

export const allProductCount = () => {
  return $axios.get("products/count/all").then((res) => {
    return res.data;
  });
};

export const exportExcel = () => {
  return $axios.get("products/export").then((res) => {
    return res.data;
  });
};

export const importExcel = (data, params = null) => {
  return $axios.post("products/import", data, { params }).then((res) => {
    return res.data;
  });
};

export const getBulkActionsLog = () => {
  return $axios.get("importExcel").then((res) => {
    return res.data;
  });
};
