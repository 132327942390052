<template>
  <s-textbox
    :placeholder="placeholder"
    :value="value"
    @input="textToCapitalize"
  />
</template>

<script>
export default {
  props: ["value", "placeholder"],
  methods: {
    textToCapitalize(value) {
      if (value && value.length) {
        let capitalizedText = value[0].toUpperCase() + value.substr(1);
        this.$emit("input", capitalizedText);
      } else this.$emit("input", "");
    },
  },
};
</script>

<style lang="scss" scoped></style>
