export default [
  {
    path: "team-members",
    redirect: { name: "team-members-list" },
    component: require("@/views/private/teamMembers/Index.vue").default,
    children: [
      {
        path: "",
        name: "team-members-list",
        component: require("@/views/private/teamMembers/List.vue").default,
        meta: {
          label: "List",
          icon: "IconMdiAccountMultipleOutline",
          breadcrumbs: ["dashboard", "team-members-list"],
        },
      },

      {
        path: "upsert/:id",
        name: "team-members-upsert",
        component: require("@/views/private/teamMembers/Item.vue").default,
        props: true,
        meta: {
          label: "Add New",
          icon: "IconMdiAccountMultipleOutline",
          breadcrumbs: ["dashboard", "team-members-list", "team-members-upsert"],
        },
      },
    ],
  },
];
