<template>
  <s-button icon="IconIcRoundLogOut" label="Logout" :loader="isLoggingOut" theme="solid" color="primary" v-tooltip="'Logout'" @click.native="logOut" />
</template>

<script>
import { resetStore } from "@/store/index";
import { logout } from "@/api/auth";
export default {
  data() {
    return {
      isLoggingOut: false,
    };
  },
  methods: {
    async logOut() {
      try {
        this.isLoggingOut = true;
        await logout();
        resetStore();
        localStorage.removeItem("bit-admin-token");
        this.isLoggingOut = false;
        this.$router.push({ name: "login" });
      } catch {
        this.$shilp.notify({
          title: "Something went wrong while logging out. Please try again.",
          type: "danger",
        });
      } finally {
        this.isLoggingOut = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
