export default [
  {
    path: "vendor-settings",
    name: "vendor-settings",
    component: require("@/views/private/vendor/settings/Index").default,
    meta: {
      label: "Vendor Settings",
      breadcrumbs: ["dashboard", "vendor-settings"],
    },
  },
];
