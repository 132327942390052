<template>
  <div>
    <label for="" class="block text-gray-500">Mobile Number</label>
    <span class="text-lg">{{ number }}</span>
  </div>
</template>

<script>
export default {
  props: ["number"],
};
</script>

<style lang="scss" scoped></style>
