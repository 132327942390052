import Vue from "vue";

/**
 * Vee Validate
 */
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, min, max, max_value, min_value } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en";

const rules = { required, email, min, max, max_value, min_value };

for (let rule in rules) {
  extend(rule, {
    ...rules[rule], // add the rule
    message: en.messages[rule], // add its message
  });
}

extend("required", {
  ...required,
  message: "* Required",
});

extend("gst", {
  validate: (value) => {
    const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/g;
    return value.match(new RegExp(regex));
  },
  message: "GSTIN Number Invalid",
});

extend("mobileNo", {
  validate(value) {
    const regex = /^[1-9][0-9]{9}$/;
    return value.match(new RegExp(regex));
  },
  message: "Mobile No Invalid.",
});

extend("positiveNonZeroInteger", {
  validate: (value) => {
    if (value <= 0) {
      return "Value must be positive";
    }
    if (value % 1 !== 0 || (value.includes && value.includes("."))) {
      return "Value shouldn't be decimal";
    }
    return true;
  },
});

extend("positiveInteger", {
  validate: (value) => {
    if (value < 0) {
      return "Value must be positive";
    }
    if (value % 1 !== 0 || (value.includes && value.includes("."))) {
      return "Value shouldn't be decimal";
    }
    return true;
  },
});

extend("positiveNonZeroFloat", {
  validate: (value) => {
    if (parseFloat(value) <= 0) {
      return "Value must be positive";
    }
    return true;
  },
});

extend("positiveFloat", {
  validate: (value) => {
    if (value < 0) {
      return "Value must be positive";
    }
    return true;
  },
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
